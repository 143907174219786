import { useQuery } from '@tanstack/react-query';
import { getMELItems } from '../../api';
import { BookItem } from '../../../models/deferrableDefectBook';

const useQueryGetMELItems = ({
  payload,
  refetchOnWindowFocus = true,
  staleTime = 0,
  enabled = true,
}: {
  payload: { id: string };
  refetchOnWindowFocus?: boolean;
  staleTime?: number;
  enabled?: boolean;
}): {
  data: BookItem[];
  isFetching: boolean;
} => {
  const { data, isFetching } = useQuery({
    queryKey: ['mel_items', payload],
    queryFn: () => getMELItems(payload),
    refetchOnWindowFocus,
    staleTime,
    enabled,
  });

  return { data, isFetching };
};

export default useQueryGetMELItems;
