import { Table } from '@arcflight/tf-component-library';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useSortBy, usePagination } from 'react-table';

interface LogbookTableProps {
  total: number;
  pageSize: number;
  pageIndex: number;
  onPaginationChange: (currentPage: number, numberOfItems: number) => void;
  data: any[];
  columns: any[];
  resetToOne: boolean;
}

const TableWrapper = styled.div`
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
  overflow-x: auto;
  table {
    thead {
      tr {
        th {
          vertical-align: bottom;
        }
      }
    }
  }
`;

const LogbookTable: React.FC<LogbookTableProps> = ({
  total,
  pageSize,
  pageIndex,
  onPaginationChange,
  data,
  columns,
  resetToOne,
}) => {
  const memoData = useMemo(() => data, [data]);

  const memoColumns = useMemo(() => columns, [columns]);

  const tableInstance = useTable(
    {
      columns: memoColumns,
      data: memoData,
      manualPagination: true,
      manualSortBy: true,
      initialState: { pageIndex, pageSize },
      pageCount: Math.ceil(total / (pageSize || 10)),
    },
    useSortBy,
    usePagination,
  );

  return (
    <TableWrapper>
      <Table
        tableInstance={tableInstance}
        total={total}
        handlePaginationChange={(currentPage, numberOfItems): void => onPaginationChange(currentPage, numberOfItems)}
        passedPageSize={pageSize}
        resetToOne={resetToOne}
      />
    </TableWrapper>
  );
};

export default LogbookTable;
