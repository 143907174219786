import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Label, SectionHeader, Text } from '../../CommonStyledComponents/CommonStyledComponents';
import { Defect } from '../../../models/defects';

interface ViewDefectAdditionalInformationProps {
  defectData: Defect;
  requiredSettings: {
    troubleShootingStepsRename?: string;
    deferralApprovalReferenceRename?: string;
    part145ApprovalNumberRename?: string;
    engineerRename?: string;
    engineerLicenceNumberRename?: string;
  };
}

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

const TroubleShootWrapper = styled.div`
  grid-column: span 2;
`;

const ViewDefectAdditionalInformation: React.FC<ViewDefectAdditionalInformationProps> = ({
  defectData,
  requiredSettings,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <SectionHeader data-testid="ViewDefectAdditionalInformation--Header">
        {formatMessage({ id: 'title.additionalInformation' })}
      </SectionHeader>
      <GridSection>
        <TroubleShootWrapper data-testid="ViewDefectAdditionalInformation--TroubleshootingSteps">
          <Label>
            {formatMessage({ id: 'text.troubleshootingSteps' }, { field: requiredSettings.troubleShootingStepsRename })}
          </Label>
          <Text>{defectData?.troubleshooting_steps || '-'}</Text>
        </TroubleShootWrapper>
        <div data-testid="ViewDefectAdditionalInformation--ReasonToDefer">
          <Label>{formatMessage({ id: 'text.reasonToDefer' })}</Label>
          <Text>{defectData?.reason_to_defer || '-'}</Text>
        </div>
        <div data-testid="ViewDefectAdditionalInformation--DeferralApprovalReference">
          <Label>{requiredSettings.deferralApprovalReferenceRename}</Label>
          <Text>{defectData?.defer_approval_reference || '-'}</Text>
        </div>
        {defectData?.deferral_approval_number && (
          <div data-testid="ViewDefectAdditionalInformation--DeferralApprovalNumber">
            <Label>{requiredSettings.part145ApprovalNumberRename}</Label>
            <Text>{defectData?.deferral_approval_number}</Text>
          </div>
        )}
        {defectData?.deferral_licence_number && (
          <div data-testid="ViewDefectAdditionalInformation--DeferralLicenceNumber">
            <Label>
              {requiredSettings.engineerRename} {requiredSettings.engineerLicenceNumberRename}
            </Label>
            <Text>{defectData?.deferral_licence_number}</Text>
          </div>
        )}
      </GridSection>
    </div>
  );
};

export default ViewDefectAdditionalInformation;
