import { BaseModel } from '..';
import { Attachment } from '../intermittentFaults';

const initialState: CabinIssueState = {
  cabinIssues: [],
  cabinIssuesCount: 0,
  lastFetched: 0,
  cabinIssueSVG: null,
  selectedIssueLocation: null,
  cabinIssueSubLocations: [],
  payload: null,
};

export enum CabinIssuesActionTypes {
  SAVE_ALL = 'cabinIssues/saveAll',
  SAVE = 'cabinIssues/save',
  ADD = 'cabinIssues/add',
  UPDATE = 'cabinIssues/update',
  REMOVE = 'cabinIssues/remove',
  GET_CABIN_ISSUE_SVG = 'cabinIssues/getCabinIssueSVG',
  SELECTED_ISSUE_LOCATION = 'cabinIssues/selectedIssueLocation',
  GETSUBLOCATIONS = 'cabinIssues/getSubLocations',
  GETINDIVIDUALSUBLOCATION = 'cabinIssues/getIndividualSubLocations',
  UPDATEINDIVIDUALSUBLOCATION = 'cabinIssues/updateIndividualSubLocation',
  UPDATE_PAYLOAD = 'cabinIssues/updatePayload',
}

export interface GetCabinIssueSVGActionType {
  type: CabinIssuesActionTypes.GET_CABIN_ISSUE_SVG;
  payload: CabinSVGContent;
}

export interface SetSelectedIssueLocationActionType {
  type: CabinIssuesActionTypes.SELECTED_ISSUE_LOCATION;
  payload: {
    id: string;
    state: string;
  };
}
export interface GetSubLocationsActionType {
  type: CabinIssuesActionTypes.GETSUBLOCATIONS;
  payload: CabinIssueSubLocation[];
}

export interface GetIndividualSubLocationsActionType {
  type: CabinIssuesActionTypes.GETINDIVIDUALSUBLOCATION;
  payload: any;
}

export interface UpdateIndividualSubLocationActionType {
  type: CabinIssuesActionTypes.UPDATEINDIVIDUALSUBLOCATION;
  payload: any;
}

export interface UpdatePayloadActionType {
  type: CabinIssuesActionTypes.UPDATE_PAYLOAD;
  payload: any;
}

export interface CabinIssueState {
  cabinIssues: CabinIssue[];
  cabinIssuesCount: number;
  lastFetched: number;
  cabinIssueSVG: CabinSVGContent;
  selectedIssueLocation: {
    id: string;
    state: string;
    x?: number;
    y?: number;
  };
  cabinIssueSubLocations: CabinIssueSubLocation[];
  payload: {
    page: number;
    limit: number;
    search?: string;
    status?: string;
    sort_column?: string;
    sort_order?: string;
  };
}

export interface CabinIssueSubLocation {
  id: string;
  aircraft_id: string;
  aircraft_sub_location_group_list_items?: any[];
  aircraft_sub_location_group_list_items_attributes?: any[];
  aircraft_sub_location_group_list_items_count: number;
  name: string;
  payload: {
    page: number;
    limit: number;
    aircraft_id: string;
    search?: string;
    status?: string;
    sort_column?: string;
    sort_order?: string;
  };
}

export interface CabinIssue extends BaseModel {
  id: string;
  details: string;
  number: string;
  raised_at: string;
  status: string;
  aircraft_location_issues: AircraftLocationIssues[];
}

export interface AircraftLocationIssues {
  aircraft_drawer_id: string;
  aircraft_location_id: string;
  associated_record_id: string;
  associated_record_type: string;
  id: string;
  issue_type_id: string;
  position_x: string;
  position_y: string;
}

export interface CabinSVGContent {
  aircraft_id: string;
  attachment: Attachment;
  id: string;
  name: string;
  type: string;
  version: number;
  aircraft_location_groups: AircraftLocationGroup[];
}

export interface AircraftLocationGroup {
  aircraft_drawing_id: string;
  id: string;
  name: string;
  aircraft_locations: AircraftLocation[];
}

export interface AircraftLocation {
  aircraft_location_group_id: string;
  element_identifier: string;
  id: string;
  name: string;
}

export interface FetchAircraftSublocationGroupListsPayload {
  aircraftId: string;
}

export interface FetchAircraftSublocationPayload {
  aircraftId: string;
  id: string;
}

export interface GetCabinSVGPayload {
  aircraft_id: string;
  id: string;
}

export interface SelectedCabinIssueLocationPayload {
  x?: number;
  y?: number;
  id?: string;
  state?: string;
}

export interface AircraftSublocationGroupListItemsAttributes {
  id: string;
  name: string;
  aircraft_sub_location_group_list_id?: string;
  _destroy?: boolean;
}

export interface UpdateAircraftSublocationGroupListParams {
  aircraftId: string;
  id: string;
  aircraft_sub_location_group_list: {
    aircraft_sub_location_group_list_items_attributes: AircraftSublocationGroupListItemsAttributes[];
  };
}

type CabinIssueAction =
  | GetCabinIssueSVGActionType
  | SetSelectedIssueLocationActionType
  | GetSubLocationsActionType
  | GetIndividualSubLocationsActionType
  | UpdateIndividualSubLocationActionType
  | UpdatePayloadActionType;

export default function (state = initialState, action: CabinIssueAction): CabinIssueState {
  switch (action.type) {
    case CabinIssuesActionTypes.GET_CABIN_ISSUE_SVG: {
      return {
        ...state,
        cabinIssueSVG: action?.payload,
      };
    }
    case CabinIssuesActionTypes.SELECTED_ISSUE_LOCATION: {
      return {
        ...state,
        selectedIssueLocation: action.payload,
      };
    }
    case CabinIssuesActionTypes.GETSUBLOCATIONS: {
      if (action.payload) {
        return {
          ...state,
          cabinIssueSubLocations: action.payload,
        };
      }
      return state;
    }
    case CabinIssuesActionTypes.GETINDIVIDUALSUBLOCATION: {
      if (action.payload) {
        const newArray = [...state.cabinIssueSubLocations];
        const existingIndex = state.cabinIssueSubLocations.findIndex((location) => location.id === action.payload.id);
        if (existingIndex !== -1) {
          newArray[existingIndex] = {
            ...newArray[existingIndex],
            ...action.payload,
          };
        } else {
          newArray.push(action.payload);
        }
        return {
          ...state,
          cabinIssueSubLocations: newArray,
        };
      }
      return state;
    }
    case CabinIssuesActionTypes.UPDATEINDIVIDUALSUBLOCATION: {
      if (action.payload) {
        const newArray = [...state.cabinIssueSubLocations];
        const existingIndex = state.cabinIssueSubLocations.findIndex((location) => location.id === action.payload.id);
        if (existingIndex !== -1) {
          newArray[existingIndex] = {
            ...newArray[existingIndex],
            ...action.payload,
            aircraft_sub_location_group_list_items_count:
              action.payload.aircraft_sub_location_group_list_items?.length || 0,
          };
        }
        return {
          ...state,
          cabinIssueSubLocations: newArray,
        };
      }
      return state;
    }
    case CabinIssuesActionTypes.UPDATE_PAYLOAD: {
      return {
        ...state,
        payload: action.payload,
      };
    }
    default:
      return state;
  }
}
