import { BaseModel } from '..';
import { Flight } from '../flights';
import { Person } from '../people';

const initialState: TripsState = {
  ephemeralTrip: {},
  tripsMap: new Map(),
  lastFetched: 0,
  data: [],
  total: 0,
  newTrip: null,
  source: null,
};

export enum TripsActionTypes {
  DELETE_TRIP = 'trips/deleteTrip',
  SAVE_ALL = 'trips/saveAll',
  SAVE_EPHEMERAL_ENTRY = 'trips/saveEphemeralEntry',
  SAVE = 'trips/save',
  SAVE_SOURCE = 'trips/saveSource',
  UPDATE = 'trips/update',
}

interface DeleteTripTripsActionType {
  type: TripsActionTypes.DELETE_TRIP;
  payload: Trip;
}

interface SaveAllTripsActionType {
  type: TripsActionTypes.SAVE_ALL;
  payload: { data: Trip[]; total: number };
}

interface SaveEphemeralEntryTripsActionType {
  type: TripsActionTypes.SAVE_EPHEMERAL_ENTRY;
  payload: Trip;
}

interface SaveTripsActionType {
  type: TripsActionTypes.SAVE;
  payload: Trip;
}

interface UpdateTripsActionType {
  type: TripsActionTypes.UPDATE;
  payload: Trip;
}

interface SaveSourceTripsActionType {
  type: TripsActionTypes.SAVE_SOURCE;
  payload: TripsState;
}

type TripsAction =
  | DeleteTripTripsActionType
  | SaveAllTripsActionType
  | SaveEphemeralEntryTripsActionType
  | SaveTripsActionType
  | SaveSourceTripsActionType
  | UpdateTripsActionType;

/**
 * Updates the flights of a trip with the flights from the payload.
 * 
 * Note: This is a fix for TL-5452. The frontend is overwriting a trip_entry with a trip
 * causing some flight data to be lost because the data doesn't exist on the trip. Ideally, the
 * frontend should be requesting a single trip_entry instead but there is no endpoint on
 * core to retrieve a single trip_entry.
 *
 * @param existingFlights - The existing flights of the trip
 * @param payloadFlights - The flights to update the trip with
 * @returns The updated flights
 */
function updateFlights(existingFlights: Flight[], payloadFlights: Flight[]): Flight[] {
  const flightMap = new Map(existingFlights.map((flight) => [flight.id, flight]));

  payloadFlights.forEach((payloadFlight) => {
    flightMap.set(payloadFlight.id, {
      ...flightMap.get(payloadFlight.id),
      ...payloadFlight,
    });
  });

  return Array.from(flightMap.values());
}

export default function (state = initialState, action: TripsAction): TripsState {
  switch (action.type) {
    case TripsActionTypes.SAVE_ALL: {
      const tripsMap = new Map();
      if (action.payload?.data && Array.isArray(action.payload?.data)) {
        action.payload.data.forEach((trip) => {
          tripsMap.set(trip.id, {
            ...trip,
            lastFetched: Date.now(),
          });
        });
      }
      return {
        ...state,
        tripsMap,
        lastFetched: Date.now(),
        data: action.payload?.data,
        total: action.payload?.total,
      };
    }
    case TripsActionTypes.UPDATE: {
      const tripsMap = new Map(state.tripsMap.entries());
      if (action.payload && action.payload.id) {
        const existingTrip = tripsMap.get(action.payload.id);
        tripsMap.set(action.payload.id, {
          ...existingTrip,
          ...action.payload,
          flights: updateFlights(existingTrip.flights || [], action.payload.flights || []),
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        tripsMap,
        lastFetched: Date.now(),
        newTrip: null,
      };
    }
    case TripsActionTypes.SAVE: {
      const tripsMap = new Map(state.tripsMap.entries());
      if (action.payload && action.payload.id) {
        tripsMap.set(action.payload.id, {
          ...tripsMap.get(action.payload.id),
          ...action.payload,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        tripsMap,
        lastFetched: Date.now(),
        newTrip: action.payload,
      };
    }
    case TripsActionTypes.SAVE_EPHEMERAL_ENTRY: {
      return {
        ...state,
        ephemeralTrip: {
          ...state.ephemeralTrip,
          ...action.payload,
        },
      };
    }
    case TripsActionTypes.SAVE_SOURCE: {
      return {
        ...state,
        source: action.payload.source,
      };
    }
    case TripsActionTypes.DELETE_TRIP: {
      const tripsMap = new Map(state.tripsMap.entries());
      if (action.payload && action.payload.id) {
        tripsMap.delete(action.payload.id);
      }
      return {
        ...state,
        tripsMap,
        newTrip: null,
      };
    }
    default:
      return state;
  }
}

export interface TripsState {
  tripsMap: Map<string, Trip>;
  ephemeralTrip: Trip | {};
  lastFetched: number;
  data: Trip[];
  total: number;
  newTrip: Trip;
  source: string;
}

export interface Trip extends BaseModel {
  first_officer_id: string;
  captain_id: string;
  id: string;
  aircraft_id: string;
  number: number;
  callsign: string;
  landings: number;
  start_airport: string;
  end_airport: string;
  date: string;
  aircraft_registration?: string;
  captain?: Person;
  first_officer?: Person;
  flights?: Flight[];
  attachments?: [];
  status?: string;
  trip_category?: string;
  duty_logs?: DutyLog[];
  duty_logs_attributes?: DutyLog[];
  custom_data?: any;
  srp_number?: number;
  signature_url?: string;
  signature_image_url?: string;
  signature_data?: string;
  completion_signature_url?: string;
  completion_signature_image_url?: string;
  completion_signature_data?: string;
  validation_justification?: string;
  additional_crews?: AdditionalCrew[];
  additional_crews_attributes?: AdditionalCrew[];
  scanned_timestamp?: string;
  scanned_justification?: string;
}

export interface AdditionalCrew {
  person_id: string;
  operator_person_role_id: string;
  position?: string;
  _destroy?: boolean;
  first_name: string;
  last_name: string;
}

export interface DutyLog {
  created_at: string;
  deleted_at: string;
  end_carried_forward: boolean;
  end_time: string;
  id: string;
  person_id: string;
  position: string;
  split_off: string;
  split_on: string;
  start_carried_forward: boolean;
  start_time: string;
  trip_id: string;
  updated_at: string;
  _destroy?: boolean;
}
