import React from 'react';
import styled, { css } from 'styled-components';

interface RadioInputButtonProps {
  text: string;
  active: boolean;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  onClick: () => void;
  checkbox?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  capitalize?: boolean;
}

const activeButton = css`
  border: solid 1px rgba(18, 111, 214, 0.3);
  background-color: rgba(18, 111, 214, 0.1);
  color: #126fd6;
`;

const defaultButton = css`
  border: solid 1px rgba(36, 45, 65, 0.1);
  background-color: rgba(36, 45, 65, 0.02);
  color: rgba(36, 46, 65, 0.7);
`;

const StyledButton = styled.button`
  width: auto;
  min-width: 100px;
  height: 40px;
  border-radius: 2px;
  line-height: normal;
  padding: 0 20px;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }): number => (disabled ? 0.4 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ active }): any => (active ? activeButton : defaultButton)}
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}` : '0')};
  margin-top: '0';
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}` : '0')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}` : '0')};
  &:hover {
    border-color: rgba(18, 111, 214, 0.3);
  }
`;
const StyledInput = styled.input`
  margin-right: 4px;
  margin-top: 1px;
  vertical-align: middle;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
`;

const Text = styled.span`
  text-transform: ${({ capitalize }): string => (capitalize ? 'capitalize' : 'none')};
`;

const RadioInputButton: React.FC<RadioInputButtonProps> = ({
  text,
  active = false,
  marginRight,
  marginBottom,
  marginLeft,
  onClick,
  checkbox,
  disabled,
  dataTestId,
  capitalize = true,
}) => {
  const handleClick = (): void => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <StyledButton
      type="button"
      active={active}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      onClick={handleClick}
      data-testid="RadioInputButton--Button"
      disabled={disabled}
    >
      {checkbox ? (
        <StyledInput
          data-testid={dataTestId}
          id={text}
          type="checkbox"
          checked={active}
          disabled={disabled}
          onClick={(e): void => e.stopPropagation()}
          onChange={(e): void => {
            e.stopPropagation();
            handleClick();
          }}
        />
      ) : (
        <StyledInput
          data-testid={dataTestId}
          id={text}
          type="radio"
          checked={active}
          disabled={disabled}
          onClick={(e): void => e.stopPropagation()}
          onChange={(e): void => {
            e.stopPropagation();
            handleClick();
          }}
        />
      )}
      <Text data-testid={`${dataTestId}-text`} capitalize={capitalize}>{text}</Text>
    </StyledButton>
  );
};

export default RadioInputButton;
