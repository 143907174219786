import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Divider, Label } from '../../CommonStyledComponents/CommonStyledComponents';

interface ViewSignatureRowProps {
  partialDefectData: {
    person: {
      first_name: string;
      last_name: string;
      id: string;
    };
    date: string;
    signatureData: string;
  };
  dateFormat: string;
  type: string;
}

const StyledImg = styled.img`
  height: 70px;
  max-width: 240px;
  @media (max-width: 451px) {
    max-width: 180px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ViewSignatureRow: React.FC<ViewSignatureRowProps> = ({ partialDefectData, dateFormat, type }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Divider />
      <Wrapper data-testid={`ViewSignatureRow--Wrapper-${partialDefectData?.person?.id}`}>
        <InnerWrapper data-testid={`ViewSignatureRow--InnerWrapper-${partialDefectData?.person?.id}`}>
          <Label edit>{type}</Label>
          <Label edit>
            {partialDefectData?.person?.first_name || '-'} {partialDefectData?.person?.last_name || null}
          </Label>
          {partialDefectData?.signatureData && (
            <StyledImg
              alt="Signature"
              src={`data:image/png;base64, ${partialDefectData?.signatureData}`}
              data-testid="ViewSignatureRow--Signature"
            />
          )}
        </InnerWrapper>
        <InnerWrapper data-testid={`ViewSignatureRow--InnerWrapper-Date-${partialDefectData?.person?.id}`}>
          <Label edit>{formatMessage({ id: 'form.labels.date' })}:</Label>
          <Label edit>{moment(partialDefectData?.date).utc().format(dateFormat) || '-'}</Label>
        </InnerWrapper>
      </Wrapper>
    </div>
  );
};

export default ViewSignatureRow;
