import { useSelector } from 'react-redux';
import { DashboardState } from '../models';
import { OperatorSetting } from '../models/userSettings';

export default function useGetOperatorSetting<K extends keyof OperatorSetting>(key: K, id: string): OperatorSetting[K] {
  const {
    userSettings,
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const operators = userSettings?.details?.operators ?? [];
  const operator = id ? operators.find((op) => op?.id === aircraftMap.get(id)?.operator_id) : operators[0];
  const foundOperatorSetting = operator?.operator_setting?.[key];
  return foundOperatorSetting;
}
