import { Button, Col, Row, Menu, Dropdown, Icon, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal, Button as TFButton } from '@arcflight/tf-component-library';
import { removeMxItem } from '../../models/maintenance/actions';
import { fetchAircraftStatus } from '../../models/aircraft/actions';
import { ButtonSize } from '../PaginatedDefectsTable/DefectTableHeader';
import { approveMaintenance } from '../../services/api';
import MaintenanceDrawer from '../MaintenanceDrawer/MaintenanceDrawer';
import { changeDrawerContent, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import MXItemDrawer from '../../pages/AircraftMaintenance/MXItemDrawer';
import styles from './ExpandableCard.module.less';

const MXDetailsManage = ({
  hideEdit,
  hideTolerance,
  onEdit,
  onApplyTolerance,
  item,
  onSuccess,
  dispatch,
  aircraftPermissions,
  aircraftId,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { formatMessage } = useIntl();

  const editItem = () => {
    onEdit();
  };

  const applyTolerance = () => {
    onApplyTolerance();
  };

  const viewItem = () => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(changeDrawerContent({ payload: { content: <MaintenanceDrawer /> } }));
    dispatch(setDrawerId({ payload: item.id }));
  };

  const approveItem = async () => {
    setSubmitting(true);
    try {
      const response = await approveMaintenance({
        ids: [item.id],
      });
      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.approveItemSuccess' }));
        dispatch(fetchAircraftStatus({ payload: aircraftId }));
        onSuccess();
      }
      setSubmitting(false);
      return null;
    } catch (error) {
      return error;
    }
  };

  const deleteItem = async () => {
    setSubmitting(true);
    try {
      const payload = {
        id: item.id,
        aircraft_id: aircraftId,
      };
      const response = await dispatch(removeMxItem({ payload }));
      if (response && response.statusCode === 204) {
        message.success(formatMessage({ id: 'message.itemDeleted' }));
        dispatch(fetchAircraftStatus({ payload: aircraftId }));
        onSuccess();
      }
    } catch (error) {
      return error;
    }
    setSubmitting(false);
    return null;
  };

  const showDeleteConfirm = (value) => {
    setModalVisible(value);
  };

  const handleViewHistory = () => {
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(
      changeDrawerContent({
        payload: {
          content: <MXItemDrawer />,
        },
      }),
    );
    dispatch(setDrawerId({ payload: item.id }));
  };

  useEffect(() => {
    if (modalVisible) {
      document.querySelector('body').setAttribute('style', 'overflow: hidden');
    } else {
      document.querySelector('body').setAttribute('style', 'overflow: auto');
    }
  }, [modalVisible]);

  const menu = (
    <Menu className={styles.itemDropdown}>
      {!hideTolerance && (
        <Menu.Item>
          <Button type="link" onClick={() => applyTolerance()} className={styles.dropdownButton}>
            {formatMessage({ id: 'form.button.applyTolerance' })}
          </Button>
        </Menu.Item>
      )}
      <Menu.Item>
        <Button type="link" onClick={() => viewItem()} className={styles.dropdownButton}>
          {formatMessage({ id: 'text.viewItem' })}
        </Button>
      </Menu.Item>
      {!hideEdit && (
        <Menu.Item>
          <Button type="link" onClick={() => editItem()} className={styles.dropdownButton}>
            {formatMessage({ id: 'text.editItem' })}
          </Button>
        </Menu.Item>
      )}
      <Menu.Item>
        <Button type="link" onClick={() => handleViewHistory()} className={styles.dropdownButton}>
          View History
        </Button>
      </Menu.Item>
      <Menu.Item className={styles.delete}>
        <Button
          type="link"
          onClick={() => showDeleteConfirm(true)}
          className={`${styles.dropdownButton}  ${styles.deleteButton}`}
        >
          {formatMessage({ id: 'title.deleteItem' })}
        </Button>
      </Menu.Item>
    </Menu>
  );

  const maintenancePermissions = aircraftPermissions.filter(
    (permission) => permission.resource_type === 'ScheduledMxItem',
  )[0];
  const canEditOrDeleteMx = maintenancePermissions.to_update || maintenancePermissions.to_delete;

  return (
    <>
      {!item.draft && canEditOrDeleteMx && (
        <Row className={styles.mxManage}>
          <Col md={24}>
            {submitting && <Icon type="sync" spin />}
            <Dropdown overlay={menu} placement="bottomRight">
              <Button type="link" data-test="manageItem">
                {formatMessage({ id: 'text.manageItem' })}
              </Button>
            </Dropdown>
          </Col>
        </Row>
      )}
      {!!item.draft && maintenancePermissions.to_update && (
        <Row className={styles.mxManage}>
          <Col md={24}>
            {submitting && <Icon type="sync" spin />}
            <Button type="link" data-test="approveItem" onClick={() => approveItem()}>
              {formatMessage({ id: 'title.approveItem' })}
            </Button>
          </Col>
        </Row>
      )}
      <Modal width={420} isOpen={modalVisible} handleClose={() => showDeleteConfirm(false)}>
        <div className={styles.modalContentWrapper}>
          <div className={styles.modalTitle}>{formatMessage({ id: 'title.deleteItem' })}</div>
          <div className={styles.modalMessage}>{formatMessage({ id: 'form.question.areYouSureDelete' })}</div>
          <div className={styles.modalButtonWrapper}>
            <div className={styles.submitButton}>
              <TFButton padding="0 28px" size={ButtonSize.MEDIUM} onClick={deleteItem}>
                Delete
              </TFButton>
            </div>
            <TFButton
              padding="0 28px"
              size={ButtonSize.MEDIUM}
              primary={false}
              onClick={() => showDeleteConfirm(false)}
            >
              Cancel
            </TFButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

MXDetailsManage.propTypes = {
  item: PropTypes.object.isRequired,
  hideEdit: PropTypes.bool,
  hideTolerance: PropTypes.bool,
  onEdit: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  onApplyTolerance: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  aircraftPermissions: PropTypes.array.isRequired,
  aircraftId: PropTypes.string.isRequired,
};

MXDetailsManage.defaultProps = {
  hideEdit: false,
  hideTolerance: false,
  onEdit: null,
  onSuccess: null,
};

export default connect(({ userSettings }) => ({
  aircraftPermissions: userSettings.details.people[0].permission_groups[1].permissions,
}))(MXDetailsManage);
