import React from 'react';
import RectificationIntervalExtension from './RectificationIntervalExtension';
import Card from './Card';

interface EditRectificationExtensionProps {
  partialDefectData: {
    rectificationIntervalExtension: {
      extension_date: string;
      extension_date_due: string;
    };
    raisedAt: string;
    displayData: {
      category: string;
    };
    deferred: boolean;
  };
  updateDefectData: (payload: any) => void;
  onChange: () => void;
  requiredSettings: {
    defectRename: string;
  };
}

const EXTENDABLE_RECTIFICATION_CATEGORIES = ['B', 'C', 'D'];

const EditRectificationExtension: React.FC<EditRectificationExtensionProps> = ({
  partialDefectData,
  updateDefectData,
  onChange,
  requiredSettings,
}) => {
  const showRectificationExtensionCard = (): boolean => {
    if (!partialDefectData?.displayData?.category) {
      return false;
    }

    return (
      partialDefectData?.deferred &&
      EXTENDABLE_RECTIFICATION_CATEGORIES.includes(partialDefectData?.displayData?.category)
    );
  };

  if (!showRectificationExtensionCard()) {
    return null;
  }

  return (
    <Card marginTop={20}>
      <RectificationIntervalExtension
        partialDefectData={partialDefectData}
        updateDefectData={updateDefectData}
        onChange={onChange}
        requiredSettings={requiredSettings}
      />
    </Card>
  );
};

export default EditRectificationExtension;
