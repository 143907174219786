/* eslint-disable @typescript-eslint/no-explicit-any */
import { intl } from '../../providers/IntlProvider';
import { addPeople, getUserDetails, queryPeople, removePeople, updatePeople, getUser } from '../../services/api';
import { ToastCategories, ToastTypes, addToast } from '../toasts';
import { createAircraftLogin } from '../../services/apiNew';
import { PeopleActionTypes } from '.';

export const getPeople =
  (callback = null, with_deleted = false) =>
  async (dispatch): Promise<any> => {
    const response = await queryPeople({ with_deleted });
    if (callback) callback();
    dispatch({
      type: PeopleActionTypes.SAVE_ALL,
      payload: response,
    });
  };

export const getSinglePerson =
  (userId, params, callback = null) =>
  async (dispatch): Promise<any> => {
    const response = await getUser(userId, params);
    if (response && response.statusCode > 199 && response.statusCode < 400) {
      dispatch({
        type: PeopleActionTypes.SAVE_SINGLE,
        payload: response.body,
      });
      if (callback) callback();
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.failedToFetchUser' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
      if (callback) callback();
    }
  };

export const resetUserSettingsDrawer =
  () =>
  async (dispatch): Promise<any> => {
    dispatch({
      type: PeopleActionTypes.SAVE_SINGLE,
      payload: {},
    });
  };

export const getMe =
  () =>
  async (dispatch): Promise<any> => {
    const response = await getUserDetails();
    dispatch({
      type: PeopleActionTypes.SAVE,
      payload: response,
    });
  };
export const add =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await addPeople(payload);
    if (response.statusCode > 199 && response.statusCode < 400) {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.newUserAdded' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
      dispatch({
        type: PeopleActionTypes.SAVE,
        payload: response.body,
      });
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.userAlreadyExists' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
    if (response.statusCode > 199 && response.statusCode < 400) return response.body;
    return null;
  };
export const remove =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await removePeople(payload);
    if (response && response.statusCode > 199 && response.statusCode < 400) {
      dispatch({
        type: PeopleActionTypes.REMOVE,
        payload: response
          ? {
              removedId: payload.id,
            }
          : {},
      });
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.userDeleted' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.userDeletedFailed' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
  };
export const update =
  ({ payload }) =>
  async (dispatch): Promise<any> => {
    const response = await updatePeople(payload);
    if (response && response.statusCode > 199 && response.statusCode < 400) {
      dispatch({
        type: PeopleActionTypes.SAVE,
        payload: response.body,
      });
      dispatch({
        type: PeopleActionTypes.SAVE_SINGLE,
        payload: response.body,
      });
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.permissionsUpdated' }),
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
            message: '',
          },
        }),
      );
    } else {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'message.failedToUpdateUser' }),
            type: ToastTypes.ERROR,
            category: ToastCategories.FLAG,
            message: '',
          },
        }),
      );
    }
  };

export const createAircraftLoginAction =
  ({ aircraft_id, email }) =>
  async (dispatch): Promise<any> => {
    const response = await createAircraftLogin({ aircraft_id, email });
    if (response.status > 199 && response.status < 400) {
      dispatch(
        addToast({
          payload: {
            title: intl.formatMessage({ id: 'text.addAircraftSuccess' }),
            message: '',
            type: ToastTypes.SUCCESS,
            category: ToastCategories.FLASH,
          },
        }),
      );
      return response;
    }
    const errorMessage = await response.text();
    dispatch(
      addToast({
        payload: {
          title: intl.formatMessage({ id: 'text.addAircraftFailed' }),
          message: errorMessage || '',
          type: ToastTypes.ERROR,
          category: ToastCategories.FLAG,
        },
      }),
    );
    return response;
  };
