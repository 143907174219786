import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../components/TFLoading'; 
import InnerMenuLayout from '../../layouts/InnerMenuLayout';
import { getAircraftTOTP } from '../../services/apiNew';

const PageCard = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 16px 0 #dbe3ed;
  border: solid 2px #fff;
  background-color: #fcfdff;
  padding: 28px;
  margin: 20px;
`;

const Title = styled.h2`
  color: #242d41;
  font-weight: 500;
`;

const OTPDisplay = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin: 16px 0;
  color: #242d41;
`;

const TOTP: React.FC = () => {
  const [totp, setTOTP] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const { id: aircraftId } = useParams<{ id: string }>();

  const fetchTOTP = useCallback(async (): Promise<void> => {
    try {
      const response = await getAircraftTOTP(aircraftId);
      if (response?.data?.otp) {
        setTOTP(response.data.otp);
        setError(null);
      }
    } catch (e) {
      if (e.response?.status === 401) {
        if (e.response.data?.error) {
          setError(e.response.data.error);
        }
      }
    } finally {
      setLoading(false);
    }
  }, [aircraftId]);

  useEffect(() => {
    fetchTOTP();
    const interval = setInterval(fetchTOTP, 30000);
    return () => clearInterval(interval);
  }, [fetchTOTP]);

  return (
    <InnerMenuLayout>
        <PageCard>
          {loading && <Loading loading={loading} contain />}
          {!loading && error && <div>Error: {error}</div>}
          {!loading && !error && (
            <>
              <Title data-testid="TOTP--Title">Aircraft One-Time Password</Title>
              <span>(auto-refresh every 30 seconds)</span>
              <OTPDisplay>{totp}</OTPDisplay>
            </>
          )}
        </PageCard>
    </InnerMenuLayout>
  );
};

export default TOTP;