import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import plusIcon from '../../../assets/plus-blue.svg';
import minusIcon from '../../../assets/icon-minus-blue.svg';

interface DeferralButtonProps {
  isDeferred: boolean;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
}

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
    margin-left: ${({ iconWidth }): string => (iconWidth === 16 ? '-2px' : '')};
    width: ${({ iconWidth }): string => `${iconWidth}px`};
  }
`;

const DeferralButton: React.FC<DeferralButtonProps> = ({ isDeferred, updateDefectData }) => {
  const { formatMessage } = useIntl();

  const buttonProps = isDeferred
    ? {
        onClick: (): void => {
          updateDefectData([
            { value: false, key: 'deferred' },
            { value: null, key: 'defect_type' },
            { value: null, key: 'book_item' },
            { value: null, key: 'book_item_id' },
            { value: null, key: 'book_item_type' },
            { value: null, key: 'rectification_type' },
            { value: null, key: 'rectification_id' },
          ]);
        },
        icon: minusIcon,
        messageId: 'text.removeDeferral',
      }
    : {
        onClick: (): void => {
          updateDefectData([
            { value: true, key: 'deferred' },
            { value: moment.utc().toISOString(), key: 'deferred_at' },
          ]);
        },
        icon: plusIcon,
        messageId: 'text.addDeferral',
      };
  return (
    <div data-testid="DeferralButton--Wrapper">
      <Button primary={false} height="24px" onClick={buttonProps.onClick} padding={isDeferred ? '0 12px' : undefined}>
        <ButtonContent iconWidth={isDeferred ? 16 : undefined} data-testid="DeferralButton-Content">
          <img src={buttonProps.icon} alt={isDeferred ? 'minus icon' : 'plus icon'} />
          {formatMessage({ id: buttonProps.messageId })}
        </ButtonContent>
      </Button>
    </div>
  );
};

export default DeferralButton;
