/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import servers from '../../utils/servers';
import { getPeople } from '../../models/people/actions';
import { Trip } from '../../models/trips';
import calendarIcon from '../../assets/icon-small-calendar.svg';
import { DisplayText, Header, SectionHeader } from '../FlightDrawer/FlightDrawer';
import TFSelect from '../TFSelect/TFSelect';
import CustomFieldSection from '../CustomFieldSection/CustomFieldSection';
import getDisplayDetailsOfPerson from '../../utils/getDisplayDetailsOfPerson';
import TFDropdown from '../TFDropdown/TFDropdown';
import TFInput from '../TFInput/TFInput';

interface TripDetailsSectionProps {
  trip: Trip;
  editable: boolean;
  updateTripData: (key: string, value: any, secondKey?: string, secondValue?: any) => void;
  aircraftId: string;
  updateTripDataMulti: (changes: { value: any; key: string }[]) => void;
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, editable }): string => {
    if (isMobile) return '1fr';
    if (editable) return '1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  gap: 20px;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 200px;
  height: 40px;
  .ant-calendar-picker-input.ant-input {
    height: 40px;
    border-radius: 2px;
    border: solid 1px rgba(36, 45, 65, 0.1);
    background-color: #f3f7fc;
  }
  @media (max-width: 451px) {
    width: 285px;
  }
`;

const SignatureSection = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 20px;
`;

const QRTimestampSection = styled.div`
  grid-column: span 2;
`;

const StyledImg = styled.img`
  width: 240px;
  margin-left: 12px;
`;

const CaptialiseSpan = styled.span`
  text-transform: capitalize;
`;

const DropdownWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

const TripDetailsSection: React.FC<TripDetailsSectionProps> = ({
  trip,
  editable,
  updateTripData,
  aircraftId,
  updateTripDataMulti,
}) => {
  const {
    userSettings: { dateFormat },
    drawer: { mode },
    people: { peopleMap, deletedPeopleMap },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const [callsign, setCallsign] = useState('');
  const [defaultUsed, setDefaultUsed] = useState(false);
  const [category, setCategory] = useState(null);
  const [customDataArray, setCustomDataArray] = useState([]);
  const [peopleArray, setPeopleArray] = useState([]);
  const [flyingPeople, setFlyingPeople] = useState([]);
  const [monitoringPeople, setMonitoringPeople] = useState([]);
  const [pilotFlying, setPilotFlying] = useState(null);
  const [pilotMonitoring, setPilotMonitoring] = useState(null);
  const [date, setDate] = useState(moment());
  const [peopleLoading, setPeopleLoading] = useState(false);
  const [combinedPeopleArray, setCombinedPeopleArray] = useState([]);

  const operatorId = aircraftMap.get(aircraftId)?.operator_id;
  let renderSIC = true;
  if (
    aircraftMap.get(aircraftId) &&
    Object.keys(aircraftMap.get(aircraftId)).includes('multipilot_rated') &&
    !aircraftMap.get(aircraftId)?.multipilot_rated
  ) {
    renderSIC = false;
  }

  const displayTripCategory = aircraftMap.get(aircraftId)?.standard_fields?.trip_category?.enabled;
  const renameTripCategory = aircraftMap.get(aircraftId)?.standard_fields?.trip_category?.name_override;
  const displayCallSign = aircraftMap.get(aircraftId)?.standard_fields?.trip_callsign?.enabled;
  const renameCallsign = aircraftMap.get(aircraftId)?.standard_fields?.callsign?.name_override;
  const aircraftQRCode = aircraftMap.get(aircraftId)?.standard_fields?.confirm_aircraft_with_qr_code?.enabled;
  const defaultCallsign = aircraftMap.get(aircraftId)?.app_settings?.default_callsign;

  const dispatch = useDispatch();

  const handleCategoryChange = (option): void => {
    setCategory(option.title);
    updateTripData('trip_category', option.title);
  };

  const handleCallsignChange = (value: string): void => {
    setCallsign(value);
    setDefaultUsed(true);
    updateTripData('callsign', value);
  };

  const selectOptions = [
    {
      title: 'private',
      value: 'private',
    },
    {
      title: 'commercial',
      value: 'commercial',
    },
  ];

  const handlePersonChange = (id: string, role: string): void => {
    const filteredPeople = peopleArray.filter((p) => p?.value !== id);
    if (role === 'captain') {
      const pilot = peopleArray.find((person) => person?.value === id);
      setPilotFlying(pilot);
      if (id) {
        setMonitoringPeople(filteredPeople);
      } else {
        setMonitoringPeople(peopleArray);
      }
      const cap = combinedPeopleArray.find((p) => p?.id === id);
      updateTripData('captain', cap, 'captain_id', id);
    }
    if (role === 'first') {
      const firstOfficer = peopleArray.find((person) => person?.value === id);
      if (id) {
        setFlyingPeople(filteredPeople);
      } else {
        setFlyingPeople(peopleArray);
      }
      if (firstOfficer) {
        setPilotMonitoring(firstOfficer);
        const fO = combinedPeopleArray.find((p) => p?.id === id);
        updateTripData('first_officer', fO, 'first_officer_id', id);
      } else {
        setPilotMonitoring({ value: '', title: 'None' });
        updateTripData('first_officer_id', null);
        updateTripData('first_officer', null);
      }
    }
  };

  const handleDateChange = (dateMoment): void => {
    setDate(dateMoment);
    updateTripData('date', dateMoment.format('YYYY-MM-DD'));
  };

  const disabledDate = (current): boolean => {
    if (mode === 'add') {
      return current && current > moment().add(7, 'day').startOf('day');
    }
    return false;
  };

  useEffect(() => {
    if (peopleMap.size === 0) {
      setPeopleLoading(true);
      dispatch(getPeople());
    } else {
      setPeopleLoading(false);
    }
  }, [peopleMap]);

  useEffect(() => {
    if (peopleMap.size > 0 || deletedPeopleMap.size > 0) {
      setCombinedPeopleArray([...peopleMap.values(), ...deletedPeopleMap.values()]);
    }
  }, [peopleMap, deletedPeopleMap]);

  useEffect(() => {
    if (combinedPeopleArray.length > 0) {
      let selectedIds = [];
      if (trip?.additional_crews_attributes) {
        selectedIds = trip.additional_crews_attributes.map((ac) => ac.person_id);
      }
      const newArray = combinedPeopleArray;
      const availablePeople = newArray.reduce((arr, person) => {
        if (selectedIds.includes(person.id)) {
          return arr;
        }
        arr.push(person);
        return arr;
      }, []);

      const pilotArray = availablePeople
        .filter(
          (person) =>
            (person.position === 'Pilot' || person.position === 'Admin') && person.organisation.id === operatorId,
        )
        .sort((a, b) => {
          if (a?.last_name && b?.last_name) return a.last_name.localeCompare(b.last_name);
          return 0;
        })
        .map((person) => {
          const displayText = getDisplayDetailsOfPerson(person);
          return {
            value: person.id,
            title: displayText,
          };
        });

      setPeopleArray(pilotArray);
      pilotArray.push({ title: 'None', value: undefined });
      let peopleFlying = [...pilotArray];
      let peopleMonitoring = [...pilotArray];
      const captain = pilotArray.find(
        (person) => person.value === trip?.captain_id || person.value === trip?.captain?.id,
      );
      if (captain) {
        setPilotFlying(captain);
        peopleMonitoring = pilotArray.filter((p) => p.value !== captain.value);
      } else {
        setPilotFlying({ value: '', title: 'None' });
      }
      if (combinedPeopleArray.length > 1 && pilotArray.length > 1) {
        const firstOfficer = pilotArray.find(
          (person) => person.value === trip?.first_officer?.id || person.value === trip?.first_officer_id,
        );
        if (firstOfficer) {
          setPilotMonitoring(firstOfficer);
          peopleFlying = pilotArray.filter((p) => p.value !== firstOfficer.value);
          updateTripData('first_officer_id', firstOfficer.value);
        } else {
          setPilotMonitoring({ value: '', title: 'None' });
        }
      }
      setFlyingPeople(peopleFlying);
      setMonitoringPeople(peopleMonitoring);
    }
  }, [combinedPeopleArray]);

  useEffect(() => {
    if (trip?.additional_crews_attributes || trip?.additional_crews) {
      const attributes = trip?.additional_crews_attributes?.map((ac) => ac.person_id) || [];
      const crews = trip?.additional_crews?.map((ac) => ac.person_id) || [];
      const selectedIds = [...attributes, ...crews];
      const newArray = combinedPeopleArray;
      const pilotArray = newArray
        .filter((p) => !selectedIds.includes(p.id) && p.position === 'Pilot' && p.organisation.id === operatorId)
        .sort((a, b) => {
          if (a?.last_name && b?.last_name) return a.last_name.localeCompare(b.last_name);
          return 0;
        })
        .map((person) => {
          const displayText = getDisplayDetailsOfPerson(person);
          return {
            value: person.id,
            title: displayText,
          };
        });
      pilotArray.push({ title: 'None', value: undefined });
      const flyingPpl = pilotArray.filter((p) => p.value !== trip.first_officer_id);
      const monitoringPpl = pilotArray.filter((p) => p.value !== trip.captain_id);
      setFlyingPeople(flyingPpl);
      setMonitoringPeople(monitoringPpl);
    }
    if (trip?.custom_data && Array.of(Object.keys(trip?.custom_data)).length) {
      const useCustomData = Object.entries(trip?.custom_data).filter((array) => array[0] !== 'trend_monitoring');
      setCustomDataArray(useCustomData);
    }
    if (trip?.callsign) {
      setCallsign(trip.callsign);
    } else if (defaultCallsign && !defaultUsed) {
      setCallsign(defaultCallsign);
      updateTripData('callsign', defaultCallsign);
    } else {
      setCallsign('');
    }
    if (displayTripCategory !== false) {
      if (trip?.trip_category && category !== trip?.trip_category) {
        setCategory(trip.trip_category);
        updateTripData('trip_category', trip?.trip_category);
      }
    }
    if (trip?.date) setDate(moment(trip?.date));
    if (trip?.captain?.id && !trip?.captain_id) {
      updateTripData('captain_id', trip?.captain?.id);
      setPilotFlying({
        value: trip?.captain?.id,
        title: getDisplayDetailsOfPerson(trip?.captain),
      });
    }
    if (trip?.first_officer?.id && !trip?.first_officer_id) {
      updateTripData('first_officer_id', trip?.first_officer?.id);
      setPilotMonitoring({
        value: trip?.first_officer?.id,
        title: getDisplayDetailsOfPerson(trip?.first_officer),
      });
    }
  }, [trip]);

  useEffect(() => {
    if (pilotMonitoring && !trip?.first_officer_id) {
      updateTripData('first_officer_id', pilotMonitoring.value);
    }
  }, [pilotMonitoring]);

  const customDataElements = (): ReactNode => {
    // only for images as new custom data section deals with rest
    return customDataArray.map((item, index) => {
      const header = item[0].replace(/_/gi, ' ');
      let displayText = <DisplayText>{item[1]}</DisplayText>;
      if (/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(item[1])) {
        displayText = <img src={`${servers.api}/attachments/thumbnail/${item[1]}.png`} alt="attachment" />;
        return (
          <div key={item[1]} data-testid={`PreFlightCustomDataSection-CustomElement${index}`}>
            <Header>{header}</Header>
            {displayText}
          </div>
        );
      }
      return null;
    });
  };

  const isMobile = window.innerWidth < 451;

  return (
    <>
      <SectionHeader data-testid="TripDetailsSection-Header">Trip Details</SectionHeader>
      <ContentWrapper isMobile={isMobile} editable={mode !== 'view'}>
        <div data-testid="TripDetailsSection-Date">
          <Header editable={mode !== 'view'}>Date</Header>
          {mode === 'view' ? (
            <DisplayText>{moment(trip?.date).format(dateFormat)}</DisplayText>
          ) : (
            <StyledDatePicker
              format={dateFormat}
              value={date}
              suffixIcon={<img src={calendarIcon} alt="calendarIcon" />}
              allowClear={false}
              onChange={(dateMoment): void => handleDateChange(dateMoment)}
              disabledDate={disabledDate}
            />
          )}
        </div>
        <DropdownWrapper data-testid="TripDetailsSection-Captain">
          {mode === 'view' ? (
            <>
              <Header editable={mode !== 'view'}>PIC</Header>
              <DisplayText>{`${trip?.captain?.first_name || '-'} ${trip?.captain?.last_name || ''}`}</DisplayText>
            </>
          ) : (
            <TFDropdown
              options={flyingPeople}
              onSelect={(value): void => handlePersonChange(value?.value, 'captain')}
              initialValue={pilotFlying}
              id="TripDetailsSection-Captain"
              searchable
              label="PIC"
              disabled={peopleLoading}
            />
          )}
        </DropdownWrapper>
        {!renderSIC ? null : (
          <DropdownWrapper data-testid="TripDetailsSection-FirstOfficer">
            {mode === 'view' ? (
              <>
                <Header editable={mode !== 'view'}>SIC</Header>
                <DisplayText>
                  {`${trip?.first_officer?.first_name || '-'} ${trip?.first_officer?.last_name || ''}`}
                </DisplayText>
              </>
            ) : (
              <TFDropdown
                options={monitoringPeople}
                onSelect={(value): void => handlePersonChange(value?.value, 'first')}
                initialValue={pilotMonitoring}
                id="TripDetailsSection-FirstOfficer"
                searchable
                label="SIC"
                disabled={peopleLoading}
              />
            )}
          </DropdownWrapper>
        )}
        {displayCallSign === false ? null : (
          <div data-testid="TripDetailsSection-Callsign">
            <Header editable={editable}>{renameCallsign || 'Callsign'}</Header>
            {editable ? (
              <TFInput
                id="TripDetailsSection-Callsign"
                value={callsign}
                onChange={(e): void => handleCallsignChange(e.target.value)}
              />
            ) : (
              <DisplayText>{trip?.callsign}</DisplayText>
            )}
          </div>
        )}
        {displayTripCategory ? (
          <div data-testid="TripDetailsSection-Category">
            <Header editable={editable}>{renameTripCategory || 'Category'}</Header>
            {editable ? (
              <TFSelect
                height={34}
                width={isMobile ? 285 : 200}
                options={selectOptions}
                initial={category ? { title: category } : null}
                handleSelectChange={handleCategoryChange}
                allowEmpty
              />
            ) : (
              <DisplayText>
                <CaptialiseSpan>{trip?.trip_category || '-'}</CaptialiseSpan>
              </DisplayText>
            )}
          </div>
        ) : null}
        {aircraftQRCode && !editable && (trip.scanned_justification || trip.scanned_timestamp) ? (
          <QRTimestampSection data-testid="TripDetailsSection-ScannedTimeStamp">
            <Header editable={editable}>Aircraft confirmed</Header>
            <DisplayText>
              <CaptialiseSpan>
                {trip?.scanned_timestamp
                  ? moment(trip?.scanned_timestamp).format(`${dateFormat} HH:mm`)
                  : `No${trip?.scanned_justification ? `- ${trip.scanned_justification}` : ''}`}
              </CaptialiseSpan>
            </DisplayText>
          </QRTimestampSection>
        ) : null}
        {customDataArray?.length ? customDataElements() : null}
      </ContentWrapper>
      <CustomFieldSection
        customData={trip?.custom_data}
        saveKey="custom_data"
        updateData={updateTripDataMulti}
        customFieldKey="trip"
      />
      {trip?.signature_data ? (
        <SignatureSection data-testid="TripDetailsSection-Signature">
          Signed by:
          <StyledImg alt="Signature" src={`data:image/png;base64, ${trip?.signature_data}`} />
        </SignatureSection>
      ) : null}
    </>
  );
};

export default TripDetailsSection;
