/* eslint-disable react/jsx-props-no-spreading */
import { DatePicker } from 'antd';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import WarningRow from '../../WarningRow';
import TFRadioInput from '../../TFRadioInput/TFRadioInput';
import FlexWrapper from './FlexWrapper';
import Label from './Label';

interface RectificationIntervalExtensionProps {
  partialDefectData: {
    rectificationIntervalExtension: {
      extension_date: string;
      extension_date_due: string;
    };
    raisedAt: string;
    displayData: {
      category: string;
    };
    deferred: boolean;
  };
  onChange: Function;
  updateDefectData: (changes: { key: string; value: any }[]) => void;
  requiredSettings: {
    defectRename: string;
  };
}

const StyledDatePicker = styled(DatePicker)`
  .ant-input {
    height: 40px;
    border-radius: 2px;
    border: solid 1px rgba(36, 45, 65, 0.1);
    background-color: #f3f7fc;
  }
`;

const RectificationIntervalExtension: React.FC<RectificationIntervalExtensionProps> = ({
  partialDefectData,
  onChange,
  updateDefectData,
  requiredSettings,
}) => {
  const { formatMessage } = useIntl();

  const [isUsingRectificationExtension, setIsUsingRectificationExtension] = useState(false);
  const [extensionDate, setExtensionDate] = useState(null);
  const [extensionDateDue, setExtensionDateDue] = useState<undefined | Moment>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!partialDefectData || loaded) {
      return;
    }

    const rie = partialDefectData.rectificationIntervalExtension;
    setLoaded(true);
    setIsUsingRectificationExtension(!!rie);

    if (rie) {
      const dateDue = rie.extension_date_due ? moment.utc(rie.extension_date_due) : null;
      setExtensionDate(moment.utc(rie.extension_date));
      setExtensionDateDue(dateDue);
      updateDefectData([
        { value: moment.utc(rie.extension_date), key: 'rectificationExtenstionExtendedOn' },
        { value: dateDue, key: 'rectificationExtensionDateDue' },
        { value: true, key: 'hasRectificationExtension' },
      ]);
    }
  }, [partialDefectData, loaded, updateDefectData]);

  const handleExtensionDateChange = (date: Moment): void => {
    setExtensionDate(date);
    onChange();
    if (extensionDateDue && extensionDateDue.isBefore(date)) {
      setExtensionDateDue(date);
      updateDefectData([
        { value: date, key: 'rectificationExtenstionExtendedOn' },
        { value: date, key: 'rectificationExtensionDateDue' },
      ]);
    } else {
      updateDefectData([{ value: date, key: 'rectificationExtenstionExtendedOn' }]);
    }
  };

  const handleExtensionDateDueChange = (date: Moment): void => {
    setExtensionDateDue(date);
    updateDefectData([{ value: date, key: 'rectificationExtensionDateDue' }]);
    onChange();
  };

  const disableDatesBefore = (date): boolean => {
    return date.isBefore(moment.utc(partialDefectData?.raisedAt).subtract(1, 'd'));
  };

  const disableDatesBeforeExtension = (date): boolean => {
    return date.isBefore(moment.utc(extensionDate));
  };

  const rectificationExtensionFields = (): ReactElement => {
    const extensionDateDueProps: { defaultValue?: Moment } = {};

    if (extensionDateDue) {
      extensionDateDueProps.defaultValue = extensionDateDue;
    }

    return (
      <>
        <div>
          <Label marginTop={20} marginBottom={5} fontWeight={500}>
            {formatMessage({ id: 'form.labels.defectExtendedOn' })}
          </Label>
          <StyledDatePicker
            value={extensionDate}
            allowClear={false}
            onChange={handleExtensionDateChange}
            name="rectificationExtenstionExtendedOn"
            disabledDate={disableDatesBefore}
          />
        </div>
        <div>
          <Label marginTop={20} marginBottom={5} fontWeight={500}>
            {formatMessage({ id: 'form.labels.defectExtensionDateDue' })}
          </Label>
          <StyledDatePicker
            {...extensionDateDueProps}
            value={extensionDateDue}
            onChange={handleExtensionDateDueChange}
            name="rectificationExtensionDateDue"
            disabledDate={disableDatesBeforeExtension}
          />
        </div>
        {!extensionDateDue ? (
          <WarningRow
            message={formatMessage({ id: 'text.defectExtensionNoDateDue' }, { field: requiredSettings.defectRename })}
          />
        ) : null}
      </>
    );
  };

  return (
    <FlexWrapper column>
      <Label marginBottom={20} fontWeight={600}>
        {formatMessage({ id: 'form.labels.rectificationIntervalExtension' })}
      </Label>
      <Label marginBottom={10} fontWeight={500}>
        {formatMessage({ id: 'text.applyDefectExtension' }, { field: requiredSettings.defectRename })}
      </Label>
      <TFRadioInput
        options={[
          { title: 'Yes', value: true },
          { title: 'No', value: false },
        ]}
        onChange={(value: boolean): void => {
          setIsUsingRectificationExtension(value);
          updateDefectData([{ value, key: 'hasRectificationExtension' }]);
        }}
        value={isUsingRectificationExtension}
      />
      {isUsingRectificationExtension ? rectificationExtensionFields() : null}
    </FlexWrapper>
  );
};

export default RectificationIntervalExtension;
