import { useQuery } from '@tanstack/react-query';
import { getCapabilites } from '../../api';
import { Capability } from '../../../models/people';

const useQueryGetCapabilities = (): { data: Capability[] } => {
  const { data } = useQuery({
    queryKey: ['capabilities'],
    queryFn: getCapabilites,
    refetchOnWindowFocus: false,
    staleTime: 30000,
  });
  return { data };
};

export default useQueryGetCapabilities;
