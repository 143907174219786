import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { changeBannerVisible } from '../../models/menu';

const BannerWrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e5bb5;
  top: 0;
  position: fixed;
  z-index: 1001;
`;

const ContentDiv = styled.div`
  color: #fff;
  margin-left: 50px;
  a {
    margin-left: 8px;
    text-decoration: underline;
    color: #fff;
    font-weight: 500;
    @media screen and (max-width: 475px) {
      margin-left: 4px;
    }
  }
`;

const NonMobileText = styled.span`
  margin-left: 4px;
  @media screen and (max-width: 475px) {
    display: none;
  }
`;

interface BannerPros {
  content?: ReactElement;
}

const Banner: React.FC<BannerPros> = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    menu: { bannerVisible },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const bannerClosed = localStorage.getItem('bannerSeen');

  useEffect(() => {
    if (bannerClosed) {
      dispatch(changeBannerVisible({ payload: false }));
    }
  }, [bannerClosed, dispatch]);

  const hasSuspendedAccounts = userSettings?.details?.operators?.some(
    (operator) => operator?.billing_status === 'suspended',
  );

  const content = (
    <ContentDiv>
      <span>
        <NonMobileText>
          {hasSuspendedAccounts
            ? formatMessage({ id: 'text.suspendedAccountMessage' })
            : formatMessage({ id: 'text.overdueAccountMessage' })}
        </NonMobileText>
      </span>
    </ContentDiv>
  );

  return (
    <>
      {bannerVisible && !bannerClosed ? (
        <BannerWrapper>
          <div>{content}</div>
        </BannerWrapper>
      ) : (
        <div />
      )}
    </>
  );
};

export default Banner;
