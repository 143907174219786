import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Loading from '../TFLoading';
import { getPublicShareURL } from '../../services/api';
import shareIcon from '../../assets/link-share.svg';
import greenTick from '../../assets/complete-icon.svg';
import TFPopover from '../TFPopover/TFPopover';
import { ToastTypes, ToastCategories, addToast } from '../../models/toasts';

interface PublicShareLinkProps {
  type: 'scheduled_mx_items' | 'defects' | 'workpacks';
  id?: string;
}

const defaultProps = {
  id: '',
};

const StyledButton = styled.button`
  color: ${({ theme }): string => theme.colours.blue};
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  padding: 0;
`;

const PopoverWrapper = styled.div`
  width: 440px;
  background-color: ${({ theme }): string => theme.colours.grayTagBackground};
  box-shadow: ${({ theme }): string => `0 0 10px 0 ${theme.colours.overviewBoxShadow}`};
  border: ${({ theme }): string => `2px solid ${theme.colours.white}`};
  text-align: left;
  padding: 15px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border: ${({ theme }): string => `solid 0.8px ${theme.colours.greenLinkBorder}`};
  background-image: ${({ theme }): string =>
    `linear-gradient(to bottom, ${theme.colours.greenLinearGradientStart}, ${theme.colours.greenLinearGradientEnd})`};
  border-radius: 50%;
  text-align: center;
  display: inline-block;
`;

const ClipboardText = styled.div`
  font-weight: 600;
  letter-spacing: 0.06px;
  color: ${({ theme }): string => theme.colours.titleDarkBlack};
  display: inline-block;
  margin-left: 10px;
`;

const LinkText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05px;
  color: ${({ theme }): string => theme.colours.greyLinkText};
  margin-top: 10px;
  margin-bottom: 10px;
  word-break: break-all;
`;

const PopoverText = styled.div`
  opacity: 0.7;
  line-height: 1.57;
  letter-spacing: 0.06px;
  color: ${({ theme }): string => theme.colours.titleDarkBlack};
`;

const LoadingWrapper = styled.div`
  width: 440px;
  height: 176px;
  background: ${({ theme }): string => theme.colours.white};
`;

const PublicShareLink: React.FC<PublicShareLinkProps> = ({ type, id }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [publicLinkLoading, setPublicLinkLoading] = useState(false);
  const [publicLink, setPublicLink] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ right: '0px', top: '0px' });

  const handleCopyUrl = (url: string): void => {
    const el = document.createElement('textarea');
    el.value = url;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const getPublicUrlLink = async (): Promise<void> => {
    setPublicLinkLoading(true);
    try {
      const response = await getPublicShareURL({ type, id });
      setPublicLink(response);
      handleCopyUrl(response);
    } catch (err) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.failedToCreatePublicUrl' }),
            type: ToastTypes.ERROR,
            message: err.message || formatMessage({ id: 'text.failedToCreatePublicUrl' }),
            category: ToastCategories.FLAG,
          },
        }),
      );
      setShowPopup(false);
    } finally {
      setPublicLinkLoading(false);
    }
  };

  const handlePopoverClose = (): void => {
    setShowPopup(false);
  };

  const handlePopoverOpen = (): void => {
    const button = document.getElementById('publicButton');
    if (button) {
      const rect = button.getBoundingClientRect();
      const isMobile = window.innerWidth <= 768;
      setButtonPosition({
        right: isMobile ? '0px' : `${window.innerWidth - rect.right}px`,
        top: `${rect.bottom}px`,
      });
    }
    setShowPopup(true);
    getPublicUrlLink();
  };

  return (
    <>
      <StyledButton data-testid="PublicShareLink" type="button" id="publicButton" onClick={handlePopoverOpen}>
        <img src={shareIcon} alt="share icon" /> {formatMessage({ id: 'text.publicShareLink' })}
      </StyledButton>
      {showPopup && (
        <TFPopover right={buttonPosition.right} top={buttonPosition.top} onClick={handlePopoverClose}>
          {publicLinkLoading ? (
            <LoadingWrapper>
              <Loading loading={publicLinkLoading} contain />
            </LoadingWrapper>
          ) : (
            <PopoverWrapper data-testid="PublicShareLinkPopover">
              <ImageContainer>
                <img src={greenTick} alt="Green Tick" />
              </ImageContainer>
              <ClipboardText data-testid="PublicShareLinkCopied">
                {formatMessage({ id: 'text.copiedToClipboard' })}
              </ClipboardText>
              <LinkText data-testid="PublicShareLinkURL">{publicLink}</LinkText>
              <PopoverText data-testid="PublicShareLinkPopoverText">
                {formatMessage({ id: 'text.validFor30days' })}
              </PopoverText>
            </PopoverWrapper>
          )}
        </TFPopover>
      )}
    </>
  );
};

PublicShareLink.defaultProps = defaultProps;

export default PublicShareLink;
