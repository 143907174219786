import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Flight } from '../../models/flights';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import EmptyStateDefects from '../../assets/emptyState/empty-state-sector-defects.svg';
import warningIcon from '../../assets/login-error-amber.svg';
import { DashboardState } from '../../models';
import plusIcon from '../../assets/plus-blue.svg';
import { changeDrawerContent, changeDrawerMode, setDrawerId } from '../../models/drawer';
import DefectDrawer from '../DefectDrawer/NewDrawer';
import { SectionHeader } from './FlightDrawer';
import FlightDefectsTable from './FlightDefectsTable';

interface DefectsSectionProps {
  flight: Flight;
  setCheckboxClicked: (value: boolean) => void;
  defectsChecked?: boolean;
  setDefectsChecked?: (value: boolean) => void;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ addMargin }): string => (addMargin ? '32px' : '')};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleWrapper = styled.div``;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const NoDefectsText = styled.span`
  margin-left: 8px;
`;

const DefectsSubHeader = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 4px;
  color: #242d41;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: ${({ noMargin }): string => (noMargin ? '0' : ' 0 0 32px')};
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 32px;
  img {
    margin-right: 8px;
  }
`;

const ErrorText = styled.span`
  line-height: 1.43;
  color: rgba(36, 45, 65, 0.7);
`;

const DefectsSection: React.FC<DefectsSectionProps> = ({
  flight,
  setCheckboxClicked,
  defectsChecked,
  setDefectsChecked,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const [defectsAddedWarning, setDefectsAddedWarning] = useState(false);

  const hideDefectCheckBox = aircraftMap.get(id)?.standard_fields?.defects_reported?.enabled === false;
  const sectorNameOverride = useGetOperatorSetting('sector_name', id) || formatMessage({ id: 'text.sector' });
  const defectNameOverride = useGetOperatorSetting('defect_name', id);
  const defectsNameOverride = useGetOperatorSetting('defect_name_plural', id);

  const handleAddDefect = (): void => {
    dispatch(
      changeDrawerContent({
        payload: {
          content: <DefectDrawer defectId="" newFlight passedFlightId={flight?.id} />,
          backButton: true,
          previousContent: flight,
        },
      }),
    );
    dispatch(changeDrawerMode({ payload: 'add' }));
    dispatch(setDrawerId({ payload: '' }));
  };

  const handleCheckBoxClick = (value: boolean): void => {
    setDefectsChecked(value);
    setCheckboxClicked(true);
  };

  useEffect(() => {
    if (flight?.defects?.length && !defectsChecked) {
      if (setDefectsChecked) {
        setDefectsChecked(true);
      }
      setCheckboxClicked(true);
    }
    if (mode === 'edit' && flight?.defects?.length === 0 && !defectsChecked) {
      if (setDefectsChecked) {
        setDefectsChecked(false);
      }
      setCheckboxClicked(true);
    }
  }, [mode, flight, setCheckboxClicked, defectsChecked, setDefectsChecked]);

  return (
    <SectionWrapper data-testid="FlightDrawer-DefectsSection">
      <TitleWrapper>
        <AddButtonWrapper>
          <SectionHeader marginLeft="20px" reducedBottomMargin data-testid="DefectsSection-Header">
            {`${sectorNameOverride} ${defectNameOverride}` || formatMessage({ id: 'text.sectorDefects' })}
          </SectionHeader>
        </AddButtonWrapper>
        {mode !== 'view' ? (
          <>
            {!hideDefectCheckBox ? (
              <DefectsSubHeader>{`${defectNameOverride || 'Defect'
                } recorded in this ${sectorNameOverride}`}</DefectsSubHeader>
            ) : null}
            <HeaderWrapper>
              {hideDefectCheckBox ? (
                <>
                  {flight?.defects?.length ? null : (
                    <EmptyStateDiv data-testid="DefectsSection-EmptyState">
                      <div>
                        <img src={EmptyStateDefects} alt="no defects" />
                        <NoDefectsText>
                          {`No ${defectNameOverride || 'defects'
                            } currently recorded on this ${sectorNameOverride.toLowerCase()}`}
                        </NoDefectsText>
                      </div>
                    </EmptyStateDiv>
                  )}
                </>
              ) : (
                <CheckBoxWrapper addMargin={flight?.defects?.length && !defectsAddedWarning}>
                  <RadioInputButton
                    text="Yes"
                    active={defectsChecked}
                    marginRight="8px"
                    onClick={(): void => {
                      handleCheckBoxClick(true);
                    }}
                  />
                  <RadioInputButton
                    text="No"
                    active={defectsChecked === false}
                    marginRight="8px"
                    onClick={(): void => {
                      if (flight?.defects?.length) {
                        setDefectsAddedWarning(true);
                      } else {
                        handleCheckBoxClick(false);
                      }
                    }}
                  />
                </CheckBoxWrapper>
              )}
              {flight?.defects?.length === 0 && (defectsChecked || hideDefectCheckBox) ? (
                <ButtonWrapper>
                  <Button height="24px" onClick={handleAddDefect} primary={false}>
                    <AddTextWrapper>
                      <PlusIcon src={plusIcon} alt="plus icon" />
                      {` Add ${defectNameOverride || 'defect'}`}
                    </AddTextWrapper>
                  </Button>
                </ButtonWrapper>
              ) : null}
            </HeaderWrapper>
          </>
        ) : null}
      </TitleWrapper>
      {defectsAddedWarning ? (
        <ErrorWrapper>
          <img src={warningIcon} alt="error icon" />
          <ErrorText>
            {`Selection cannot be changed as a ${defectNameOverride || 'defect'
              } has already been added to this ${sectorNameOverride.toLowerCase()}`}
          </ErrorText>
        </ErrorWrapper>
      ) : null}
      <div>
        {flight?.defects?.length ? (
          <>
            <FlightDefectsTable defects={flight?.defects} />
            {mode !== 'view' ? (
              <ButtonWrapper>
                <Button height="24px" onClick={handleAddDefect} primary={false}>
                  <AddTextWrapper>
                    <PlusIcon src={plusIcon} alt="plus icon" />
                    {` Add ${defectNameOverride || 'defect'}`}
                  </AddTextWrapper>
                </Button>
              </ButtonWrapper>
            ) : null}
          </>
        ) : (
          <>
            {mode === 'view' ? (
              <EmptyStateDiv data-testid="DefectsSection-EmptyState">
                <div>
                  <img src={EmptyStateDefects} alt="no defects" />
                  <NoDefectsText>
                    {`No ${defectsNameOverride || 'defects'
                      } currently recorded on this ${sectorNameOverride.toLowerCase()}`}
                  </NoDefectsText>
                </div>
              </EmptyStateDiv>
            ) : null}
          </>
        )}
      </div>
    </SectionWrapper>
  );
};

export default DefectsSection;
