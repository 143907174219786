import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Label, Text } from '../../CommonStyledComponents/CommonStyledComponents';
import { Defect } from '../../../models/defects';

interface ViewDefectDrawerRectificationLimitsProps {
  defectData: Defect;
}

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px 10px;
  @media (max-width: 451px) {
    grid-template-columns: 1fr 1fr;
  }
`;

type LimitItem = {
  limit: number | string;
  label: string;
  isHours?: boolean;
  formatMessage: (any) => string;
};

const buildRectificationLimits = (limit: LimitItem) => {
  const displayLimit = limit.isHours && typeof limit.limit === 'number' ? limit.limit / 3600 : limit.limit || '-';
  return (
    <div data-testid={`ViewDefectDrawerRectificationLimits-${limit.label}`}>
      <Label>{limit.formatMessage({ id: `form.labels.${limit.label}` })}</Label>
      <Text>{displayLimit}</Text>
    </div>
  );
};

const ViewDefectDrawerRectificationLimits: React.FC<ViewDefectDrawerRectificationLimitsProps> = ({ defectData }) => {
  const { formatMessage } = useIntl();

  const limits: LimitItem[] = [
    { limit: defectData?.flight_seconds_limit, label: 'flightHourLimit', isHours: true, formatMessage },
    { limit: defectData?.apu_seconds_limit, label: 'apuHoursLimit', isHours: true, formatMessage },
    { limit: defectData?.flight_days_limit, label: 'flightDaysLimit', formatMessage },
    { limit: defectData?.seconds_limit, label: 'calendarHoursLimit', isHours: true, formatMessage },
    { limit: defectData?.calendar_days_limit, label: 'calendarDaysLimit', formatMessage },
    { limit: defectData?.cycles_limit, label: 'cyclesLimit', formatMessage },
    { limit: defectData?.flights_limit, label: 'flightLimit', formatMessage },
    { limit: defectData?.other_limit, label: 'otherLimit', formatMessage },
  ];

  return (
    <GridSection data-testid="ViewDefectDrawerRectificationLimits">{limits.map(buildRectificationLimits)}</GridSection>
  );
};

export default ViewDefectDrawerRectificationLimits;
