/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@arcflight/tf-component-library';
import { Defect, DefectType } from '../../../models/defects';
import { DashboardState } from '../../../models';
import melChapters from '../../../assets/melChapters.json';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import TFDropdown from '../../TFDropdown/TFDropdown';
import TFInput from '../../TFInput/TFInput';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import DeferUntil from './DeferUntil';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import RectificationLimits from './RectificationLimits';
import { createMELChapters, getMELSections, getNewDateDueBasedOnInterval, handleDeferralTimeChange } from './utils';

interface ManuallyEnterMELDetailsProps {
  defect: Defect;
  noMEL?: boolean;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  aircraftId?: string;
  formChanged: boolean;
  poShortName: string;
  poSuffix: string;
}

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: 20px;
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
  margin-left: -3px;
`;

const DropdownWrapper = styled.div`
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}` : '0')};
  margin-top: 20px;
`;

const InputWrapper = styled.div`
  margin-top: 20px;
`;

const Chapter0AtaSections = Array.from({ length: 100 }, (_, index) => {
  const indexStr = index.toString();
  return {
    value: indexStr,
    title: indexStr,
  };
});

const ManuallyEnterMELDetails: React.FC<ManuallyEnterMELDetailsProps> = ({
  defect,
  noMEL,
  updateDefectData,
  aircraftId,
  formChanged,
  poShortName,
  poSuffix,
}) => {
  const { formatMessage } = useIntl();

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const melNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.name_override;

  const [showRecticationLimits, setShowRectificationLimits] = useState(false);
  const [MELChapters, setMELChapters] = useState([]);
  const [MELSections, setMELSections] = useState([]);
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);

  const updateAtaData = (value: string, key: string, displayValue: string | number, displayKey: string): void => {
    const ataUpdates = [];
    const newDisplayData = {
      ...defect?.display_data,
      [`${displayKey}`]: displayValue,
    };

    // reset the ATA section to 0 when the ATA chapter is changed
    if (key === 'ata_chapter') {
      newDisplayData.ata_section = 0;
      ataUpdates.push({ value: 0, key: 'ata_section' });
    }

    ataUpdates.push({ value: parseInt(value, 10), key });
    ataUpdates.push({ value: newDisplayData, key: 'display_data' });
    updateDefectData(ataUpdates);
  };

  const handleCategoryChange = (option: { title: string }): void => {
    const newDateDue = getNewDateDueBasedOnInterval(option.title, defect.raised_at);
    if (option.title === 'A') {
      updateDefectData([
        { value: option.title, key: 'rectification_category' },
        { value: { ...defect.display_data, category: option.title }, key: 'display_data' },
        { value: newDateDue, key: 'date_due' },
      ]);
    } else {
      updateDefectData([
        { value: option.title, key: 'rectification_category' },
        { value: { ...defect.display_data, category: option.title }, key: 'display_data' },
        { value: newDateDue, key: 'date_due' },
        { value: null, key: 'flight_seconds_limit' },
        { value: null, key: 'apu_seconds_limit' },
        { value: null, key: 'flight_days_limit' },
        { value: null, key: 'seconds_limit' },
        { value: null, key: 'calendar_days_limit' },
        { value: null, key: 'cycles_limit' },
        { value: null, key: 'flights_limit' },
        { value: null, key: 'other_limit' },
      ]);
    }
  };

  const handleChapterChange = (option): void => {
    updateAtaData(option.value, 'ata_chapter', option.title, 'ata');
  };

  const handleSectionChange = (option): void => {
    updateAtaData(option.value, 'ata_section', parseInt(option.value, 10), 'ata_section');
  };

  const handleReferenceChange = (value: string): void => {
    updateDefectData([{ value, key: 'reference' }]);
  };

  const getCurrentMelChapter = (): { title: string, value: string } | null => {
    return defect?.ata_chapter != null
      ? MELChapters.find(chapter => chapter.value === defect?.ata_chapter?.toString())
      : null
  };

  const getCurrentMelSection = (): { title: string, value: string } | null => {
    return defect?.ata_section != null
      ? MELSections.find(section => section.value === defect?.ata_section?.toString())
      : null;
  };

  useEffect(() => {
    if (melChapters) {
      const chapters = createMELChapters(melChapters);
      setMELChapters(chapters);
    }
  }, [defect?.ata_chapter]);

  useEffect(() => {
    if (MELChapters && defect?.ata_chapter != null) {
      const sections = defect?.ata_chapter.toString() === MELChapters[0]?.value
        ? Chapter0AtaSections
        : getMELSections(MELChapters, defect?.ata_chapter?.toString());

      setMELSections(sections);
    }
  }, [defect?.ata_chapter, MELChapters]);

  useEffect(() => {
    if (defect?.display_data?.category === 'A') {
      setShowRectificationLimits(true);
    } else {
      setShowRectificationLimits(false);
    }
  }, [defect?.display_data?.category]);

  const isMobile = window.innerWidth < 451;

  const hasDeferralApplied = defect && Object.prototype.hasOwnProperty.call(defect, 'deferralApplied');
  let defectDeferred = false;
  if (defect?.deferred || (hasDeferralApplied && defect?.deferralApplied)) {
    defectDeferred = true;
  }

  return (
    <div data-testid="ManuallyEnterBookDetails--Wrapper">
      {defect?.defect_type === DefectType.MEL && (
        <>
          {noMEL && defect?.deferred ? (
            <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
              {`This aircraft has no ${melNameOverride || DefectType.MEL} currently, please manually enter the ${
                melNameOverride || DefectType.MEL
              } details below.`}
            </Label>
          ) : (
            <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
              {formatMessage({ id: 'text.pleaseEnterReferenceNumber' }, { po: poShortName, suffix: poSuffix })}
            </Label>
          )}
        </>
      )}
      <InputWrapper>
        <TFInput
          id="reference"
          value={defect?.reference || ''}
          onChange={(e): void => handleReferenceChange(e.target.value)}
          label={formatMessage({ id: 'title.reference' })}
        />
      </InputWrapper>
      {MELChapters ? (
        <>
          {!defectDeferred ? (
            <GridSection>
              <TFDropdown
                options={MELChapters}
                initialValue={getCurrentMelChapter()}
                onSelect={handleChapterChange}
                label={formatMessage({ id: 'title.ataChapter' })}
                id="ATAChapter"
                searchable
              />
              <TFDropdown
                options={MELSections}
                initialValue={getCurrentMelSection()}
                onSelect={handleSectionChange}
                label={formatMessage({ id: 'title.ataSection' })}
                id="ATASection"
              />
            </GridSection>
          ) : (
            <>
              <DropdownWrapper>
                <TFDropdown
                  options={MELChapters}
                  initialValue={getCurrentMelChapter()}
                  onSelect={handleChapterChange}
                  label={formatMessage({ id: 'title.ataChapter' })}
                  id="ATAChapter"
                  searchable
                />
              </DropdownWrapper>
              <GridSection>
                <TFDropdown
                  options={MELSections}
                  initialValue={getCurrentMelSection()}
                  onSelect={handleSectionChange}
                  label={formatMessage({ id: 'title.ataSection' })}
                  id="ATASection"
                />
                <TFDropdown
                  initialValue={{
                    title: defect?.display_data?.category,
                    value: defect?.display_data?.category,
                  }}
                  options={[
                    { title: 'A', value: 'A' },
                    { title: 'B', value: 'B' },
                    { title: 'C', value: 'C' },
                    { title: 'D', value: 'D' },
                    { title: 'Advisory only', value: 'ADVISORYONLY' },
                  ]}
                  onSelect={handleCategoryChange}
                  id="RectificationCategory"
                  label={formatMessage({ id: 'title.rectificationCategory' })}
                />
              </GridSection>
            </>
          )}
        </>
      ) : null}
      {defect?.deferred || defect?.deferralApplied ? (
        <FlexWrapper marginTop={20} column={isMobile} width="100%">
          <FlexWrapper marginRight={20}>
            <DateTimePicker
              dateTime={defect?.deferred_at}
              headerDate="Deferral date"
              headerTime="Deferral time"
              handleDateTimeChange={(value) => handleDeferralTimeChange(value, defect, updateDefectData)}
              disabled={!unlockDeferralTime}
            />
          </FlexWrapper>
          <FlexWrapper marginTop={isMobile ? 20 : 0} width="auto">
            <DeferUntil defect={defect} formChanged={formChanged} />
          </FlexWrapper>
          <ButtonWrapper>
            {!unlockDeferralTime ? (
              <Button primary={false} height="24px" padding="0 12px" onClick={(): void => setUnlockDeferralTime(true)}>
                <StyledIcon src={padlockIcon} alt="padlock icon" />
                {formatMessage({ id: 'text.unlock' })}
              </Button>
            ) : null}
          </ButtonWrapper>
        </FlexWrapper>
      ) : null}
      {showRecticationLimits && (defect?.deferred || defect?.deferralApplied) ? (
        <RectificationLimits defect={defect} updateDefectData={updateDefectData} />
      ) : null}
    </div>
  );
};

ManuallyEnterMELDetails.defaultProps = {
  aircraftId: undefined,
  noMEL: false,
};

export default ManuallyEnterMELDetails;
