import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Accordion } from '@arcflight/tf-component-library';
import { DashboardState } from '../../models';
import { checkNumberIsTwoDecimalPlaces, combineFluidUplifts } from '../../utils/utils';
import hydraulicIcon from '../../assets/icon-card-hydraulics.svg';
import errorIcon from '../../assets/login-error.svg';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { PartsDiv, RowHeader, TitleWrapper } from '../TripUpdateDrawer.tsx/TripUpdateAirframeSection';
import { DisplayText, Header, StyledInput } from './FlightDrawer';

interface HydraulicUpliftsProps {
  flight: any;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const AccordionWrapper = styled.div`
  margin-top: 16px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }): string => (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
  padding: 16px 20px;
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const UnitSelectionWrapper = styled.div`
  grid-column: span 4;
`;

const UnitSpan = styled.span`
  margin-left: 8px;
`;

const ErrorWrapper = styled.div`
  font-size: '16px';
  color: '#ac1717';
  margin-top: 4px;
  img {
    margin-right: 4px;
  }
`;

const HydraulicUplifts: React.FC<HydraulicUpliftsProps> = ({ flight, updateFlightData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [hydraulicUplifts, setHydraulicUplifts] = useState([]);
  const [numberOfParts, setNumberOfParts] = useState(0);
  const [errorMap, setErrorMap] = useState(new Map());

  const handleInputChange = (value: number, key: string, name: string): void => {
    if (checkNumberIsTwoDecimalPlaces(value.toString())) {
      errorMap.delete(name);
      setErrorMap(errorMap);
    } else {
      errorMap.set(name, true);
      setErrorMap(errorMap);
    }
    const foundIndex = hydraulicUplifts.findIndex((uplift) => uplift?.hydraulic_circuit_name === name);
    hydraulicUplifts[foundIndex] = { ...hydraulicUplifts[foundIndex], uplift: value };
    const existingFluidUplifts = flight?.fluid_uplifts_attributes || flight?.fluid_uplifts;
    const newHydraulicLogs = combineFluidUplifts(hydraulicUplifts, existingFluidUplifts, true);
    updateFlightData([{ value: newHydraulicLogs, key: 'fluid_uplifts_attributes' }]);
  };

  const handleHydraulicUnitChange = (value: string): void => {
    let newArray = [...hydraulicUplifts];
    newArray = newArray.map((item) => {
      return {
        ...item,
        uplift_unit: value,
      };
    });
    const existingFluidUplifts = flight?.fluid_uplifts_attributes || flight?.fluid_uplifts;
    const newFluidArray = combineFluidUplifts(newArray, existingFluidUplifts);
    updateFlightData([{ value: newFluidArray, key: 'fluid_uplifts_attributes' }]);
  };

  const displayHydraulicUplifts = (): ReactElement[] => {
    return hydraulicUplifts.map((item) => {
      return (
        <div key={item?.hydraulic_circuit_id} data-testid={`HydraulicUplifts--${item?.hydraulic_circuit_name}`}>
          <Header editable={mode !== 'view'}>{item?.hydraulic_circuit_name}</Header>
          {mode !== 'view' ? (
            <>
              <StyledInput
                width={74}
                value={item?.uplift}
                type="number"
                placeholder="-"
                onChange={(e): void => handleInputChange(e.target.value, 'uplift', item?.hydraulic_circuit_name)}
                data-testid={`HydraulicUplifts--StyledInput-${item?.hydraulic_circuit_name}`}
              />
              <UnitSpan>{item?.uplift_unit}</UnitSpan>
            </>
          ) : (
            <DisplayText data-testid={`HydraulicUplifts--DisplayText-${item?.hydraulic_circuit_name}`}>
              {item?.uplift ? `${item?.uplift ? Math.round(item?.uplift * 100) / 100 : ''} ${item?.uplift_unit}` : '-'}
            </DisplayText>
          )}
          {errorMap.has(item?.hydraulic_circuit_name) ? (
            <ErrorWrapper>
              <img src={errorIcon} alt="error" />
              Value must be to 2 decimal places
            </ErrorWrapper>
          ) : null}
        </div>
      );
    });
  };

  useEffect(() => {
    const createHydraulicUplifts = (passedArray) => {
      const newArray = [];
      if (aircraftMap.get(id)?.hydraulic_circuits?.length) {
        aircraftMap.get(id).hydraulic_circuits.forEach((item) => {
          if (passedArray.find((uplift) => uplift?.hydraulic_circuit_id === item?.hydraulic_circuit_id)) {
            newArray.push(passedArray.find((uplift) => uplift?.hydraulic_circuit_id === item?.hydraulic_circuit_id));
          } else {
            newArray.push({
              hydraulic_circuit_name: item?.hydraulic_circuit_name,
              hydraulic_circuit_id: item?.hydraulic_circuit_id,
              initial_value: '',
              uplift: '',
              carried_forwards: '',
              uplift_unit: aircraftMap.get(id)?.oil_unit,
              fluid_type: 'hydraulic',
            });
          }
        });
      }
      return newArray;
    };
    if (flight) {
      if (flight?.fluid_uplifts_attributes?.some((uplift) => uplift?.fluid_type === 'hydraulic')) {
        const newLogs = flight?.fluid_uplifts_attributes.filter((uplift) => uplift?.fluid_type === 'hydraulic');
        const builtLogs = createHydraulicUplifts(newLogs);
        setHydraulicUplifts(builtLogs);
        setNumberOfParts(builtLogs?.length);
      } else if (flight?.fluid_uplifts?.some((uplift) => uplift?.fluid_type === 'hydraulic')) {
        const newLogs = flight?.fluid_uplifts.filter((uplift) => uplift?.fluid_type === 'hydraulic');
        const builtLogs = createHydraulicUplifts(newLogs);
        setHydraulicUplifts(builtLogs);
        setNumberOfParts(builtLogs?.length);
      } else {
        const builtLogs = createHydraulicUplifts([]);
        setHydraulicUplifts(builtLogs);
        setNumberOfParts(builtLogs?.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight]);

  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={mode !== 'view'}
        headerContent={
          <RowHeader data-testid="HydraulicUplifts--Accordion">
            <TitleWrapper>
              <img src={hydraulicIcon} alt="engine icon" />
              Hydraulic Uplifts
            </TitleWrapper>
            <PartsDiv>{`${numberOfParts} parts`}</PartsDiv>
          </RowHeader>
        }
      >
        <ContentWrapper>
          {displayHydraulicUplifts()}
          <UnitSelectionWrapper>
            <Header editable={mode !== 'view'}>Oil unit</Header>
            {mode === 'view' ? (
              <DisplayText>{hydraulicUplifts && hydraulicUplifts[0]?.uplift_unit}</DisplayText>
            ) : (
              <RadioWrapper>
                <RadioInputButton
                  text="ml"
                  active={
                    hydraulicUplifts?.length
                      ? hydraulicUplifts[0]?.uplift_unit === 'ml'
                      : aircraftMap.get(id)?.oil_unit === 'ml'
                  }
                  marginRight="8px"
                  onClick={(): void => handleHydraulicUnitChange('ml')}
                />
                <RadioInputButton
                  text="qt"
                  active={
                    hydraulicUplifts?.length
                      ? hydraulicUplifts[0]?.uplift_unit === 'qt'
                      : aircraftMap.get(id)?.oil_unit === 'qt'
                  }
                  marginRight="8px"
                  onClick={(): void => handleHydraulicUnitChange('qt')}
                />
                <RadioInputButton
                  text="usg"
                  active={
                    hydraulicUplifts?.length
                      ? hydraulicUplifts[0]?.uplift_unit === 'usg'
                      : aircraftMap.get(id)?.oil_unit === 'usg'
                  }
                  marginRight="8px"
                  onClick={(): void => handleHydraulicUnitChange('usg')}
                />
              </RadioWrapper>
            )}
          </UnitSelectionWrapper>
        </ContentWrapper>
      </Accordion>
    </AccordionWrapper>
  );
};

export default HydraulicUplifts;
