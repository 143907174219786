/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThreeDotMenu } from '@arcflight/tf-component-library';
import Portal from '../TFPortal/TFPortal';
import { DashboardState } from '../../models';
import { AircraftPermission } from '../../models/aircraft';
import { UserPermission } from '../../models/userSettings';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import AuthMenuItem from './AuthMenuItem';
import styles from './AuthDropdownMenu.module.less';

const AuthDropdownMenu = ({
  setDrawerVisible,
  handleDelete,
  viewText,
  viewCallback,
  editText,
  editCallback,
  customText,
  customCallback,
  custom2Text,
  custom2Callback,
  duplicateCallback,
  deleteButtonChild,
  aircraftId,
  hasDrawer = false,
  resource,
  options,
  forTable,
  noOffset = false,
}: AuthDropdownMenuProps): React.ReactElement => {
  const {
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);
  const { formatMessage } = useIntl();
  const [menuStyle, setMenuStyle] = useState({});
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  const menuWrapperRef = useRef<HTMLDivElement>();

  const currentAircraft = aircraftMap.get(aircraftId);

  const limitDeletionToAdminsEnabled = useGetOperatorSetting('limit_deletion_to_admins', aircraftId);

  const currentPositionIsAdmin =
    currentAircraft && limitDeletionToAdminsEnabled
      ? userSettings?.details?.people?.find((person) => person?.organisation?.id === currentAircraft?.operator_id)
        ?.position === 'Admin'
      : true;

  const handleMenuClick = (): void => {
    setShowMenu(true);
    const rect = menuRef.current.getBoundingClientRect();
    let offset = 62;
    if (menuWrapperRef.current) {
      const wrapper = menuWrapperRef.current.getBoundingClientRect();
      offset = wrapper.width * -1 + 20;
      if (noOffset) offset = 0;
      const { top, left } = rect;
      setMenuStyle({
        position: 'absolute',
        top: top + 24 + window.scrollY,
        left: left + offset + window.scrollX,
      });
    }
  };

  useEffect(() => {
    if (showMenu) {
      const rect = menuRef.current.getBoundingClientRect();
      let offset = 62;
      if (menuWrapperRef.current) {
        const wrapper = menuWrapperRef.current.getBoundingClientRect();
        offset = wrapper.width * -1 + 20;
        const { top, left } = rect;
        if (noOffset) offset = 0;
        setMenuStyle({
          position: 'absolute',
          top: top + 24 + window.scrollY,
          left: left + offset + window.scrollX,
        });
      }
    }
  }, [noOffset, showMenu]);

  if (currentAircraft?.locked || currentAircraft?.billing_status === 'Archived') return null;

  return (
    <div
      onClick={(): void => handleMenuClick()}
      onKeyDown={(): void => handleMenuClick()}
      onFocus={(): void => handleMenuClick()}
      className={forTable ? styles.menuButtonTable : styles.menuButton}
      role="button"
      tabIndex={0}
    >
      <div ref={menuRef} className={styles.iconWrapper}>
        <ThreeDotMenu menuItems={[]} />
      </div>
      {showMenu && (
        <Portal>
          <button
            type="button"
            onClick={(e): void => {
              e.stopPropagation();
              setShowMenu(false);
            }}
            className={styles.invisibleBackground}
          >
            <div ref={menuWrapperRef} className={styles.menuWrapper} style={menuStyle}>
              {options.read && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                    }
                    if (viewCallback) {
                      viewCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={viewText || <span className={styles.menuItemSpan}>{formatMessage({ id: 'text.view' })}</span>}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.READ : UserPermission.READ}
                />
              )}
              {options.update && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                    }
                    if (editCallback) {
                      editCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={editText || <span className={styles.menuItemSpan}>{formatMessage({ id: 'text.edit' })}</span>}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                />
              )}
              {options.duplicate && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                    }
                    if (duplicateCallback) {
                      duplicateCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={<span className={styles.menuItemSpan}>{formatMessage({ id: 'text.duplicate' })}</span>}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                />
              )}
              {options.extraItems &&
                options.extraItems.map((item) => {
                  return (
                    <AuthMenuItem
                      onMenuItemClick={(): void => {
                        item.onClick();
                        setShowMenu(false);
                      }}
                      action={<span className={styles.menuItemSpan}>{formatMessage({ id: item.text })}</span>}
                      aircraftId={aircraftId}
                      resource={resource}
                      permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                    />
                  );
                })}
              {options.custom && customText && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                    }
                    if (customCallback) {
                      customCallback();
                    }
                    setShowMenu(false);
                  }}
                  action={
                    <span className={styles.menuItemSpan}>{customText}</span> || (
                      <span className={styles.menuItemSpan}>{formatMessage({ id: 'text.edit' })}</span>
                    )
                  }
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                />
              )}
              {options.custom2 && custom2Text && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    if (hasDrawer) {
                      setDrawerVisible(true);
                    }
                    if (custom2Callback) {
                      custom2Callback();
                    }
                    setShowMenu(false);
                  }}
                  action={
                    <span className={styles.menuItemSpan}>{custom2Text}</span> || (
                      <span className={styles.menuItemSpan}>{formatMessage({ id: 'text.edit' })}</span>
                    )
                  }
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.UPDATE : UserPermission.UPDATE}
                />
              )}
              {options.delete && currentPositionIsAdmin && (
                <AuthMenuItem
                  onMenuItemClick={(): void => {
                    handleDelete();
                    setShowMenu(false);
                  }}
                  action={
                    deleteButtonChild || (
                      <span className={styles.menuItemSpan}>{formatMessage({ id: 'text.delete' })}</span>
                    )
                  }
                  optionalClass={styles.delete}
                  aircraftId={aircraftId}
                  resource={resource}
                  permissionLevel={aircraftId ? AircraftPermission.DELETE : UserPermission.DELETE}
                />
              )}
            </div>
          </button>
        </Portal>
      )}
    </div>
  );
};

interface MenuOptions {
  read: boolean;
  update: boolean;
  delete: boolean;
  duplicate?: boolean;
  custom?: boolean;
  custom2?: boolean;
  extraItems?: Array<extraItems>;
}

type extraItems = {
  text: string;
  onClick: () => void;
};

type AuthDropdownMenuProps = {
  record?: Record<string, unknown>;
  setDrawerVisible?: (arg0: boolean) => void;
  handleDelete?: () => void;
  viewText?: JSX.Element | string;
  viewCallback?: () => void;
  editText?: JSX.Element | string;
  editCallback?: () => void;
  deleteButtonChild?: JSX.Element | string;
  customText?: JSX.Element | string;
  customCallback?: () => void;
  custom2Text?: JSX.Element | string;
  custom2Callback?: () => void;
  duplicateCallback?: () => void;
  aircraftId?: string;
  resource?: string;
  menuStyle?: object;
  hasDrawer?: boolean;
  options: MenuOptions;
  forTable?: boolean;
  noOffset?: boolean;
};

const defaultProps = {
  record: undefined,
  setDrawerVisible: undefined,
  handleDelete: undefined,
  viewText: undefined,
  viewCallback: undefined,
  editText: undefined,
  editCallback: undefined,
  deleteButtonChild: undefined,
  customText: undefined,
  customCallback: undefined,
  custom2Text: undefined,
  custom2Callback: undefined,
  duplicateCallback: undefined,
  aircraftId: undefined,
  resource: undefined,
  menuStyle: undefined,
  hasDrawer: false,
  forTable: false,
  noOffset: false,
};

AuthDropdownMenu.defaultProps = defaultProps;

export default AuthDropdownMenu;
