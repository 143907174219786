import { Accordion } from '@arcflight/tf-component-library';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { checkNumberIsTwoDecimalPlaces, combineFluidUplifts } from '../../utils/utils';
import idgIcon from '../../assets/icon-card-idg.svg';
import errorIcon from '../../assets/login-error.svg';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import { Flight, FluidUplift } from '../../models/flights';
import { DashboardState } from '../../models';
import { DisplayText, Header, StyledInput } from './FlightDrawer';

interface LocalIDGUpliftsProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const AccordionWrapper = styled.div`
  margin-top: 16px;
`;

const RowHeader = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 1.33;
  font-size: 12px;
  text-transform: capitalize;
  img {
    margin-right: 8px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
  }
`;

const PartsDiv = styled.div`
  margin-right: 20px;
`;

const UnitSpan = styled.span`
  margin-left: 8px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
`;

const UnitSelectionWrapper = styled.div`
  grid-column: ${({ editable }): string => (editable ? 'span 3' : 'auto')};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }): string => (isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? '20px' : '0')};
  padding: 16px 20px;
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
`;

const ErrorWrapper = styled.div`
  font-size: '16px';
  color: '#ac1717';
  margin-top: 4px;
  img {
    margin-right: 4px;
  }
`;

const IDGUplifts: React.FC<LocalIDGUpliftsProps> = ({ flight, updateFlightData }) => {
  const {
    drawer: { mode },
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const [localIDGUplifts, setLocalIDGUplifts] = useState([]);
  const [errorMap, setErrorMap] = useState(new Map());

  const operatorSettings = operators?.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const IDGRename = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.name_override;
  const IDGCount = aircraftMap.get(id)?.standard_fields?.integrated_drive_generator?.count;

  const handleUpliftChange = (value: string, key: string): void => {
    if (checkNumberIsTwoDecimalPlaces(value)) {
      errorMap.delete(key);
      setErrorMap(errorMap);
    } else {
      errorMap.set(key, true);
      setErrorMap(errorMap);
    }
    const upliftIndex = localIDGUplifts.findIndex((uplift) => uplift?.fluid_destination_number === key);
    localIDGUplifts[upliftIndex] = { ...localIDGUplifts[upliftIndex], uplift: value };
    const existingFluidUplifts = flight?.fluid_uplifts_attributes || flight?.fluid_uplifts;
    const newFluidUplifts = combineFluidUplifts(localIDGUplifts, existingFluidUplifts);
    updateFlightData([{ value: newFluidUplifts, key: 'fluid_uplifts_attributes' }]);
  };

  const handleIDGUnitChange = (value: string): void => {
    let newIDGUplifts = [...localIDGUplifts];
    newIDGUplifts = newIDGUplifts.map((item) => {
      return { ...item, uplift_unit: value };
    });
    const existingFluidUplifts = flight?.fluid_uplifts_attributes || flight?.fluid_uplifts;
    const newFluidArray = combineFluidUplifts(newIDGUplifts, existingFluidUplifts);
    updateFlightData([{ value: newFluidArray, key: 'fluid_uplifts_attributes' }]);
  };

  const handleInitialValueChange = (value: string, key: string): void => {
    if (checkNumberIsTwoDecimalPlaces(value)) {
      errorMap.delete(`initial_${key}`);
      setErrorMap(new Map(errorMap));
    } else {
      errorMap.set(`initial_${key}`, true);
      setErrorMap(new Map(errorMap));
    }
    const upliftIndex = localIDGUplifts.findIndex((uplift) => uplift?.fluid_destination_number === key);
    localIDGUplifts[upliftIndex] = { ...localIDGUplifts[upliftIndex], initial_value: value };
    const existingFluidUplifts = flight?.fluid_uplifts_attributes || flight?.fluid_uplifts;
    const newFluidUplifts = combineFluidUplifts(localIDGUplifts, existingFluidUplifts);
    updateFlightData([{ value: newFluidUplifts, key: 'fluid_uplifts_attributes' }]);
  };

  const buildIDGUplifts = (): ReactElement[] => {
    return localIDGUplifts?.map((uplift) => {
      const title = `${uplift?.fluid_destination} ${uplift?.fluid_destination_number}`;
      return (
        <div key={uplift.fluid_destination_number}>
          <Header editable={mode !== 'view'} style={{ marginLeft: '22px' }}>
            {operatorSettings[`${uplift.fluid_destination}_${uplift.fluid_destination_number}`] ||
              title.replace(/_/g, ' ')}
          </Header>
            <ContentWrapper>
            <div>
              <Header editable={mode !== 'view'}>Initial</Header>
              {mode !== 'view' ? (
                <>
                  <StyledInput
                  width={74}
                  value={uplift?.initial_value || '-'}
                  type="number"
                  onChange={(e): void => handleInitialValueChange(e.target.value, uplift?.fluid_destination_number)}
                  />
                  <UnitSpan>{uplift?.uplift_unit}</UnitSpan>
                </>
              ) : (
                  <DisplayText>
                  {uplift?.initial_value
                    ? `${
                        uplift?.initial_value 
                        ? Math.round(uplift?.initial_value * 100) / 100 
                        : ''
                      } ${uplift?.uplift_unit}`
                    : '-'}
                  </DisplayText>
              )}
            </div>
            <div>
              <Header editable={mode !== 'view'}>Uplift</Header>
              {mode !== 'view' ? (
                <>
                  <StyledInput
                  width={74}
                  value={uplift?.uplift || '-'}
                  type="number"
                  onChange={(e): void => handleUpliftChange(e.target.value, uplift?.fluid_destination_number)}
                  />
                  <UnitSpan>{uplift?.uplift_unit}</UnitSpan>
                </>
              ) : (
                <>
                  <DisplayText>
                  {uplift?.uplift
                    ? `${uplift?.uplift ? Math.round(uplift?.uplift * 100) / 100 : ''} ${uplift?.uplift_unit}`
                    : '-'}
                  </DisplayText>
                </>
              )}
            </div>
            </ContentWrapper>
          {errorMap.has(uplift?.fluid_destination_number) || 
            errorMap.has(`initial_${uplift?.fluid_destination_number}`) ? (
            <ErrorWrapper>
              <img src={errorIcon} alt="error" />
              Value must be to 2 decimal places
            </ErrorWrapper>
          ) : null}
        </div>
      );
    });
  };

  useEffect(() => {
    const buildUplift = (passedArray): FluidUplift[] => {
      const newArray = [];
      for (let i = 1; i <= IDGCount; i += 1) {
        newArray.push({
          fluid_destination: 'idg',
          fluid_destination_number: i,
          fluid_type: 'oil',
          id: '',
          uplift: '',
          initial_value: '',
          uplift_unit: aircraftMap.get(id)?.oil_unit,
        });
      }
      let returnArray = passedArray;
      if (passedArray?.length !== newArray?.length) {
        returnArray = newArray.map((item) => {
          if (passedArray?.fluid_destination_number === item?.fluid_destination_number) {
            return passedArray.filter(
              (passedItem) => passedItem?.fluid_destination_number === item?.fluid_destination_number,
            );
          }
          return item;
        });
      }
      return returnArray;
    };
    if (flight) {
      if (flight?.fluid_uplifts_attributes?.filter((uplift) => uplift?.fluid_destination === 'idg')?.length) {
        const newUplifts = flight?.fluid_uplifts_attributes?.filter((uplift) => uplift?.fluid_destination === 'idg');
        setLocalIDGUplifts(buildUplift(newUplifts));
      } else if (flight?.fluid_uplifts?.filter((uplift) => uplift?.fluid_destination === 'idg')?.length) {
        const newUplifts = flight?.fluid_uplifts?.filter((uplift) => uplift?.fluid_destination === 'idg');
        setLocalIDGUplifts(buildUplift(newUplifts));
      } else if (mode === 'view') {
        setLocalIDGUplifts([]);
      } else {
        setLocalIDGUplifts(buildUplift([]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IDGCount, flight]);
  if (mode === 'view' && localIDGUplifts?.length === 0) {
    return null;
  }
  return (
    <AccordionWrapper>
      <Accordion
        dash
        add
        isExpanded={mode !== 'view'}
        headerContent={
          <RowHeader>
            <TitleWrapper>
              <img src={idgIcon} alt="engine icon" />
              {IDGRename ? `${IDGRename} Uplifts` : 'IDG Uplifts'}
            </TitleWrapper>
            <PartsDiv>{`${localIDGUplifts?.length} parts`}</PartsDiv>
          </RowHeader>
        }
      >
      {buildIDGUplifts()}
      <ContentWrapper>
      <UnitSelectionWrapper editable={mode !== 'view'}>
        <Header editable={mode !== 'view'}>{IDGRename ? `${IDGRename} unit` : 'IDG unit'}</Header>
        {mode === 'view' ? (
          <DisplayText>{localIDGUplifts && localIDGUplifts[0]?.uplift_unit}</DisplayText>
        ) : (
          <RadioWrapper>
            <RadioInputButton
              text="ml"
              active={
                localIDGUplifts?.length
                  ? localIDGUplifts[0]?.uplift_unit === 'ml'
                  : aircraftMap.get(id)?.oil_unit === 'ml'
              }
              marginRight="8px"
              onClick={(): void => handleIDGUnitChange('ml')}
            />
            <RadioInputButton
              text="qt"
              active={
                localIDGUplifts?.length
                  ? localIDGUplifts[0]?.uplift_unit === 'qt'
                  : aircraftMap.get(id)?.oil_unit === 'qt'
              }
              marginRight="8px"
              onClick={(): void => handleIDGUnitChange('qt')}
            />
            <RadioInputButton
              text="usg"
              active={
                localIDGUplifts?.length
                  ? localIDGUplifts[0]?.uplift_unit === 'usg'
                  : aircraftMap.get(id)?.oil_unit === 'usg'
              }
              marginRight="8px"
              onClick={(): void => handleIDGUnitChange('usg')}
            />
          </RadioWrapper>
        )}
      </UnitSelectionWrapper>
      </ContentWrapper>
      </Accordion>
    </AccordionWrapper>
  );
};

export default IDGUplifts;
