import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { SectionHeader } from '../../CommonStyledComponents/CommonStyledComponents';
import TFInput from '../../TFInput/TFInput';
import TFDropdown from '../../TFDropdown/TFDropdown';

interface EditDefectAdditionalInformationProps {
  partialDefectData: {
    troubleshootingSteps: string;
    reasonToDefer: string;
  };
  updateDefectData: (changes: { key: string; value: string }[]) => void;
  requiredSettings: {
    troubleShootingStepsRename?: string;
    troubleShootingStepsRequired?: boolean;
    reasonToDeferRequired?: boolean;
    reasonToDeferAsDropdown?: boolean;
    reasonToDeferDropdownOptions?: string[];
  };
}

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px 0;
`;

const EditDefectAdditionalInformation: React.FC<EditDefectAdditionalInformationProps> = ({
  partialDefectData,
  updateDefectData,
  requiredSettings,
}) => {
  const { formatMessage } = useIntl();

  const [reasonToDeferOptions, setReasonToDeferOptions] = useState([]);

  const handleTroubleshootingStepsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateDefectData([{ value: e.target.value, key: 'troubleshooting_steps' }]);
  };

  const handleReasonToDeferChangeOptions = (option: { title: string; value: string }): void => {
    updateDefectData([{ value: option.value, key: 'reason_to_defer' }]);
  };

  const handleReasonToDeferChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateDefectData([{ value: e.target.value, key: 'reason_to_defer' }]);
  };

  useEffect(() => {
    if (requiredSettings.reasonToDeferDropdownOptions) {
      const newOptions = requiredSettings.reasonToDeferDropdownOptions.map((option) => {
        return { title: option, value: option };
      });
      setReasonToDeferOptions(newOptions);
    }
  }, [requiredSettings.reasonToDeferDropdownOptions]);

  return (
    <div>
      <SectionHeader data-testid="EditDefectAdditionalInformation-Header">
        {formatMessage({ id: 'text.pleaseEnterAdditional' })}
      </SectionHeader>
      <GridWrapper>
        <TFInput
          id="EditDefectAdditionalInformation-TroubleshootingSteps"
          value={partialDefectData.troubleshootingSteps}
          onChange={handleTroubleshootingStepsChange}
          label={formatMessage(
            { id: 'text.troubleshootingSteps' },
            { field: requiredSettings.troubleShootingStepsRename },
          )}
        />
        {requiredSettings.reasonToDeferAsDropdown ? (
          <TFDropdown
            options={reasonToDeferOptions}
            onSelect={handleReasonToDeferChangeOptions}
            id="EditDefectAdditionalInformation-ReasonToDeferOptions"
            label={formatMessage({ id: 'text.reasonToDefer' })}
            initialValue={{ value: partialDefectData.reasonToDefer, title: partialDefectData.reasonToDefer }}
          />
        ) : (
          <TFInput
            id="EditDefectAdditionalInformation-ReasonToDefer"
            value={partialDefectData.reasonToDefer}
            onChange={handleReasonToDeferChange}
            label={formatMessage({ id: 'text.reasonToDefer' })}
          />
        )}
      </GridWrapper>
    </div>
  );
};

export default EditDefectAdditionalInformation;
