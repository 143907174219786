import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { message } from 'antd';
import { Badge } from '@arcflight/tf-component-library';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import servers from '../../utils/servers';
import { DashboardState } from '../../models';
import { Flight } from '../../models/flights';
import CustomFieldSection from '../CustomFieldSection/CustomFieldSection';
import errorAmberIcon from '../../assets/login-error-amber.svg';
import errorIcon from '../../assets/login-error.svg';
import fratRiskColour from '../../utils/fratRiskLevelColourCalculator';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import getAppSetting from '../../utils/getAppSetting';
import { DisplayText, Header, SectionHeader } from './FlightDrawer';
import HoursMinsInput from './HoursMinsInput';

interface PreFlightCustomDataSectionProps {
  flight: Flight;
  updateFlightData: (changes: { value: any; key: string }[]) => void;
}

const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, edit }): string => {
    if (isMobile) return '1fr 1fr';
    if (edit) return '1fr 1fr 1fr 1fr';
    return '1fr 1fr 1fr 1fr 1fr';
  }};
  grid-template-rows: auto;
  row-gap: 20px;
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '0')};
`;

const StyledInput = styled.input`
  width: 110px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.2);
  background: #fff;
  padding-left: 8px;
`;

const SubHeading = styled.div`
  color: #242d41;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '0')};
`;

const BaggageWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 4px;
  }
`;

const SignatureSection = styled.div`
  color: rgba(36, 45, 65, 0.7);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 20px;
`;

const StyledImg = styled.img`
  width: 240px;
  margin-left: 12px;
`;

const UnitWrapper = styled.div`
  display: flex;
`;

const StyledUnit = styled.span`
  margin: ${({ edit }): string => (edit ? '7px 4px 0' : '0 4px')};
`;

const JustificationDiv = styled.div`
  grid-column: ${({ noSpan }): string => (noSpan ? 'span 1' : 'span 2')};
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 8px 0 0;
  grid-column: span 2;
  img {
    margin-right: 8px;
  }
`;

const ErrorText = styled.span`
  line-height: 1.43;
  color: ${({ red }): string => (red ? '#ac1717' : '#f2a650')};
`;

const MaxWeightWrapper = styled.div`
  grid-column: ${({ span }): string => (span ? 'span 2' : 'span 1')};
`;

const FRATSectionWrapper = styled.div`
  grid-column: span 2;
`;

const FRATWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 4px;
  }
  flex-shrink: 0;
`;

const Capitalise = styled.span`
  text-transform: capitalize;
`;

const PreFlightCustomDataSection: React.FC<PreFlightCustomDataSectionProps> = ({ flight, updateFlightData }) => {
  const isMobile = window.innerWidth < 451;
  const { formatMessage } = useIntl();

  const [GPU, setGPU] = useState(null);
  const [malePassengers, setMalePassengers] = useState(null);
  const [femalePassengers, setFemalePassengers] = useState(null);
  const [childrenPassengers, setChildrenPassengers] = useState(null);
  const [infantPassengers, setInfantPassengers] = useState(null);
  const [baggageWeight, setBaggageWeight] = useState(null);
  const [plannedFuelBurn, setPlannedFuelBurn] = useState(null);
  const [maxWeight, setMaxWeight] = useState(null);

  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const journeyLog = aircraftMap.get(id)?.standard_fields?.journey_log;
  const showFuelBurn = aircraftMap.get(id)?.standard_fields?.show_fuel_burn?.enabled;
  const aircraftMaxWeight = aircraftMap.get(id)?.max_weight;
  const aircraftWeightRename = useGetOperatorSetting('aircraft_weight_name', id);

  const displayMaxWeight = useGetOperatorSetting('max_weight_for_flight_enabled', id);
  const displayFRAT = getAppSetting('frat_enabled', id);

  const handleTimeChange = (time: string): void => {
    setGPU(time);
  };

  const updateGPU = (): void => {
    const stringArray = GPU?.split('.');
    const hours = parseInt(stringArray[0], 10) * 3600;
    const minutes = parseInt(stringArray[1], 10) || 0;
    const adjustedMinutes = minutes * 60;
    const totalSeconds = hours + adjustedMinutes;
    updateFlightData([{ value: totalSeconds, key: 'gpu_seconds' }]);
  };

  const handleInputChange = (value: string, type: string): void => {
    let newValue = value;
    if (value && Number.isNaN(parseInt(value, 10))) {
      message.error('Input must be numerical');
      newValue = '';
    }
    if (type === 'male') {
      setMalePassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_male' }]);
    }
    if (type === 'female') {
      setFemalePassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_female' }]);
    }
    if (type === 'children') {
      setChildrenPassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_children' }]);
    }
    if (type === 'infant') {
      setInfantPassengers(newValue);
      updateFlightData([{ value: newValue, key: 'passengers_infants' }]);
    }
    if (type === 'bag') {
      setBaggageWeight(newValue);
      updateFlightData([{ value: newValue, key: 'baggage_weight' }]);
    }
    if (type === 'preflight_weight') {
      setMaxWeight(newValue);
      updateFlightData([{ value: newValue, key: 'preflight_weight' }]);
    }
  };

  const handlFuelBurnChange = (value: string): void => {
    setPlannedFuelBurn(parseInt(value, 10));
    updateFlightData([{ value: parseInt(value, 10), key: 'planned_fuel_burn' }]);
  };

  const displayFRATResult = (fratResult): ReactElement => {
    if (fratResult?.risk_level) {
      return (
        <FRATWrapper>
          <Badge color={fratRiskColour(fratResult.risk_level)} />
          <span>
            {`${fratResult.percentage}% - `}
            <Capitalise>{fratResult.risk_level.toLowerCase()}</Capitalise> risk
          </span>
        </FRATWrapper>
      );
    }
    return <span>-</span>;
  };

  useEffect(() => {
    if (flight) {
      if (flight?.gpu_seconds) {
        const h = Math.floor(flight?.gpu_seconds / 3600);
        const m = Math.floor((flight?.gpu_seconds % 3600) / 60);
        const formattedMins = m < 10 ? `0${m}` : m;
        const formattedHours = `${h}:${formattedMins}`;
        setGPU(formattedHours);
      } else {
        setGPU('');
      }
      if (flight?.passengers_male) {
        setMalePassengers(flight.passengers_male);
      } else {
        setMalePassengers('');
      }
      if (flight?.passengers_female) {
        setFemalePassengers(flight.passengers_female);
      } else {
        setFemalePassengers('');
      }
      if (flight?.passengers_children) {
        setChildrenPassengers(flight.passengers_children);
      } else {
        setChildrenPassengers('');
      }
      if (flight?.passengers_infants) {
        setInfantPassengers(flight.passengers_infants);
      } else {
        setInfantPassengers('');
      }
      if (flight?.baggage_weight) {
        setBaggageWeight(flight.baggage_weight);
      } else {
        setBaggageWeight('');
      }
      if (flight?.planned_fuel_burn) {
        setPlannedFuelBurn(flight.planned_fuel_burn);
      } else {
        setPlannedFuelBurn('');
      }
      if (flight?.preflight_weight) {
        setMaxWeight(flight.preflight_weight);
      } else {
        setMaxWeight('');
      }
    }
  }, [flight]);

  let dataItems = [];
  if (flight?.preflight_custom_data) {
    dataItems = Object.entries(flight.preflight_custom_data);
  }
  const dataElements = dataItems.map((item, index) => {
    // only for images as new custom data section deals with rest
    const header = item[0].replace(/_/gi, ' ');
    let displayText = <DisplayText>{item[1]}</DisplayText>;
    if (/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(item[1])) {
      displayText = <img src={`${servers.api}/attachments/thumbnail/${item[1]}.png`} alt="attachment" />;
      return (
        <div key={item[1]} data-testid={`PreFlightCustomDataSection-CustomElement${index}`}>
          <Header>{header}</Header>
          {displayText}
        </div>
      );
    }
    return null;
  });

  return (
    <>
      <SectionHeader data-testid="PreFlightCustomDataSection-Header">
        {`${journeyLog?.name_override || 'Preflight'}`}
      </SectionHeader>
      {journeyLog?.enabled === false ? null : (
        <SubHeading data-testid="PreFlightCustomDataSection-PassengersSubheader">
          {formatMessage({ id: 'text.passengers' })}
        </SubHeading>
      )}
      <SectionWrapper edit={mode !== 'view'} isMobile={isMobile}>
        {journeyLog?.enabled === false ? null : (
          <>
            <div>
              <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-MalePassengers">
                {formatMessage({ id: 'text.malePassengers' })}
              </Header>
              {mode === 'view' ? (
                <DisplayText data-testid="PreFlightCustomDataSection-MalePassengerCount">
                  {malePassengers || '-'}
                </DisplayText>
              ) : (
                <StyledInput
                  type="number"
                  placeholder="0"
                  value={malePassengers}
                  onChange={(e): void => handleInputChange(e.target.value, 'male')}
                />
              )}
            </div>
            <div>
              <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-FemalePassengers">
                {formatMessage({ id: 'text.femalePassengers' })}
              </Header>
              {mode === 'view' ? (
                <DisplayText data-testid="PreFlightCustomDataSection-FemalePassengerCount">
                  {femalePassengers || '-'}
                </DisplayText>
              ) : (
                <StyledInput
                  type="number"
                  placeholder="0"
                  value={femalePassengers}
                  onChange={(e): void => handleInputChange(e.target.value, 'female')}
                />
              )}
            </div>
            <div>
              <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-ChildrenPassengers">
                {formatMessage({ id: 'text.childrenPassengers' })}
              </Header>
              {mode === 'view' ? (
                <DisplayText data-testid="PreFlightCustomDataSection-ChildrenPassengerCount">
                  {childrenPassengers || '-'}
                </DisplayText>
              ) : (
                <StyledInput
                  type="number"
                  placeholder="0"
                  value={childrenPassengers}
                  onChange={(e): void => handleInputChange(e.target.value, 'children')}
                />
              )}
            </div>
            <div>
              <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-InfantPassengers">
                {formatMessage({ id: 'text.infantPassengers' })}
              </Header>
              {mode === 'view' ? (
                <DisplayText data-testid="PreFlightCustomDataSection-InfantPassengerCount">
                  {infantPassengers || '-'}
                </DisplayText>
              ) : (
                <StyledInput
                  type="number"
                  placeholder="0"
                  value={infantPassengers}
                  onChange={(e): void => handleInputChange(e.target.value, 'infant')}
                />
              )}
            </div>
            <div>
              <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-BaggageWeight">
                {formatMessage({ id: 'text.baggageWeight' })}
              </Header>
              {mode === 'view' ? (
                <DisplayText data-testid="PreFlightCustomDataSection-BaggageWeightNumber">
                  {baggageWeight || '-'}
                </DisplayText>
              ) : (
                <BaggageWrapper>
                  <StyledInput
                    type="number"
                    placeholder="0"
                    value={baggageWeight}
                    onChange={(e): void => handleInputChange(e.target.value, 'bag')}
                  />
                  <span> kg</span>
                </BaggageWrapper>
              )}
            </div>
            <div>
              <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-GPUUsage">
                {formatMessage({ id: 'text.GPUUsage' })}
              </Header>
              {mode === 'view' ? (
                <DisplayText data-testid="PreFlightCustomDataSection-GPUFigure">
                  <DisplayText>{GPU || '-'}</DisplayText>
                </DisplayText>
              ) : (
                <HoursMinsInput value={GPU} onChange={handleTimeChange} onBlur={updateGPU} />
              )}
            </div>
          </>
        )}
        {displayMaxWeight ? (
          <MaxWeightWrapper span={maxWeight && (maxWeight < aircraftMaxWeight / 100 || maxWeight > aircraftMaxWeight)}>
            <Header editable={mode !== 'view'}>{aircraftWeightRename || formatMessage({ id: 'text.weight' })}</Header>
            {mode === 'view' ? (
              <DisplayText>
                <DisplayText>{maxWeight ? `${maxWeight} kg` : '-'}</DisplayText>
              </DisplayText>
            ) : (
              <UnitWrapper>
                <StyledInput
                  type="number"
                  placeholder="-"
                  value={maxWeight}
                  onChange={(e): void => handleInputChange(e.target.value, 'preflight_weight')}
                />
                <StyledUnit edit={mode !== 'view'}>kg</StyledUnit>
              </UnitWrapper>
            )}
            {maxWeight && maxWeight > aircraftMaxWeight ? (
              <ErrorWrapper>
                <img src={errorIcon} alt="error icon" />
                <ErrorText red>{formatMessage({ id: 'text.maxWeightError' })}</ErrorText>
              </ErrorWrapper>
            ) : null}
            {maxWeight && maxWeight < aircraftMaxWeight / 100 ? (
              <ErrorWrapper>
                <img src={errorAmberIcon} alt="error icon" />
                <ErrorText>{formatMessage({ id: 'text.maxWeightWarning' })}</ErrorText>
              </ErrorWrapper>
            ) : null}
          </MaxWeightWrapper>
        ) : null}
        {dataElements}
        {showFuelBurn ? (
          <JustificationDiv noSpan={mode === 'view'}>
            <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-PlannedFuelBurn">
              {formatMessage({ id: 'text.plannedFuelBurn' })} {`(${formatMessage({ id: 'text.incTaxi' })})`}
            </Header>
            {mode === 'view' ? (
              <DisplayText data-testid="PreFlightCustomDataSection-PlannedFuelBurn">
                <DisplayText>
                  {plannedFuelBurn}
                  <StyledUnit edit={mode !== 'view'}>{aircraftMap.get(id)?.fuel_unit}</StyledUnit>
                </DisplayText>
              </DisplayText>
            ) : (
              <UnitWrapper>
                <StyledInput
                  type="number"
                  placeholder="-"
                  value={plannedFuelBurn}
                  onChange={(e): void => handlFuelBurnChange(e.target.value)}
                />
                <StyledUnit edit={mode !== 'view'}>{aircraftMap.get(id)?.fuel_unit}</StyledUnit>
              </UnitWrapper>
            )}
          </JustificationDiv>
        ) : null}
      </SectionWrapper>
      <CustomFieldSection
        customData={flight?.preflight_custom_data}
        saveKey="preflight_custom_data"
        updateData={updateFlightData}
        customFieldKey="preflight"
      />
      {displayFRAT && mode === 'view' ? (
        <>
          <SubHeading data-testid="PreFlightCustomDataSection-FRATSubheader" marginTop="16px">
            {formatMessage({ id: 'text.flightRiskAssessment' })}
          </SubHeading>
          <SectionWrapper>
            <FRATSectionWrapper>
              <Header editable={mode !== 'view'} data-testid="PreFlightCustomDataSection-FRAT">
                {formatMessage({ id: 'text.fratResult' })}
              </Header>
              <DisplayText data-testid="PreFlightCustomDataSection-FRATResult">
                {displayFRATResult(flight?.flight_risk_assessment_result)}
              </DisplayText>
            </FRATSectionWrapper>
          </SectionWrapper>
        </>
      ) : null}
      {flight?.preflight_signature_data ? (
        <SignatureSection data-testid="TripDetailsSection-Signature">
          {formatMessage({ id: 'form.labels.signedBy' })}
          :
          <StyledImg alt="Signature" src={`data:image/png;base64, ${flight?.preflight_signature_data}`} />
        </SignatureSection>
      ) : null}
    </>
  );
};

export default PreFlightCustomDataSection;
