import React from 'react';
import styled from 'styled-components';
import backArrow from '../assets/icon-back.svg';

interface BackButtonProps {
  onClick: () => void;
}

const StyledBackButton = styled.div`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:focus {
    border: none;
  }
  img {
    height: 16px;
    width: 16px;
    margin: 0 8px 0 0;
  }
`;

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <StyledBackButton onClick={onClick} data-testid="BackButton">
      <img src={backArrow} alt="back arrow" />
    </StyledBackButton>
  );
};

export default BackButton;
