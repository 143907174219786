import React from 'react';
import styled from 'styled-components';

interface CardProps {
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  marginBottom?: number;
  maxHeight?: number;
  identifier?: string;
  padding?: string;
  display?: string;
  id?: string;
  width?: string;
  title?: string;
  extra?: JSX.Element;
  height?: string;
  border?: string;
  boxShadow?: string;
}

const StyledCard = styled.div`
  border-radius: 4px;
  box-shadow: ${({ boxShadow }): string => (boxShadow ? `${boxShadow}` : '0 0 10px 0 rgba(219, 227, 237, 0.41)')};
  border: ${({ border }): string => (border ? `${border}` : 'solid 2px #fff')};
  background-color: #fcfcfc;
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
  height: ${({ height }): string => (height ? `${height}` : 'auto')};
  padding: ${({ padding }): string => (padding ? `${padding}` : '20px')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}px` : '0')};
  margin-left: ${({ marginLeft }): string => (marginLeft ? `${marginLeft}px` : '0')};
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}px` : '0')};
  margin-bottom: ${({ marginBottom }): string => (marginBottom ? `${marginBottom}px` : '0')};
  max-height: ${({ maxHeight }): string => (maxHeight ? `${maxHeight}px` : 'unset')};
  overflow: ${({ maxHeight }): string => (maxHeight ? 'auto' : 'unset')};
  display: ${({ display }): string => (display ? `${display}` : 'block')};
`;

const Title = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8e8e8;
`;

const TFCard: React.FC<CardProps> = ({
  children,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  maxHeight,
  identifier,
  border,
  boxShadow,
  padding,
  display,
  id,
  width,
  title,
  extra,
  height,
}) => {
  return (
    <StyledCard
      marginRight={marginRight}
      marginLeft={marginLeft}
      marginTop={marginTop}
      marginBottom={marginBottom}
      maxHeight={maxHeight}
      padding={padding}
      display={display}
      id={id}
      width={width}
      height={height}
      data-testid={`StyledCard--${identifier}`}
      border={border}
      boxShadow={boxShadow}
      data-test={identifier}
    >
      {title ? (
        <>
          <Title>
            {title}
            <div>{extra}</div>
          </Title>
          <Line />
        </>
      ) : null}
      {extra && !title ? <Title>{extra}</Title> : null}
      {children}
    </StyledCard>
  );
};

export default TFCard;
