import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import useGetOperatorSetting from '../../../utils/useGetOperatorSetting';
import defectIcon from '../../../assets/icon-card-defects.svg';
import DeferralButton from './DeferralButton';

interface DeferralOptionsHeaderProps {
  defectData: any;
  updateDefectData: (changes: { key: string; value: any }[]) => void;
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
`;

const DeferralOptionsHeader: React.FC<DeferralOptionsHeaderProps> = ({ defectData, updateDefectData }) => {
  const { id } = useParams<{ id: string }>();

  const defectRename = useGetOperatorSetting('defect_name', id);

  return (
    <HeaderWrapper data-testid="DeferralOptionsHeader--Wrapper">
      <Header data-testid="DeferralOptionsHeader--Header">
        <img src={defectIcon} alt="defect icon" />
        <span>{`${defectRename} Deferral`}</span>
      </Header>
      <DeferralButton isDeferred={!!defectData?.deferred} updateDefectData={updateDefectData} />
    </HeaderWrapper>
  );
};

export default DeferralOptionsHeader;
