import { Col, DatePicker, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Button } from '@arcflight/tf-component-library';
import { DashboardState } from '../../../models';
import ToolTip from '../../NewTooltip/NewTooltip';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import { Maintenance } from '../../../models/maintenance';
import { Header, ItemWrapper, Label, RadioWrapper } from '../../CommonStyledComponents/CommonStyledComponents';
import TFTimePicker from '../../TFTimePicker/TFTimePicker';
import TFNumberInput from '../../TFNumberInput/TFNumberInput';
import timeUtils from '../../../utils/timeUtils';
import globalStyles from '../../../utils/globalStyles.module.less';
import styles from './MXModal.module.less';

interface BaseModalFieldsProps {
  item: Maintenance;
  updateItemData: (changes: { value: any; key: string }[]) => void;
  configOptions: { utcOffsetSeconds: number };
}

const TimeDueWrapper = styled.div`
  margin-bottom: 20px;
`;

const UnlockedTimeDueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const TimeWrapper = styled.div`
  margin-right: 16px;
`;

const CompletionDateFeatureFlag = true;
const IndividualThresholdFlag = true;

const BaseModalFields: React.FC<BaseModalFieldsProps> = ({ item, updateItemData, configOptions }) => {
  const {
    userSettings: { dateFormat },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const isUTC = configOptions.utcOffsetSeconds === 0;

  const [unitOfTime, setUnitOfTime] = useState('days');
  const [completionType, setCompletionType] = useState('date_resolved');
  const [unlockTimeDue, setUnlockTimeDue] = useState(!isUTC);
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const aircraft = aircraftMap.get(id);

  const hasAPU = aircraft ? aircraft?.apu_installed : false;

  let initialSeconds;

  if (item?.datetime_due) {
    initialSeconds = timeUtils.getSecondsFromDateTime(item?.datetime_due);
  } else if (!isUTC) {
    if (configOptions.utcOffsetSeconds > 0) {
      initialSeconds = configOptions.utcOffsetSeconds;
    } else {
      const twentyFourHoursAsSeconds = 24 * 3600;

      initialSeconds = twentyFourHoursAsSeconds + configOptions.utcOffsetSeconds;
    }
  }

  const [timeDue, setTimeDue] = useState(initialSeconds);

  const handleUnitOfTimeClick = (input): void => {
    setUnitOfTime(input);
    updateItemData([{ value: input, key: 'unit_of_time' }]);
  };

  const handleCompletionTypeClick = (input): void => {
    setCompletionType(input);
    updateItemData([{ value: input, key: 'time_based_repeat_calculation_method' }]);
  };

  const addTimetoDate = (seconds: number, currentDate: Moment): string => {
    const dateWithSeconds = moment.utc(currentDate).add(seconds, 'seconds');
    const formattedDate = moment.utc(dateWithSeconds).format('YYYY-MM-DD HH:mm Z');
    return formattedDate;
  };

  const handleDateChange = (value): void => {
    if (value) {
      if (unlockTimeDue) {
        const newDate = moment.utc(value, dateFormat);
        const formattedDate = addTimetoDate(timeDue, newDate);
        updateItemData([{ value: formattedDate, key: 'datetime_due' }]);
      } else {
        const formattedDate = moment.utc(value, dateFormat).format('YYYY-MM-DD');
        updateItemData([{ value: formattedDate, key: 'date_due' }]);
      }
    } else if (unlockTimeDue) {
      updateItemData([{ value: '', key: 'datetime_due' }]);
    } else {
      updateItemData([{ value: '', key: 'date_due' }]);
    }
  };

  const handleExpiryTimeChange = (seconds: number): void => {
    if (seconds) {
      setTimeDue(seconds);
      let newDate;
      if (item?.datetime_due) newDate = moment.utc(item?.datetime_due);
      const currentSeconds = timeUtils.getSecondsFromDateTime(item?.datetime_due);
      const differenceOfSeconds = seconds - currentSeconds;
      const formattedDate = addTimetoDate(differenceOfSeconds, newDate);
      updateItemData([{ value: formattedDate, key: 'datetime_due' }]);
    }
  };

  const handleTimeDueUnlock = (): void => {
    if (!unlockTimeDue) {
      const newItem = item;
      newItem.datetime_due = newItem.date_due;
      newItem.date_due = null;
      const formattedDate = moment.utc(newItem.datetime_due).format('YYYY-MM-DD');
      updateItemData([{ value: formattedDate, key: 'datetime_due' }]);
    } else {
      setTimeDue(null);
      const newItem = item;
      const newDate = moment.utc(newItem.datetime_due).format('YYYY-MM-DD');
      newItem.datetime_due = null;
      updateItemData([{ value: newDate, key: 'date_due' }]);
    }
    setUnlockTimeDue(!unlockTimeDue);
  };

  const handleIndividualThresholdChange = (value, key): void => {
    if (key === 'days') {
      updateItemData([{ value, key: 'calendar_days_amber_limit' }]);
    } else if (key === 'seconds') {
      updateItemData([{ value, key: 'calendar_seconds_amber_limit' }]);
    }
  };

  useEffect(() => {
    if (item?.unit_of_time) {
      setUnitOfTime(item.unit_of_time);
    }
    if (item?.time_based_repeat_calculation_method) setCompletionType(item.time_based_repeat_calculation_method);
    if (item?.datetime_due) setUnlockTimeDue(true);
  }, [item]);

  return (
    <>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-testid="BaseModalFields-FlightHours">
            <span>{formatMessage({ id: 'form.title.addFlightHours' })}</span>
          </div>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.threshold' })}</Label>
            <TFNumberInput
              value={item?.flight_seconds_threshold ? Math.round(item.flight_seconds_threshold / 3600) : null}
              onChange={(value): void => updateItemData([{ value: value * 3600, key: 'flight_seconds_threshold' }])}
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.interval' })}</Label>
            <TFNumberInput
              value={item?.flight_seconds_repeat ? Math.round(item.flight_seconds_repeat / 3600) : null}
              onChange={(value): void => updateItemData([{ value: value * 3600, key: 'flight_seconds_repeat' }])}
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
            <TFNumberInput
              value={item?.flight_seconds_tolerance ? Math.round(item.flight_seconds_tolerance / 3600) : null}
              onChange={(value): void => updateItemData([{ value: value * 3600, key: 'flight_seconds_tolerance' }])}
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
            <TFTimePicker
              initial={item?.flight_seconds_due}
              setValue={(value): void => updateItemData([{ value, key: 'flight_seconds_due' }])}
              removeHoursRestriction
              id="flight_seconds_due"
            />
          </ItemWrapper>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-testid="BaseModalFields-Cycles">
            <span>{formatMessage({ id: 'form.title.addCycles' })}</span>
          </div>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.threshold' })}</Label>
            <TFNumberInput
              value={item?.cycles_threshold}
              onChange={(value): void => updateItemData([{ value, key: 'cycles_threshold' }])}
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.interval' })}</Label>
            <TFNumberInput
              value={item?.cycles_repeat}
              onChange={(value): void => updateItemData([{ value, key: 'cycles_repeat' }])}
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
            <TFNumberInput
              value={item?.cycles_tolerance}
              onChange={(value): void => updateItemData([{ value, key: 'cycles_tolerance' }])}
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
            <TFNumberInput
              value={item?.cycles_due}
              onChange={(value): void => updateItemData([{ value, key: 'cycles_due' }])}
            />
          </ItemWrapper>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-testid="BaseModalFields-Months">
            <span>{formatMessage({ id: 'form.title.addMonths' })}</span>
          </div>
        </Col>
        <Col md={24}>
          <ItemWrapper marginBottom="16px">
            <div className={styles.dateLabel}>{formatMessage({ id: 'text.limitType' })}</div>
            <RadioWrapper>
              <RadioInputButton
                text="Days"
                active={unitOfTime === 'days'}
                onClick={(): void => handleUnitOfTimeClick('days')}
                marginRight="8px"
              />
              <RadioInputButton
                text="Months"
                active={unitOfTime === 'months'}
                onClick={(): void => handleUnitOfTimeClick('months')}
              />
            </RadioWrapper>
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.threshold' })}</Label>
            <TFNumberInput
              value={unitOfTime === 'months' ? item?.months_threshold : item?.days_threshold}
              onChange={(value): void =>
                updateItemData([{ value, key: unitOfTime === 'months' ? 'months_threshold' : 'days_threshold' }])
              }
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.interval' })}</Label>
            <TFNumberInput
              value={unitOfTime === 'months' ? item?.months_repeat : item?.days_repeat}
              onChange={(value): void =>
                updateItemData([{ value, key: unitOfTime === 'months' ? 'months_repeat' : 'days_repeat' }])
              }
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px">
            <Label edit>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
            <TFNumberInput
              value={unitOfTime === 'months' ? item?.months_tolerance : item?.days_tolerance}
              onChange={(value): void =>
                updateItemData([{ value, key: unitOfTime === 'months' ? 'months_tolerance' : 'days_tolerance' }])
              }
            />
          </ItemWrapper>
        </Col>
        <Col md={12}>
          <ItemWrapper marginBottom="16px" data-testid="BaseModalFields-DatePickerWrapper">
            <Label edit>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
            {!item?.datetime_due ? (
              <DatePicker
                className={globalStyles.formDatePicker}
                format={dateFormat}
                value={item?.date_due ? moment.utc(item.date_due) : null}
                data-testid="formInputMonthsNextDue"
                onChange={(date, dateString): void => handleDateChange(dateString)}
              />
            ) : null}
            {item?.datetime_due ? (
              <DatePicker
                className={globalStyles.formDatePicker}
                format={dateFormat}
                value={item?.datetime_due ? moment.utc(item?.datetime_due) : null}
                data-testid="formInputMonthsNextDue"
                onChange={(date, dateString): void => handleDateChange(dateString)}
              />
            ) : null}
          </ItemWrapper>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={24}>
          <TimeDueWrapper>
            {unlockTimeDue ? (
              <UnlockedTimeDueWrapper data-testid="BaseModalFields-TimeDueWrapper">
                <TimeWrapper>
                  <Header editable>{formatMessage({ id: 'text.timeDue' })}</Header>
                  <TFTimePicker
                    id="timeDue"
                    initial={initialSeconds}
                    setValue={handleExpiryTimeChange}
                  />
                </TimeWrapper>
                {isUTC && (
                  <Button height="32px" onClick={handleTimeDueUnlock}>
                    {formatMessage({ id: 'text.removeTimeDue' })}
                  </Button>
                )}
              </UnlockedTimeDueWrapper>
            ) : (
              <Button height="32px" onClick={handleTimeDueUnlock}>
                {formatMessage({ id: 'text.unlockTimeDue' })}
              </Button>
            )}
          </TimeDueWrapper>
        </Col>
      </Row>
      {hasAPU && (
        <Row gutter={16}>
          <Col md={24}>
            <div className={styles.timesTitle} data-testid="BaseModalFields-APUHours">
              <span>{formatMessage({ id: 'form.title.addAPUHours' })}</span>
            </div>
          </Col>
          <Col md={12}>
            <ItemWrapper marginBottom="16px" data-testid="BaseModalFields-APUThreshold">
              <Label edit>{formatMessage({ id: 'form.labels.threshold' })}</Label>
              <TFNumberInput
                value={item?.apu_seconds_threshold ? Math.round(item?.apu_seconds_threshold / 3600) : null}
                onChange={(value): void => updateItemData([{ value: value * 3600, key: 'apu_seconds_threshold' }])}
                id="APUThreshold"
              />
            </ItemWrapper>
          </Col>
          <Col md={12}>
            <ItemWrapper marginBottom="16px" data-testid="BaseModalFields-APUInterval">
              <Label edit>{formatMessage({ id: 'form.labels.interval' })}</Label>
              <TFNumberInput
                value={item?.apu_seconds_repeat ? Math.round(item.apu_seconds_repeat / 3600) : null}
                onChange={(value): void => updateItemData([{ value: value * 3600, key: 'apu_seconds_repeat' }])}
                id="APUInterval"
              />
            </ItemWrapper>
          </Col>
          <Col md={12}>
            <ItemWrapper marginBottom="16px" data-testid="BaseModalFields-APUTolerance">
              <Label edit>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
              <TFNumberInput
                value={item?.apu_seconds_tolerance ? Math.round(item.apu_seconds_tolerance / 3600) : null}
                onChange={(value): void => updateItemData([{ value: value * 3600, key: 'apu_seconds_tolerance' }])}
                id="APUTolerance"
              />
            </ItemWrapper>
          </Col>
          <Col md={12}>
            <ItemWrapper marginBottom="16px" data-testid="BaseModalFields-APUNextDue">
              <Label edit>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
              <TFTimePicker
                initial={item.apu_seconds_due}
                setValue={(value): void => updateItemData([{ value, key: 'apu_seconds_due' }])}
                removeHoursRestriction
                id="APUNextDue"
              />
            </ItemWrapper>
          </Col>
        </Row>
      )}
      {CompletionDateFeatureFlag && item?.mx_type?.includes('scheduled') ? (
        <Row gutter={16}>
          <Col md={24}>
            <div className={styles.timesTitle} data-testid="sectionTitleCompletionType">
              <span>{formatMessage({ id: 'text.repeatFrom' })}</span>
              <ToolTip background="#fff" text={formatMessage({ id: 'text.completionDateTooltip' })} />
            </div>
          </Col>
          <Col md={24}>
            <ItemWrapper marginBottom="16px">
              <RadioWrapper data-testid="CompletionType-RadioWrapper">
                <RadioInputButton
                  text="Date resolved"
                  active={completionType === 'date_resolved'}
                  onClick={(): void => handleCompletionTypeClick('date_resolved')}
                  marginRight="8px"
                  dataTestId="CompletionType-DateResolved"
                />
                <RadioInputButton
                  text="Date due"
                  active={completionType === 'date_due'}
                  onClick={(): void => handleCompletionTypeClick('date_due')}
                  dataTestId="CompletionType-DateDue"
                />
              </RadioWrapper>
            </ItemWrapper>
          </Col>
        </Row>
      ) : null}
      {IndividualThresholdFlag ? (
        <Row gutter={16}>
          <Col md={24}>
            <div className={styles.timesTitle} data-testid="sectionTitleThresholdSection">
              <span>{formatMessage({ id: 'text.amberWarningOverride' })}</span>
              <ToolTip background="#fff" text={formatMessage({ id: 'text.individualThresholdsTooltip' })} />
            </div>
          </Col>
          <Col md={12}>
            <ItemWrapper marginBottom="16px">
              <Label edit>{formatMessage({ id: 'text.daysThreshold' })}</Label>
              <TFNumberInput
                value={item?.calendar_days_amber_limit}
                onChange={(value): void => handleIndividualThresholdChange(value, 'days')}
              />
            </ItemWrapper>
          </Col>
          <Col md={12}>
            <ItemWrapper marginBottom="16px">
              <Label edit>{formatMessage({ id: 'text.hoursThreshold' })}</Label>
              <TFTimePicker
                initial={item?.calendar_seconds_amber_limit}
                setValue={(value): void => handleIndividualThresholdChange(value, 'seconds')}
              />
            </ItemWrapper>
          </Col>
        </Row>
      ) : null}
      <Row gutter={16}>
        <Col md={24}>
          <div className={styles.timesTitle} data-testid="sectionTitleCustomSettingsSection">
            <span>{formatMessage({ id: 'text.customSettings' })}</span>
          </div>
        </Col>
        <Col md={24}>
          <ItemWrapper marginBottom="16px">
            <RadioWrapper data-testid="ShowOnSRP-RadioWrapper">
              <RadioInputButton
                text="Show on SRP"
                active={item?.show_on_srp}
                onClick={(): void => updateItemData([{ value: true, key: 'show_on_srp' }])}
                marginRight="8px"
                dataTestId="ShowOnSRP-True"
              />
              <RadioInputButton
                text="Hide on SRP"
                active={!item?.show_on_srp}
                onClick={(): void => updateItemData([{ value: false, key: 'show_on_srp' }])}
                dataTestId="ShowOnSRP-False"
              />
            </RadioWrapper>
          </ItemWrapper>
        </Col>
      </Row>
    </>
  );
};

export default BaseModalFields;
