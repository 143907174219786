import React from 'react';
import { RadioWrapper } from '../CommonStyledComponents/CommonStyledComponents';
import RadioInputButton from '../RadioInputButton/RadioInputButton';
import TFFormLabel from '../TFFormLabel/TFFormLabel';

interface TFCheckboxInputProps {
  options: { title: string; value: string }[];
  onChange: (value: string) => void;
  label?: string;
  id?: string;
  value?: string[];
  tooltip?: string;
  optional?: boolean;
  disabledOptions?: string[];
  capitalize?: boolean;
}

const defaultProps = {
  label: '',
  id: '',
  value: [],
  tooltip: '',
  optional: false,
  disabledOptions: [],
  capitalize: true
};

const TFCheckboxInput: React.FC<TFCheckboxInputProps> = ({
  options,
  onChange,
  label,
  id,
  tooltip,
  optional,
  value,
  disabledOptions,
  capitalize,
}) => {
  return (
    <div data-testid="TFCheckboxInput--Wrapper">
      {label && <TFFormLabel label={label} id={id} optional={optional} tooltip={tooltip} />}
      <RadioWrapper data-testid={`TFCheckboxInput--${id}`}>
        {options.map((item) => {
          return (
            <RadioInputButton
              text={item.title}
              active={!!(value && value.includes(item.value))}
              onClick={(): void => onChange(item.value)}
              key={item.value}
              marginRight="8px"
              dataTestId={`TFCheckboxInput--${id}-${item.value}`}
              checkbox
              disabled={disabledOptions?.includes(item.value)}
              capitalize={capitalize}
            />
          );
        })}
      </RadioWrapper>
    </div>
  );
};

TFCheckboxInput.defaultProps = defaultProps;

export default TFCheckboxInput;
