import { TruckUnit, FuelUnit, FuelUplift } from '../models/flights';

export function fuelUnitConversion(truckUnit: TruckUnit, aircraftFuelUnit: FuelUnit): number {
  if (truckUnit === 'usg' && aircraftFuelUnit === 'l') {
    return 1 / 3.785;
  }
  if (truckUnit === 'usg' && aircraftFuelUnit === 'kg') {
    return 1 / 0.453592;
  }
  if (truckUnit === 'l' && aircraftFuelUnit === 'lb') {
    return 1 / 2.20462;
  }
  if (truckUnit === 'l' && aircraftFuelUnit === 'usg') {
    return 3.785;
  }
  return 1;
}

export function checkDensity(truck: TruckUnit, aircraftFuelUnit: FuelUnit): boolean {
  if (truck === 'l' && (aircraftFuelUnit === 'l' || aircraftFuelUnit === 'usg')) {
    return false;
  }
  if (truck === 'usg' && (aircraftFuelUnit === 'usg' || aircraftFuelUnit === 'l')) {
    return false;
  }
  return true;
}

export function calculateActualUplift(uplift: FuelUplift, aircraftFuelUnit: FuelUnit): number {
  const bowser = uplift?.truck_actual_uplift ? parseInt(uplift?.truck_actual_uplift, 10) : 0;
  const density = uplift?.density ? parseFloat(uplift?.density) : 0.8;
  const truckUnit = uplift?.truck_unit;
  const densityToUse = truckUnit !== aircraftFuelUnit ? density : 1;
  let newAmount = bowser;
  if (aircraftFuelUnit !== truckUnit) {
    newAmount =
      bowser *
      (checkDensity(truckUnit, aircraftFuelUnit) ? densityToUse : 1) *
      (1 / fuelUnitConversion(truckUnit, aircraftFuelUnit));
  }
  return newAmount;
}
