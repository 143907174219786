import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { getCabinIssueSVG } from '../../services/api';
import {
  getAircraftSublocationGroupList,
  getAircraftSublocationGroupLists,
  updateAircraftSublocationGroupList,
} from '../../services/apiNew';
import {
  CabinIssuesActionTypes,
  CabinIssueSubLocation,
  FetchAircraftSublocationGroupListsPayload,
  FetchAircraftSublocationPayload,
  GetCabinSVGPayload,
  SelectedCabinIssueLocationPayload,
  UpdateAircraftSublocationGroupListParams,
} from '.';

export const getCabinSVG =
  ({ payload }: { payload: GetCabinSVGPayload }) =>
  async (dispatch: Dispatch): Promise<void> => {
    const response = await getCabinIssueSVG(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.GET_CABIN_ISSUE_SVG,
        payload: response,
      });
    }
  };

export const fetchAircraftSublocationGroupLists =
  ({ payload }: { payload: FetchAircraftSublocationGroupListsPayload }) =>
  async (dispatch: Dispatch): Promise<void> => {
    const response = await getAircraftSublocationGroupLists(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.GETSUBLOCATIONS,
        payload: response?.data,
      });
    }
  };

export const setSelectedCabinIssueLocation =
  ({ payload }: { payload: SelectedCabinIssueLocationPayload }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: CabinIssuesActionTypes.SELECTED_ISSUE_LOCATION,
      payload,
    });
  };

export const fetchAircraftSublocation =
  ({ payload }: { payload: FetchAircraftSublocationPayload }) =>
  async (dispatch: Dispatch): Promise<void> => {
    const response = await getAircraftSublocationGroupList(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.GETINDIVIDUALSUBLOCATION,
        payload: response?.data,
      });
    }
  };

export const updateAircraftSubLocation =
  ({ payload }: { payload: UpdateAircraftSublocationGroupListParams }) =>
  async (dispatch: Dispatch): Promise<AxiosResponse<CabinIssueSubLocation> | null> => {
    const response = await updateAircraftSublocationGroupList(payload);
    if (response) {
      dispatch({
        type: CabinIssuesActionTypes.UPDATEINDIVIDUALSUBLOCATION,
        payload: response.data,
      });
      return response;
    }
    return null;
  };
