import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Divider, Header, Label, Text, Title } from '../CommonStyledComponents/CommonStyledComponents';
import AuthDropdownMenu from '../AuthDropdownMenu/AuthDropdownMenu';
import PublicShareLink from '../PublicShareLink/PublicShareLink';
import CRSButton from '../CRSButton/CRSButton';
import { Maintenance, MaintenanceActionTypes } from '../../models/maintenance';
import { stringToTitleCase } from '../../utils/utils';
import { DashboardState } from '../../models';
import { changeDrawerContent, changeDrawerMode, changeDrawerVisibility } from '../../models/drawer';
import { AircraftResource } from '../../models/aircraft';
import timeUtils from '../../utils/timeUtils';
import hoursIcon from '../../assets/icon-card-mx-flight-hours-limit.svg';
import cyclesIcon from '../../assets/icon-card-mx-flight-cycles-limit.svg';
import MXItemDrawer from '../../pages/AircraftMaintenance/MXItemDrawer';
import monthsIcon from '../../assets/icon-card-mx-flight-month-limit.svg';
import apuIcon from '../../assets/icon-card-mx-apu-hours-limit.svg';
import { fetchAircraftStatus } from '../../models/aircraft/actions';
import { ToastCategories, ToastTypes, addToast } from '../../models/toasts';
import { removeMaintenance } from '../../services/api';
import BuildProgressBar from './BuildProgressBar';

interface MaintenanceDrawerViewSectionProps {
  itemData: Maintenance;
  mxType: string;
  area: string;
}

const Wrapper = styled.div`
  width: 100%;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: #fcfcfc;
`;

const StyledHeader = styled(Header)`
  color: #181818;
  letter-spacing: -0.09px;
  font-weight: 500;
  text-transform: capitalize;
`;

const TypeBox = styled.div`
  min-width: 100px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background-color: ${({ type, theme }): string => {
    if (type === 'Scheduled') return theme.colours.scheduledBlue;
    if (type === 'LLP') return theme.colours.llpBlue;
    if (type === 'OOP') return theme.colours.oopBlue;
    return 'transparent';
  }};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PublicShareLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-left: auto;
  min-width: 191px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const ProgressWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ hasAPU }): string => (hasAPU ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr')};
  width: 100%;
  margin: 24px 0;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const AreaHeader = styled.div`
  font-weight: 500;
`;

const ItemTitle = styled.div``;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  img {
    margin-right: 8px;
    width: 32px;
  }
`;

const TitleSpan = styled.span`
  margin-right: 10px;
  flex-shrink: 0;
`;

const MaintenanceDrawerViewSection: React.FC<MaintenanceDrawerViewSectionProps> = ({ itemData, mxType, area }) => {
  const {
    aircraft: { aircraftMap },
    userSettings: { dateFormat },
  } = useSelector((state: DashboardState) => state);

  const [amberWarning, setAmberWarning] = useState(null);
  const [daysNextDueDisplayValue, setDaysNextDueDisplayValue] = useState(null);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const aircraftHasAPU = aircraftMap.get(id)?.apu_installed;

  const displayType = (value: string): string => {
    let newValue = value;
    if (value === 'scheduled') newValue = 'Scheduled';
    if (value === 'llp') newValue = 'LLP';
    if (value === 'oop') newValue = 'OOP';
    return newValue;
  };

  const formatHoursAndMinutes = (seconds): string => {
    if (!seconds) return '-';
    const hours = timeUtils.getOnlyHoursFromSeconds(seconds);
    const minutes = timeUtils.getOnlyMinutesFromSeconds(seconds);
    const newMinutes = minutes > 9 ? minutes : `0${minutes}`;
    return `${hours}:${newMinutes}`;
  };

  const handleDelete = async () => {
    try {
      const payload = {
        id: itemData.id,
        aircraft_id: id,
      };
      const response = await removeMaintenance(payload);
      if (response && response.statusCode === 204) {
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'message.itemDeleted' }),
              message: '',
              type: ToastTypes.SUCCESS,
              category: ToastCategories.FLASH,
            },
          }),
        );
        dispatch({
          type: MaintenanceActionTypes.DELETE,
          payload: { id: itemData.id },
        });
        dispatch(fetchAircraftStatus({ payload: id }));
        dispatch(changeDrawerVisibility({ payload: false }));
      }
    } catch (error) {
      return error;
    }
    return null;
  };

  // TODO: make new apply tolerance modal
  // const handleApplyTolerance = () => {
  // todo
  // };

  const handleViewHistory = () => {
    dispatch(
      changeDrawerContent({
        payload: {
          content: <MXItemDrawer />,
        },
      }),
    );
  };

  useEffect(() => {
    if (itemData?.calendar_days_amber_limit) {
      setAmberWarning(`${itemData?.calendar_days_amber_limit} days threshold`);
    }
    if (itemData?.calendar_seconds_amber_limit) {
      setAmberWarning(`${itemData?.calendar_seconds_amber_limit} hours threshold`);
    }
  }, [itemData]);

  useEffect(() => {
    if (itemData?.datetime_due) {
      const newDate = `${moment(itemData.datetime_due).format(`${dateFormat} HH:mm`)}z`;
      setDaysNextDueDisplayValue(newDate);
    } else if (itemData?.date_due) {
      const newDate = moment(itemData.date_due).format(dateFormat);
      setDaysNextDueDisplayValue(newDate);
    } else {
      setDaysNextDueDisplayValue('-');
    }
  }, [dateFormat, itemData.date_due, itemData.datetime_due]);

  return (
    <Wrapper>
      <Title data-testid="MaintenanceDrawerViewSection--Title">
        <TitleSpan>{formatMessage({ id: 'text.maintenanceItem' })}</TitleSpan>
        <AuthDropdownMenu
          options={{ read: false, update: true, delete: true, custom: true, custom2: true }}
          aircraftId={id}
          resource={AircraftResource.SCHEDULED_MX_ITEM}
          editCallback={() => dispatch(changeDrawerMode({ payload: 'edit' }))}
          handleDelete={handleDelete}
          // customCallback={handleApplyTolerance}
          // customText="Apply tolerance"
          custom2Callback={handleViewHistory}
          custom2Text="View history"
        />
      </Title>
      <HeaderWrapper>
        <TypeWrapper>
          <TypeBox data-testid="MaintenanceDrawerViewSection--TypeBox" type={displayType(mxType)}>
            {displayType(mxType)}
          </TypeBox>
          <div>
            <AreaHeader data-testid="MaintenanceDrawerViewSection--TypeAreaText">
              {`${stringToTitleCase(area?.replace(/_/g, ' ') || '')}`}
            </AreaHeader>
            <ItemTitle data-testid="MaintenanceDrawerViewSection--ItemTitle">{itemData.name}</ItemTitle>
          </div>
        </TypeWrapper>
        <PublicShareLinkWrapper>
          <CRSButton crsItemId={itemData.id} status={itemData.status} type="scheduled_mx_items" />
          <PublicShareLink id={itemData.id} type="scheduled_mx_items" />
        </PublicShareLinkWrapper>
      </HeaderWrapper>
      <ProgressWrapper hasAPU={aircraftHasAPU}>
        <BuildProgressBar itemData={itemData} type="hours" />
        <BuildProgressBar itemData={itemData} type="cycles" />
        <BuildProgressBar itemData={itemData} type="days" />
        {aircraftHasAPU ? <BuildProgressBar itemData={itemData} type="apu" /> : null}
      </ProgressWrapper>
      <GridWrapper>
        <SectionHeader style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--FlightHoursLimit">
          <StyledHeader>
            <img src={hoursIcon} alt="hours icon" />
            {formatMessage({ id: 'text.flightHoursLimit' })}
          </StyledHeader>
        </SectionHeader>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-Threshold">
          <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
          <Text>{itemData?.flight_seconds_threshold ? itemData?.flight_seconds_threshold / 3600 : '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-Repeat">
          <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
          <Text>{itemData?.flight_seconds_repeat ? itemData?.flight_seconds_repeat / 3600 : '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-Tolerance">
          <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
          <Text>{itemData?.flight_seconds_tolerance ? itemData?.flight_seconds_tolerance / 3600 : '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightHoursLimit-NextDue">
          <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
          <Text>{formatHoursAndMinutes(itemData?.flight_seconds_due)}</Text>
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <SectionHeader style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit">
          <img src={cyclesIcon} alt="cycles icon" />
          <StyledHeader>{formatMessage({ id: 'text.flightCyclesLimit' })}</StyledHeader>
        </SectionHeader>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-Threshold">
          <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
          <Text>{itemData?.cycles_threshold || '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-Repeat">
          <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
          <Text>{itemData?.cycles_repeat || '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-Tolerance">
          <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
          <Text>{itemData?.cycles_tolerance || '-'}</Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightCyclesLimit-NextDue">
          <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
          <Text>{itemData?.cycles_due || '-'}</Text>
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <SectionHeader style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--FlightMonthLimit">
          <img src={monthsIcon} alt="months icon" />
          <StyledHeader>{formatMessage({ id: 'text.flightMonthLimit' })}</StyledHeader>
        </SectionHeader>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-Threshold">
          <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
          <Text>
            {itemData?.unit_of_time === 'days' ? itemData?.days_threshold || '-' : itemData?.months_threshold || '-'}
          </Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-Repeat">
          <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
          <Text>
            {itemData?.unit_of_time === 'days' ? itemData?.days_repeat || '-' : itemData?.months_repeat || '-'}
          </Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-Tolerance">
          <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
          <Text>
            {itemData?.unit_of_time === 'days' ? itemData?.days_tolerance || '-' : itemData?.months_tolerance || '-'}
          </Text>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--FlightMonthLimit-NextDue">
          <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
          <Text>{daysNextDueDisplayValue}</Text>
        </div>
        {aircraftHasAPU ? (
          <>
            <div style={{ gridColumn: 'span 4' }}>
              <Divider />
            </div>
            <SectionHeader style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--APUHoursLimit">
              <img src={apuIcon} alt="apu icon" />
              <StyledHeader>{formatMessage({ id: 'text.apuHoursLimit' })}</StyledHeader>
            </SectionHeader>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-Threshold">
              <Label>{formatMessage({ id: 'form.labels.threshold' })}</Label>
              <Text>{itemData?.apu_seconds_threshold ? itemData?.apu_seconds_threshold / 3600 : '-'}</Text>
            </div>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-Repeat">
              <Label>{formatMessage({ id: 'form.labels.interval' })}</Label>
              <Text>{itemData?.apu_seconds_repeat ? itemData?.apu_seconds_repeat / 3600 : '-'}</Text>
            </div>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-Tolerance">
              <Label>{formatMessage({ id: 'form.labels.tolerance' })}</Label>
              <Text>{itemData?.apu_seconds_tolerance ? itemData?.apu_seconds_tolerance / 3600 : '-'}</Text>
            </div>
            <div data-testid="MaintenanceDrawerViewSection--APUHoursLimit-NextDue">
              <Label>{formatMessage({ id: 'form.labels.nextDue' })}</Label>
              <Text>{formatHoursAndMinutes(itemData?.apu_seconds_due)}</Text>
            </div>
          </>
        ) : null}
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <div style={{ gridColumn: 'span 2' }} data-testid="MaintenanceDrawerViewSection--RepeatFrom">
          <Label>{`Repeat from: `}</Label>
          <Text>
            {`${itemData?.time_based_repeat_calculation_method === 'date_date' ? 'Date due' : 'Date resolved'}`}
          </Text>
        </div>
        <div style={{ gridColumn: 'span 2' }} data-testid="MaintenanceDrawerViewSection--AmberWarningOverride">
          <Label>{`Amber warning override: `}</Label>
          <Text>{`${amberWarning || 'Not set'}`}</Text>
        </div>
        <div style={{ gridColumn: 'span 4' }}>
          <Divider />
        </div>
        <div style={{ gridColumn: 'span 4' }} data-testid="MaintenanceDrawerViewSection--CustomSettings">
          <StyledHeader>{formatMessage({ id: 'text.customSettings' })}</StyledHeader>
        </div>
        <div data-testid="MaintenanceDrawerViewSection--ShowOnSRP">
          <Label>{formatMessage({ id: 'text.showOnSRP' })}</Label>
          <Text>{itemData?.show_on_srp ? 'Yes' : 'No'}</Text>
        </div>
      </GridWrapper>
    </Wrapper>
  );
};

export default MaintenanceDrawerViewSection;
