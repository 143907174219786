import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { Divider, Label, SectionHeader, Text } from '../../CommonStyledComponents/CommonStyledComponents';
import FileViewer from '../../TFFileViewer/fileViewer';
import ResolutionDetails from '../../WorkPackDrawer/Components/ResolutionDetails';
import ViewSignatureRow from './ViewSignatureRow';
import ViewDefectDrawerDeferralOptions from './ViewDefectDrawerDeferralOptions';
import ViewLimitations from './ViewLimitations';
import ViewDefectAdditionalInformation from './ViewDefectAdditionalInformation';
import ViewRectificationExtension from './ViewRectificationExtension';

interface ViewDefectDrawerDetailsProps {
  defectData: Defect;
  editResolved: boolean;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  requiredSettings: {
    defectRename: string;
    reportedByTypeEnabled: boolean;
    stageOfFlightEnabled: boolean;
    stageOfFlightText: string;
    eropsRename: string;
    melNameOverride: string;
    cdlNameOverride: string;
    nefNameOverride: string;
    casNameOverride: string;
    otherNameOverride: string;
    showRichTextMel: boolean;
    troubleShootingStepsRename: string;
    deferralApprovalReferenceRename: string;
    part145ApprovalNumberRename: string;
    engineerRename: string;
    engineerLicenceNumberRename: string;
    dateFormat: string;
  };
}

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0 0;
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
`;

const ViewDefectDrawerDetails: React.FC<ViewDefectDrawerDetailsProps> = ({
  defectData,
  editResolved,
  updateDefectData,
  requiredSettings,
}) => {
  const { formatMessage } = useIntl();

  const lastMXEvent = defectData?.mx_events && defectData?.mx_events[defectData?.mx_events.length - 1];

  return (
    <div>
      <SectionHeader data-testid="ViewDefectDrawerDetails--Header">
        {formatMessage({ id: 'form.labels.defectDetails' }, { field: requiredSettings.defectRename })}
      </SectionHeader>
      <GridSection>
        <div data-testid="ViewDefectDrawerDetails--DetailsSection">
          <Label>{formatMessage({ id: 'text.details' })}</Label>
          {editResolved ? (
            <StyledTextarea
              id="details"
              name="details"
              data-testid="EditDefectDrawerDetails--TextArea"
              cols={30}
              rows={5}
              onChange={(e): void => {
                updateDefectData([{ value: e.target.value, key: 'details' }]);
              }}
              value={defectData?.details}
            />
          ) : (
            <Text>{defectData?.details || '-'}</Text>
          )}
        </div>
        <div data-testid="ViewDefectDrawerDetails--AttachmentsSection">
          <Label>{formatMessage({ id: 'text.attachments' })}</Label>
          <FileViewer
            attachments={defectData?.attachments}
            uploaderVisible={false}
            allowDelete={false}
            disableAddition
          />
        </div>
        {requiredSettings.reportedByTypeEnabled ? (
          <div data-testid="ViewDefectDrawerDetails--ReportedByTypeSection">
            <Label>{formatMessage({ id: 'text.reportedBy' })}</Label>
            <Text>{defectData?.reported_by_type || '-'}</Text>
          </div>
        ) : null}
        {requiredSettings.stageOfFlightEnabled ? (
          <div data-testid="ViewDefectDrawerDetails--StageOfFlightSection">
            <Label>{requiredSettings.stageOfFlightText}</Label>
            <Text>{defectData?.stage_of_flight || '-'}</Text>
          </div>
        ) : null}
      </GridSection>
      <ViewSignatureRow
        partialDefectData={{
          person: defectData?.reporter,
          date: defectData?.raised_at,
          signatureData: defectData?.signature_data,
        }}
        dateFormat={requiredSettings.dateFormat}
        type={formatMessage({ id: 'text.reportedBy' })}
      />
      <Divider />
      <ViewDefectDrawerDeferralOptions defectData={defectData} requiredSettings={requiredSettings} />
      <ViewLimitations defectData={defectData} eropsRename={requiredSettings.eropsRename} />
      <ViewDefectAdditionalInformation
        defectData={defectData}
        requiredSettings={{
          troubleShootingStepsRename: requiredSettings.troubleShootingStepsRename,
          deferralApprovalReferenceRename: requiredSettings.deferralApprovalReferenceRename,
          part145ApprovalNumberRename: requiredSettings.part145ApprovalNumberRename,
          engineerRename: requiredSettings.engineerRename,
          engineerLicenceNumberRename: requiredSettings.engineerLicenceNumberRename,
        }}
      />
      {defectData?.deferred && defectData?.display_data?.category !== 'A' ? (
        <ViewRectificationExtension
          rectificationIntervalExtension={defectData?.rectification_interval_extension}
          dateFormat={requiredSettings.dateFormat}
        />
      ) : null}
      {defectData?.deferred_by?.id ? (
        <ViewSignatureRow
          partialDefectData={{
            person: defectData?.deferred_by,
            date: defectData?.deferred_at,
            signatureData: defectData?.deferral_signature_data,
          }}
          dateFormat={requiredSettings.dateFormat}
          type={formatMessage({ id: 'text.deferredBy' })}
        />
      ) : null}
      {defectData?.edited_by?.id ? (
        <ViewSignatureRow
          partialDefectData={{
            person: defectData?.edited_by,
            date: defectData?.edited_date,
            signatureData: defectData?.approval_signature_data,
          }}
          dateFormat={requiredSettings.dateFormat}
          type={formatMessage({ id: 'text.editedBy' })}
        />
      ) : null}
      {defectData?.mx_events && defectData?.mx_events.length > 0 ? (
        <>
          <Divider />
          <ResolutionDetails release={lastMXEvent} poNum={lastMXEvent.workpack_reference} noCard />
        </>
      ) : null}
    </div>
  );
};

export default ViewDefectDrawerDetails;
