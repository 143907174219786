import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IntlShape } from 'react-intl';
import { changeModalVisibility, changeModalContent } from '../models/drawer';
import { updateTripFromDrawer } from '../models/trips/actions';
import { ToastCategories, ToastTypes, addToast } from '../models/toasts';
import { DashboardState } from '../models';

export default function buildCompleteOption(
  tripId: string,
  dispatch: ThunkDispatch<DashboardState, unknown, AnyAction>,
  formatMessage: IntlShape['formatMessage'],
): object {
  const onClick = async () => {
    const saveAction = async () => {
      try {
        await dispatch(
          updateTripFromDrawer({
            id: tripId,
            trip: {
              marked_as_complete: true,
            },
          }),
        );

        dispatch(changeModalVisibility({ payload: false }));
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'text.tripCompleted' }),
              type: ToastTypes.SUCCESS,
              category: ToastCategories.FLASH,
              message: '',
            },
          }),
        );
      } catch (error) {
        console.error(error);

        dispatch(changeModalVisibility({ payload: false }));
        dispatch(
          addToast({
            payload: {
              title: formatMessage({ id: 'text.problemCompletingTrip' }),
              type: ToastTypes.ERROR,
              category: ToastCategories.FLASH,
              message: formatMessage({ id: 'text.contact' }),
            },
          }),
        );
      }
    };

    const cancelAction = () => {
      dispatch(changeModalVisibility({ payload: false }));
    };

    // Why would you ever want to do these separately if changing the visibility to true resets the content anyway
    dispatch(changeModalVisibility({ payload: true }));
    dispatch(
      changeModalContent({
        payload: {
          title: formatMessage({ id: 'text.completeTrip' }),
          text: formatMessage({ id: 'text.confirmCompleteTrip' }),
          saveButtonText: formatMessage({ id: 'form.button.complete' }),
          saveAction,
          cancelAction,
        },
      }),
    );
  };

  return {
    text: formatMessage({ id: 'form.button.complete' }),
    onClick,
  };
}
