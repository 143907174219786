import React from 'react';
import { useIntl } from 'react-intl';
import { Label, Text } from '../../CommonStyledComponents/CommonStyledComponents';

interface DefectFieldProps {
  formatMessageId: string;
  value: string | number;
  formatMessageOverride?: string;
}

const DefectField: React.FC<DefectFieldProps> = ({ formatMessageId, value, formatMessageOverride }) => {
  const { formatMessage } = useIntl();

  return (
    <div data-testid={formatMessageId}>
      <Label>
        {formatMessageOverride
          ? formatMessage({ id: formatMessageId }, { field: formatMessageOverride })
          : formatMessage({ id: formatMessageId })}
      </Label>
      <Text>{value || '-'}</Text>
    </div>
  );
};

DefectField.defaultProps = {
  formatMessageOverride: null,
};

export default DefectField;
