/* eslint-disable max-len */
export default {
  'navBar.lang': 'Languages',
  'lang.simplified-chinese': '简体中文',
  'lang.traditional-chinese': '繁体中文',
  'lang.english': 'English',
  'lang.portuguese': 'Portuguese',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'validation.email.required': 'Please enter your email',
  'validation.enterEmail.required': 'Please enter an email',
  'validation.email.wrong-format': 'The email address is in the wrong format!',
  'validation.password.required': 'Please enter your password!',
  'validation.password.twice': 'The passwords entered twice do not match!',
  'validation.password.strength.msg':
    "Please enter at least 6 characters and don't use passwords that are easy to guess.",
  'validation.password.strength.strong': 'Strength: strong',
  'validation.password.strength.medium': 'Strength: medium',
  'validation.password.strength.short': 'Strength: too short',
  'validation.confirm-password.required': 'Please confirm your password!',
  'validation.phone-number.required': 'Please enter your phone number!',
  'validation.phone-number.wrong-format': 'Malformed phone number!',
  'validation.verification-code.required': 'Please enter the verification code!',
  'validation.title.required': 'Please enter a title',
  'validation.date.required': 'Please select the start and end date',
  'validation.goal.required': 'Please enter a description of the goal',
  'validation.standard.required': 'Please enter a metric',
  'validation.firstName.required': 'Please enter your first name',
  'validation.enterFirstName.required': 'Please enter a first name',
  'validation.lastName.required': 'Please enter your last name',
  'validation.enterLastName.required': 'Please enter a last name',
  'validation.organisation.required': 'Please select your company',
  'validation.role.required': 'Please select your role',
  'form.optional': ' (optional) ',
  'form.optionalCaps': '(OPTIONAL)',
  'form.submit': 'Submit',
  'form.save': 'Save',
  'form.email.placeholder': 'Email',
  'form.password.placeholder': 'Password',
  'form.confirm-password.placeholder': 'Confirm password',
  'form.phone-number.placeholder': 'Phone number',
  'form.verification-code.placeholder': 'Verification code',
  'form.title.label': 'Title',
  'form.title.placeholder': 'Give the target a name',
  'form.date.label': 'Start and end date',
  'form.date.placeholder.start': 'Start date',
  'form.date.placeholder.end': 'End date',
  'form.goal.label': 'Goal description',
  'form.goal.placeholder': 'Please enter your work goals',
  'form.standard.label': 'Metrics',
  'form.standard.placeholder': 'Please enter a metric',
  'form.client.label': 'Client',
  'form.client.label.tooltip': 'Target service object',
  'form.client.placeholder': 'Please describe your customer service, internal customers directly @ Name / job number',
  'form.invites.label': 'Inviting critics',
  'form.invites.placeholder': 'Please direct @ Name / job number, you can invite up to 5 people',
  'form.weight.label': 'Weight',
  'form.weight.placeholder': 'Please enter weight',
  'form.public.label': 'Target disclosure',
  'form.public.label.help': 'Customers and invitees are shared by default',
  'form.public.radio.public': 'Public',
  'form.public.radio.partially-public': 'Partially public',
  'form.public.radio.private': 'Private',
  'form.publicUsers.placeholder': 'Open to',
  'form.publicUsers.option.A': 'Colleague A',
  'form.publicUsers.option.B': 'Colleague B',
  'form.publicUsers.option.C': 'Colleague C',
  'component.globalHeader.search': 'Search',
  'component.globalHeader.search.example1': 'Search example 1',
  'component.globalHeader.search.example2': 'Search example 2',
  'component.globalHeader.search.example3': 'Search example 3',
  'component.globalHeader.help': 'Help',
  'component.globalHeader.notification': 'Notification',
  'component.globalHeader.notification.empty': 'You have viewed all notifications.',
  'component.globalHeader.message': 'Message',
  'component.globalHeader.message.empty': 'You have viewed all messsages.',
  'component.globalHeader.event': 'Event',
  'component.globalHeader.event.empty': 'You have viewed all events.',
  'component.noticeIcon.clear': 'Clear',
  'component.noticeIcon.cleared': 'Cleared',
  'component.noticeIcon.empty': 'No notifications',
  menu: 'Trustflight',
  'menu.Aircraft.AircraftSettings': 'Aircraft Settings',
  'menu.Aircraft.Aircraft Settings': 'Aircraft Settings',
  'menu.Aircraft.Aircraft_frat': 'FRAT',
  'menu.Aircraft.Cabin_log': 'Cabin Log',
  'menu.Aircraft.Custom_fields': 'Custom Fields',
  'menu.Aircraft.DamageMap': 'Dent & Buckle',
  'menu.Aircraft.Damage Map.List View': 'List View',
  'menu.Aircraft.Damage_maps.List_view': '{field}',
  'menu.Aircraft.Damage_maps.Schematic_view': '{field}',
  'menu.Aircraft.Damage Map.Schematic View': 'Schematic View',
  'menu.Aircraft.Defects.Defect Profile': 'Defect Profile',
  'menu.Aircraft.Defects': 'Defects',
  'menu.Aircraft.Defects.Add Defect': 'Add Defect',
  'menu.Aircraft.Defects.Add Defect.Add Defect': 'Add Defect',
  'menu.Aircraft.Defects.Defects': 'Defects',
  'menu.Aircraft.Defects.Log': 'Defects',
  'menu.Aircraft.Documents': 'Documents',
  'menu.Aircraft.Intermittent_faults': 'Intermittent Faults',
  'menu.Aircraft.Logbook': 'Logbook',
  'menu.Aircraft.Maintenance.Maintenance': 'Maintenance',
  'menu.Aircraft.Maintenance.Airframe': 'Maintenance',
  'menu.Aircraft.Maintenance.Oop': 'Maintenance',
  'menu.Aircraft.Maintenance.Llp': 'Maintenance',
  'menu.Aircraft.Maintenance': 'Maintenance',
  'menu.Aircraft.Metrics': 'Metrics',
  'menu.Aircraft.Notes': 'Notes to Crew',
  'menu.Aircraft.Overview': 'Overview',
  'menu.Aircraft.Settings': 'Settings',
  'menu.Aircraft.Trips': 'Trips',
  'menu.Aircraft.Trips.Add Trip': 'Add Trip',
  'menu.Aircraft.Workpacks': 'Workpacks',
  'menu.Aircraft.Workpacks.Workpack': 'Workpack',
  'menu.Aircraft.Workpacks.Workpacks': 'Workpacks',
  'menu.Aircraft.Workpacks.Log': 'Workpacks',
  'menu.Aircraft.Workpacks.Add Workpack': 'Add Workpack',
  'menu.home': 'Home',
  'menu.Dashboard': 'Dashboard',
  'menu.fleet': 'Fleet',
  'menu.Frat': 'FRAT',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.Maintenance.Defects': 'Defects',
  'menu.Maintenance.Defect Profile': 'Defect Profile',
  'menu.mels': 'MELs',
  'menu.receipts': 'Receipts',
  'menu.Reports.Fuel': 'Fuel',
  'menu.Reports.Metrics': 'Metrics',
  'menu.Reports.Map': 'Map',
  'menu.Reports.Receipts': 'Receipts',
  'menu.Operations.Flights.Edit Flight': 'Edit Flight',
  'menu.Operations.Flights.Flight Profile': 'Flight Profile',
  'menu.Operations.Flights': 'Flights',
  'menu.People': 'Manage People',
  'menu.Settings.Account Settings': 'Account Settings',
  'menu.Settings.Aircraft': 'Manage Aircraft',
  'menu.Settings.Subscriptions': 'Manage Subscriptions',
  'menu.Settings.Operators': 'Templates',
  'menu.Settings.Operator_settings': 'Operator Settings',
  'menu.Operations.Documents': 'Manage Documents',
  'menu.Operations.Logbook': 'Logbook',
  'menu.Manage.trips': 'Manage Trips',
  'menu.Settings.Integrations': 'Manage Integrations',
  'menu.Settings.Mels': 'Manage MELs',
  'menu.support': 'Support',
  'menu.Add.Trip': 'Add Trip',
  'menu.Add.Defect': 'Add {field}',
  'menu.Add.aircraft': 'Add Aircraft',
  'menu.form': 'Form',
  'menu.form.basicform': 'Basic Form',
  'menu.form.stepform': 'Step Form',
  'menu.form.stepform.info': 'Step Form(write transfer information)',
  'menu.form.stepform.confirm': 'Step Form(confirm transfer information)',
  'menu.form.stepform.result': 'Step Form(finished)',
  'menu.form.advancedform': 'Advanced Form',
  'menu.list': 'List',
  'menu.list.searchtable': 'Search Table',
  'menu.list.basiclist': 'Basic List',
  'menu.list.cardlist': 'Card List',
  'menu.list.searchlist': 'Search List',
  'menu.list.searchlist.articles': 'Search List(articles)',
  'menu.list.searchlist.projects': 'Search List(projects)',
  'menu.list.searchlist.applications': 'Search List(applications)',
  'menu.profile': 'Profile',
  'menu.profile.basic': 'Basic Profile',
  'menu.profile.advanced': 'Advanced Profile',
  'menu.result': 'Result',
  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.changepassword': 'Change password',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Log out',
  'menu.account.signout': 'Sign out',
  'menu.registration': 'Registration',
  'menu.registrationAZ': 'Registration (A-Z)',
  'menu.registrationZA': 'Registration (Z-A)',
  'menu.status': 'Status',
  'menu.helpdesk': 'Helpdesk',
  'app.login.message-invalid-credentials': 'Invalid username or password（admin/888888）',
  'app.login.message-invalid-verification-code': 'Invalid verification code',
  'app.login.tab-login-credentials': 'Credentials',
  'app.login.tab-login-mobile': 'Mobile number',
  'app.login.remember-me': 'Remember me',
  'app.login.forgot-password': 'Forgot your password?',
  'app.login.sign-in-with': 'Sign in with',
  'app.login.signup': 'Sign up',
  'app.login.login': 'Login',
  'app.register.register': 'Register',
  'app.register.get-verification-code': 'Get code',
  'app.register.sing-in': 'Already have an account?',
  'app.register-result.msg': 'Account：registered at {email}',
  'app.register-result.activation-email':
    'The activation email has been sent to your email address and is valid for 24 hours. Please log in to the email in time and click on the link in the email to activate the account.',
  'app.register-result.back-home': 'Back to home',
  'app.register-result.view-mailbox': 'View mailbox',
  'app.home.introduce': 'introduce',
  'app.analysis.test': 'Gongzhuan No.{no} shop',
  'app.analysis.introduce': 'Introduce',
  'app.analysis.total-sales': 'Total Sales',
  'app.analysis.day-sales': 'Day Sales',
  'app.analysis.visits': 'Visits',
  'app.analysis.visits-trend': 'Visits Trend',
  'app.analysis.visits-ranking': 'Visits Ranking',
  'app.analysis.day-visits': 'Day Visits',
  'app.analysis.week': 'Week Ratio',
  'app.analysis.day': 'Day Ratio',
  'app.analysis.payments': 'Payments',
  'app.analysis.conversion-rate': 'Conversion Rate',
  'app.analysis.operational-effect': 'Operational Effect',
  'app.analysis.sales-trend': 'Stores Sales Trend',
  'app.analysis.sales-ranking': 'Sales Ranking',
  'app.analysis.all-year': 'All Year',
  'app.analysis.all-month': 'All Month',
  'app.analysis.all-week': 'All Week',
  'app.analysis.all-day': 'All day',
  'app.analysis.search-users': 'Search Users',
  'app.analysis.per-capita-search': 'Per Capita Search',
  'app.analysis.online-top-search': 'Online Top Search',
  'app.analysis.the-proportion-of-sales': 'The Proportion Of Sales',
  'app.analysis.channel.all': 'ALL',
  'app.analysis.channel.online': 'Online',
  'app.analysis.channel.stores': 'Stores',
  'app.analysis.sales': 'Sales',
  'app.analysis.traffic': 'Traffic',
  'app.analysis.table.rank': 'Rank',
  'app.analysis.table.search-keyword': 'Keyword',
  'app.analysis.table.users': 'Users',
  'app.analysis.table.weekly-range': 'Weekly Range',
  'app.forms.basic.title': 'Basic form',
  'app.forms.basic.description':
    'Form pages are used to collect or verify information to users, and basic forms are common in scenarios where there are fewer data items.',
  'app.monitor.trading-activity': 'Real-Time Trading Activity',
  'app.monitor.total-transactions': 'Total transactions today',
  'app.monitor.sales-target': 'Sales target completion rate',
  'app.monitor.remaining-time': 'Remaining time of activity',
  'app.monitor.total-transactions-per-second': 'Total transactions per second',
  'app.monitor.activity-forecast': 'Activity forecast',
  'app.monitor.efficiency': 'Efficiency',
  'app.monitor.ratio': 'Ratio',
  'app.monitor.proportion-per-category': 'Proportion Per Category',
  'app.monitor.fast-food': 'Fast food',
  'app.monitor.western-food': 'Western food',
  'app.monitor.hot-pot': 'Hot pot',
  'app.monitor.waiting-for-implementation': 'Waiting for implementation',
  'app.monitor.popular-searches': 'Popular Searches',
  'app.monitor.resource-surplus': 'Resource Surplus',
  'app.monitor.fund-surplus': 'Fund Surplus',
  'app.settings.menuMap.basic': 'Basic Settings',
  'app.settings.menuMap.security': 'Security Settings',
  'app.settings.menuMap.binding': 'Account Binding',
  'app.settings.menuMap.notification': 'New Message Notification',
  'app.settings.basic.avatar': 'Change avatar',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Please input your email!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Please input your Nickname!',
  'app.settings.basic.profile': 'Personal profile',
  'app.settings.basic.profile-message': 'Please input your personal profile!',
  'app.settings.basic.profile-placeholder': 'Brief introduction to yourself',
  'app.settings.basic.country': 'Country/Region',
  'app.settings.basic.country-message': 'Please input your country!',
  'app.settings.basic.geographic': 'Province or city',
  'app.settings.basic.geographic-message': 'Please input your geographic info!',
  'app.settings.basic.address': 'Street Address',
  'app.settings.basic.address-message': 'Please input your address!',
  'app.settings.basic.phone': 'Phone Number',
  'app.settings.basic.phone-message': 'Please input your phone!',
  'app.settings.basic.update': 'Update Information',
  'app.settings.security.strong': 'Strong',
  'app.settings.security.medium': 'Medium',
  'app.settings.security.weak': 'Weak',
  'app.settings.security.password': 'Account Password',
  'app.settings.security.password-description': 'Current password strength：',
  'app.settings.security.phone': 'Security Phone',
  'app.settings.security.phone-description': 'Bound phone：',
  'app.settings.security.question': 'Security Question',
  'app.settings.security.question-description':
    'The security question is not set, and the security policy can effectively protect the account security',
  'app.settings.security.email': 'Backup Email',
  'app.settings.security.email-description': 'Bound Email：',
  'app.settings.security.mfa': 'MFA Device',
  'app.settings.security.mfa-description': 'Unbound MFA device, after binding, can be confirmed twice',
  'app.settings.security.modify': 'Modify',
  'app.settings.security.set': 'Set',
  'app.settings.security.bind': 'Bind',
  'app.settings.binding.taobao': 'Binding Taobao',
  'app.settings.binding.taobao-description': 'Currently unbound Taobao account',
  'app.settings.binding.alipay': 'Binding Alipay',
  'app.settings.binding.alipay-description': 'Currently unbound Alipay account',
  'app.settings.binding.dingding': 'Binding DingTalk',
  'app.settings.binding.dingding-description': 'Currently unbound DingTalk account',
  'app.settings.binding.bind': 'Bind',
  'app.settings.notification.password': 'Account Password',
  'app.settings.notification.password-description':
    'Messages from other users will be notified in the form of a station letter',
  'app.settings.notification.messages': 'System Messages',
  'app.settings.notification.messages-description': 'System messages will be notified in the form of a station letter',
  'app.settings.notification.todo': 'To-do Notification',
  'app.settings.notification.todo-description':
    'The to-do list will be notified in the form of a letter from the station',
  'app.settings.open': 'Open',
  'app.settings.close': 'Close',
  'app.exception.back': 'Back to home',
  'app.exception.description.403': "Sorry, you don't have access to this page",
  'app.exception.description.404': 'Sorry, the page you visited does not exist',
  'app.exception.description.500': 'Sorry, the server is reporting an error',
  'app.result.error.title': 'Submission Failed',
  'app.result.error.description': 'Please check and modify the following information before resubmitting.',
  'app.result.error.hint-title': 'The content you submitted has the following error:',
  'app.result.error.hint-text1': 'Your account has been frozen',
  'app.result.error.hint-btn1': 'Thaw immediately',
  'app.result.error.hint-text2': 'Your account is not yet eligible to apply',
  'app.result.error.hint-btn2': 'Upgrade immediately',
  'app.result.error.btn-text': 'Return to modify',
  'app.result.success.title': 'Submission Success',
  'app.result.success.description':
    'The submission results page is used to feed back the results of a series of operational tasks. If it is a simple operation, use the Message global prompt feedback. This text area can show a simple supplementary explanation. If there is a similar requirement for displaying “documents”, the following gray area can present more complicated content.',
  'app.result.success.operate-title': 'Project Name',
  'app.result.success.operate-id': 'Project ID：',
  'app.result.success.principal': 'Principal：',
  'app.result.success.operate-time': 'Effective time：',
  'app.result.success.step1-title': 'Create project',
  'app.result.success.step1-operator': 'Qu Lili',
  'app.result.success.step2-title': 'Departmental preliminary review',
  'app.result.success.step2-operator': 'Zhou Maomao',
  'app.result.success.step2-extra': 'Urge',
  'app.result.success.step3-title': 'Financial review',
  'app.result.success.step4-title': 'Finish',
  'app.result.success.btn-return': 'Back to list',
  'app.result.success.btn-project': 'View project',
  'app.result.success.btn-print': 'Print',
  'app.setting.pagestyle': 'Page style setting',
  'app.setting.pagestyle.dark': 'Dark style',
  'app.setting.pagestyle.light': 'Light style',
  'app.setting.content-width': 'Content Width',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.themecolor': 'Theme Color',
  'app.setting.themecolor.dust': 'Dust Red',
  'app.setting.themecolor.volcano': 'Volcano',
  'app.setting.themecolor.sunset': 'Sunset Orange',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Polar Green',
  'app.setting.themecolor.daybreak': 'Daybreak Blue (default)',
  'app.setting.themecolor.geekblue': 'Geek Glue',
  'app.setting.themecolor.purple': 'Golden Purple',
  'app.setting.navigationmode': 'Navigation Mode',
  'app.setting.sidemenu': 'Side Menu Layout',
  'app.setting.topmenu': 'Top Menu Layout',
  'app.setting.fixedheader': 'Fixed Header',
  'app.setting.fixedsidebar': 'Fixed Sidebar',
  'app.setting.fixedsidebar.hint': 'Works on Side Menu Layout',
  'app.setting.hideheader': 'Hidden Header when scrolling',
  'app.setting.hideheader.hint': 'Works when Hidden Header is enabled',
  'app.setting.othersettings': 'Other Settings',
  'app.setting.weakmode': 'Weak Mode',
  'app.setting.copy': 'Copy Setting',
  'app.setting.copyinfo': 'copy success，please replace defaultSettings in src/models/setting.js',
  'app.setting.production.hint': 'Setting panel shows in development environment only, please manually modify',
  'status.active': 'Active',
  'status.airworthy': 'Airworthy',
  'status.approved': 'Approved',
  'status.closed': 'Closed',
  'status.complete': 'Complete',
  'status.completed': 'Completed',
  'status.critical': 'Critical',
  'status.deferralPending': 'Deferral pending',
  'status.draft': 'Draft',
  'status.error': 'Incomplete',
  'status.flightStatus': 'Flight status',
  'status.inFlight': 'In flight',
  'status.inMaintenance': 'In maintenance',
  'status.inProgress': 'In progress',
  'status.inTolerance': 'In tolerance',
  'status.lastKnownLocation': 'Last known location',
  'status.location': 'Location',
  'status.new': 'New',
  'status.notAirworthy': 'Not airworthy',
  'status.on': 'on',
  'status.onGround': 'On ground',
  'status.open': 'Open',
  'status.overdue': 'Overdue',
  'status.partial': 'Partial',
  'status.pendingClose': 'Pending close',
  'status.pendingReminder': 'Pending reminder',
  'status.postflightMissing': 'Postflight missing',
  'status.preflight': 'Preflight done',
  'status.rejected': 'Rejected',
  'status.reported': 'Reported',
  'status.resolved': 'Resolved',
  'status.started': 'Started',
  'status.planned': 'Planned',
  'status.serviceable': 'Serviceable',
  'status.unknown': 'Unknown',
  'status.unserviceable': 'Unserviceable',
  'status.unserviceable_missing_crs': 'Unserviceable - requires {releaseText}',
  'status.unserviceable_missing_crs_short': 'Unserviceable - {releaseText}',
  'menu.dashboard': 'Dashboard',
  'menu.reports': 'Reports',
  'menu.metrics': 'Metrics',
  'menu.map': 'Map',
  'menu.operations': 'Operations',
  'menu.documents': 'Documents',
  'menu.defects': 'Defects',
  'menu.people': 'People',
  'menu.settings': 'Settings',
  'menu.userSettings': 'User Settings',
  'menu.aircraft': 'Aircraft',
  'menu.integrations': 'Integrations',
  'menu.subscriptions': 'Subscriptions',
  'menu.operator': 'Templates',
  'menu.operatorSettings': 'Operator',
  'menu.maintenance': 'Maintenance',
  'menu.totp': 'One-Time Password',
  'message.addActualUplift': 'Please add the actual fuel uplift',
  'message.addAircraft': 'Please select an aircraft',
  'message.addAircraftType': 'Please select an aircraft type',
  'message.addAirframe': 'Please add in airframe hours for the next check',
  'message.addAirframeHours': 'Please add the current airframe hours',
  'message.addApiKey': 'Please add API Key',
  'message.addApiPassword': 'Please add API Password',
  'message.addApuHours': 'Please add the current APU hours',
  'message.addAPUHoursError': 'Please add a number of APU hours',
  'message.addArrivalFuel': 'Please add the arrival fuel',
  'message.addAssembly': 'Please add an assembly',
  'message.addCurrentLandings': 'Please add the current landings',
  'message.addCycles': 'Please add a number of cycles',
  'message.addDeferralDetails': 'Please add deferral details',
  'message.addDefectDetails': 'Please add details of the defect',
  'message.addDefectItem': 'Please select an MEL item to defer the defect',
  'message.generateShareLinkFailed': 'Unable to generate a sharing link',
  'message.addDays': 'Please add a number of days',
  'message.addDeferralPeriod': 'Please add a deferral period',
  'message.addDescription': 'Please add a description',
  'message.addAuthorisationNumber': 'Please add authorisation number',
  'message.addFirstName': 'Please add a first name',
  'message.addItem': 'Please add related item',
  'message.addInCycles': 'Please add in cycles for the next check (if applicable)',
  'message.addLandings': 'Please add a number of landings',
  'message.addLastName': 'Please add a last name',
  'message.addMaxFuelCapacity': 'Please add the max fuel capacity',
  'message.addMaxWeight': 'Please add the max weight',
  'message.addNextCheckApu': 'Please add in hours for the next APU check',
  'message.addNextCheckDate': 'Please add in a date for the next check',
  'message.addPart145Email': 'Please add a {field} email',
  'message.addNotes': 'Please add any notes',
  'message.addOilUplift': 'Please add oil uplift',
  'message.addOperator': 'Please add an operator',
  'message.addOrganisation': 'Please select an organisation',
  'message.addPassword': 'Please add password',
  'message.addPart145Approval': 'Please add a {field} approval no.',
  'message.addPart145Organisation': 'Please add a {field} Organisation name',
  'message.addPartNum': 'Please add a part no.',
  'message.addPlannedUplift': 'Please add the planned fuel uplift',
  'message.addRegistration': 'Please add a registration',
  'message.addRegistrationLength': 'The max length of a registration number is 8 characters',
  'message.addRegistrationCharacters': 'Only alphabetic, numerical and "-" characters are allowed',
  'message.addYear': 'Only numerical characters are allowed',
  'message.addResolutionDate': 'Please add a resolution date',
  'message.resolutionSaveSuccess': 'Resolution saved successfully.',
  'message.resolutionSaveError': "Couldn't save resolution. Please try again later.",
  'message.addSerialNum': 'Please add a serial no.',
  'message.addSignature': 'Please add a signature',
  'message.addSource': 'Please add source',
  'message.addSrpNo': 'Please add an SRP number to start from',
  'message.addStatus': 'Please add status',
  'message.addTaskRef': 'Please add a task',
  'message.addTrip': 'Please add a trip',
  'message.addTicket': 'Add a New Ticket',
  'message.addType': 'Please add a type',
  'message.addUsername': 'Please add username',
  'message.addValidEmail': 'Please input user email!',
  'message.addWholeNumbers': 'Please add a whole and positive number',
  'message.addYearOfManufacture': 'Please add a year of manufacture',
  'message.addYourOprId': 'Please add yourOprId',
  'message.addedToSystem': 'has been added to the system',
  'message.addOperatorReference': 'Please add operator reference',
  'message.aircraftUpdated': 'Aircraft Updated',
  'message.aThumbnailNotComplete': 'A thumbnail is not complete',
  'message.approveItemsSuccess': 'Items successfully approved',
  'message.approveMultiItemsSuccess': '{field} Items successfully approved',
  'message.approveItemSuccess': 'Item successfully approved',
  'message.approveItemsError': 'An error occured, please try again',
  'message.canvasEmpty': 'Canvas is empty',
  'message.changesNotSaved': 'Changes have not been saved',
  'message.clearOverrideNotice':
    'To remove the override value and return to the calculated value, please delete the entered value',
  'message.crewMemberAdded': 'Crew member added',
  'message.crewMemberRemoved': 'Crew member removed',
  'message.crewMembersRemoved': 'Crew members removed',
  'message.csvGenerated': 'Your CSV is being generated, you will receive an email shortly',
  'message.amountMustBePositive': 'Amount must be a positive number',
  'message.amountMustBeANumber': 'Amount must be a number',
  'message.dateRequired': 'Please select a date',
  'message.defectUpdated': 'Defect updated successfully',
  'message.defectUpdateFail': 'Defect update failed',
  'message.defectDeleted': 'Defect deleted successfully',
  'message.downloadFail': 'Download Failed',
  'message.workpackDeleted': 'Workpack deleted successfully',
  'message.editTrip': 'Edit trip',
  'message.editAircraftError': 'Unable to update aircraft',
  'message.errorWhileLoadingMapScript': 'An error has occurred while loading map script. Please reload the page.',
  'message.emailHasBeenSent': 'An email has been sent to',
  'message.emailInvalid': 'The input is not a valid email address.',
  'message.emailIsAlreadyUsed': 'This email is already in use. Please choose another',
  'message.filePreviewFailed': 'Failed to load file preview.',
  'message.failedToUpdateUser': 'Failed to update user.',
  'message.fileIsEmpty': 'Selected file is empty.',
  'message.fuelWarning': 'Fuel carried forward cannot exceed aircraft fuel capacity',
  'message.generateDocumentError': 'There was an error generating your document',
  'message.hasApu': 'Please select if this aircraft has an APU',
  'message.hobbsField': 'Please select whether to show a Hobbs field',
  'message.invalidDate': 'Please enter a valid date',
  'message.linkCopied': 'Link copied to clipboard',
  'message.maximumFuel': 'Please add a maximum fuel value',
  'message.melIsRequired': 'MEL file is required',
  'message.newAircraftAdded': 'New aircraft user added successfully',
  'message.newUserAdded': 'New user added successfully',
  'message.userAlreadyExists': 'User already exists with this email address.',
  'message.addUserFail': 'Failed to add user',
  'message.noAircraftSelected': 'No aircraft selected',
  'message.noCallsignSelected': 'No callsign entered',
  'message.noPilotInCommandSelected': 'No Pilot in Command selected',
  'message.noDateSelected': 'No date selected',
  'message.noFilePreview': 'No preview available.',
  'message.noInformationProvided': 'No information provided',
  'message.nothingToSave': 'There is nothing to save',
  'message.oilUnit': 'Please choose the units used for oil',
  'message.oneItemMustBeResolvedOrDeferred': 'Please resolve or defer at least one item in the workpack',
  'message.onFileSuccess': 'File uploaded successfully',
  'message.onFileUpdate': 'File updated successfully',
  'message.onFileFailed': 'File upload failed',
  'message.onFileDoesNotExist': 'File Does Not Exist',
  'message.permissionsUpdated': 'Permissions updated successfully',
  'message.personRemoved': 'Person removed',
  'message.onePersonRemoved': 'person removed',
  'message.peopleRemoved': 'people removed',
  'message.personUpdatedSuccess': 'Person updated successfully',
  'message.pinMustBe6Digits': 'Please enter a PIN 6 digits long',
  'message.pleaseDescription': 'Please include a description',
  'message.pleaseSaveExistingWork': 'Please save existing work first and try again',
  'message.previousArrival': 'Previous arrival destination ({field})',
  'message.doesntMatchDeparture': "doesn't match selected departure airport ({field})",
  'message.previousAirportNotMatch': "Previous arrival destination doesn't match selected departure airport",
  'message.referenceRequired': 'Please enter a reference',
  'message.poRequired': 'Please enter {po} {suffix}',
  'message.resolutionType': 'Please include a resolution type',
  'message.roleRequired': 'Please select a role',
  'message.saving': 'Saving.....',
  'message.selectApuTotal': 'Please enter the APU hours',
  'message.selectArea': 'Please select area',
  'message.selectArrivalAirport': 'Please select an arrival airport',
  'message.selectATAChapter': 'Please select an ATA Chapter',
  'message.selectDeferred': 'Please select if the defect is to be deferred',
  'message.selectDepartureAirport': 'Please select a departure airport',
  'message.selectLanding': 'Please enter the landing time',
  'message.selectLandings': 'Please enter the no. of landings',
  'message.selectModel': 'Please select a model!',
  'message.selectOffBlocks': 'Please enter the off blocks time',
  'message.selectOnBlocks': 'Please enter the on blocks time',
  'message.selectOperator': 'Please select operator!',
  'message.selectTakeOff': 'Please enter the takeoff time',
  'message.selectTicketTitle': 'Please input ticket title',
  'message.selectTicketText': 'Please input ticket text',
  'message.selectTicketAircraft': 'Please select aircraft',
  'message.selectTicketGroup': 'Please select group',
  'message.selectUnit': 'Please choose the units used for fuel',
  'message.selectUserRole': 'Please select user role!',
  'message.successfulSubmission': 'Successful Submission',
  'message.titleMustBeAdded': 'Title must be added',
  'message.sectorRemoved': '{sector} removed',
  'message.sectorRemoveFailed': 'Failed to remove {sector}',
  'message.tripRemoved': 'Trip removed',
  'message.tripRemovalFailed': 'Failed To Remove Trip',
  'message.tripUpdateRemovalFailed': 'Failed To Remove Trip Update',
  'message.tripUpdateRemoved': 'Trip update removed',
  'message.tripNumberBlank': 'Please enter trip number',
  'message.tripSRPNumberBlank': 'Please enter SRP number',
  'message.tripNumberZero': 'Please enter number greater than zero',
  'message.tripUpdatedSuccess': 'Trip updated successfully',
  'message.twoDecimalPlacesMax': 'Number must be positive, no larger than 99999.99 and 2 decimal places at most',
  'message.twoDecimalPlacesMax99': 'Number must be positive, no larger than 99.99 and 2 decimal places at most',
  'message.operatorNotSelected': 'Please select an operator first',
  'message.unresolvedItemsMoved': 'Unresolved items were moved to a new workpack',
  'message.unsupportedFileType': 'Unsupported file type, please select correct file and retry!',
  'message.upliftSuccess': 'Uplift updated successfully',
  'message.uploadMel': 'Please upload the MEL PDF file for the aircraft',
  'message.waitForUpload': 'Please wait for upload to complete',
  'message.warningDeicingTime': 'Time between start and end of deicing is',
  'message.warningDeicingTimeBefore': 'Time between deicing and takeoff is',
  'message.warningDeicingTimeAfter': 'Time is after takeoff.',
  'message.warningOnBlocks': 'Time between landing and blocks-on is more than 30 minutes',
  'message.warningTakeOff': 'Time between blocks-off and takeoff is more than 30 minutes',
  'message.wholeNumbers': 'Number can only be whole positive number no larger than 99999',
  'message.workpackAlreadyExists': 'Workpack already exists',
  'message.workpackUpdated': 'Workpack updated',
  'message.workpackAdded': 'Workpack successfully added',
  'message.oneItemApproved': 'item approved',
  'message.onResetPasswordSuccess': 'Password E-mail sent',
  'message.onResetPasswordFailed': 'Failed to send email',
  'message.onResetPINSuccess': 'PIN E-mail sent',
  'message.onResetPINFailed': 'Failed to send email',
  'message.itemApproved': 'Item approved',
  'message.itemsApproved': 'items approved',
  'message.oneItemDeleted': 'item deleted',
  'message.itemsDeleted': 'items deleted',
  'message.itemDeleted': 'Item deleted',
  'message.userDeleted': 'User deleted',
  'message.userDeletedFailed': 'Failed to delete user',
  'message.oneSubscriptionDeleted': 'subscription deleted',
  'message.subscriptionsDeleted': 'subscriptions deleted',
  'message.oneDamageReportDeleted': 'Damage Report deleted',
  'message.damageReportsDeleted': 'Damage Reports deleted',
  'message.subscriptionDeleted': 'Subscription deleted',
  'message.subscriptionExists': 'Subscription already exists',
  'message.subscriptionUsesFilter': 'Another subscription of this type already includes 1 or more aircraft ids',
  'message.subscriptionUpdated': 'Subscription updated',
  'message.titleNotUnique': 'The title must be unique',
  'message.imageMustBeAdded': 'An image must be added',
  'message.subscriptionCreated': 'Subscription created',
  'message.oneDocumentDeleted': 'document deleted',
  'message.documentsDeleted': 'documents deleted',
  'message.documentDeleted': 'Document deleted',
  'message.missingField': 'Please fill in {field}',
  'message.wrongEmail': 'Please enter a valid email address',
  'message.tooLongNumber': 'Please input a number with less than 10 digits',
  'message.noLimitations': 'No limitations on this aircraft.',
  'message.ticketClosed': 'Ticket closed',
  'message.ticketUpdateFailed': 'Unable to update ticket',
  'message.addArticleError': 'Unable to add comment',
  'message.addTicketError': 'Unable to create ticket',
  'message.addToWorkpackError': 'Unable to add to workpack',
  'message.fillComment': 'Please fill in comment text',
  'message.invalidFileType': 'Invalid file format, please retry.',
  'message.noAirports': 'No Airports Found',
  'message.noAircraft': 'No Aircraft Found',
  'message.emptyActiveWorkPack': 'Active Workpack must have at least 1 item',
  'message.unableToChangeFileName': 'Unable to change this file name',
  'message.pleaseWaitTillFinish': 'Please wait for form upload completion in order to close',
  'message.noAircraftFoundCriteria': 'No aircraft found for the specified criteria',
  'message.selectResolutionType': 'Please select resolution type',
  'message.detailsResolution': 'Please add resolution details',
  'message.setLimitations': 'Please set or discard limitations',
  'message.limitationsDetails': 'Please input limitations details',
  'message.failedToFetchUser': 'Failed to fetch user',
  'message.fillInNumber': 'Please fill in number',
  'message.resolutionDetailsSaved': 'Resolution details saved!',
  'message.pleaseSaveResolution': 'Please save opened resolution(s) first',
  'message.melRemoved': 'MEL removed',
  'message.melCreated': 'MEL created',
  'message.melDefectsMustBeDeferred': 'MEL Defects Must Be Deferred',
  'message.melCantCreate': 'Could not create MEL or it already exists',
  'message.melSettingsSaved': 'MEL Settings saved',
  'message.masterMelSettingsSaved': 'MMEL Settings saved',
  'message.melChapterSaved': 'MEL chapter saved',
  'message.masterMelChapterSaved': 'MMEL chapter saved',
  'message.melProblemSave': 'There was a problem saving MEL',
  'message.melFileTooLarge': 'The file size exceeds the limit of 20 MB',
  'message.melSectionRemoved': 'Section removed',
  'message.newItems': 'New items',
  'message.cantCreateUser': "User can't be created",
  'message.deselectItems': 'Please deselect items before closing their category',
  'message.draftItemsImported':
    'Draft Items were imported. Review imported items to add them to the maintenance timeline.',
  'message.draftItemImported':
    'Draft Item was imported. Review imported items to add them to the maintenance timeline.',
  'message.duplicates': 'Duplicates',
  'message.deselectDrafts': 'Please deselect draft items to select items for workpack',
  'message.deselectActive': 'Please deselect active items to select drafts to import',
  'message.resolvedItemsNotSelectable': 'Resolved items are not able to be added to a workpack',
  'message.removedItems': 'Removed items',
  'message.startReviewing': 'Start reviewing',
  'message.rejectItemSuccess': 'Items successfully rejected',
  'message.rejectMultiItemsSuccess': '{field} Item successfully rejected',
  'message.rejectItemsError': 'An error occured, please try again',
  'message.workpackCreateSuccess': 'Workpack successfully created!',
  'message.workpackCreateError': "Couldn't create workpack",
  'message.workpackUpdateSuccess': 'Workpack successfully updated!',
  'message.workpackUpdateError': "Couldn't update workpack",
  'message.updatedItems': 'Updated items',
  'message.mxItemUpdated': '{name} successfully updated!',
  'message.failedToUpdateMXItem': 'Failed to update MX item',
  'message.mxItemCreated': '{name} successfully created!',
  'message.noToleranceAppliedByUser': "You haven't applied any tolerances",
  'message.openComputer': 'For full experience, please open this page on tablet or computer.',
  'message.fillInDueValue': 'Please fill in at least one Due value',
  'text.ago': 'ago',
  'text.attachments': 'Attachments',
  'text.atleast1ItemAppearsInWorkpack': 'At least 1 of the selected items already appears in this workpack',
  'text.accept': 'Accept',
  'text.acceptAll': 'Accept All',
  'text.accessToAircraftRestricted': 'Access to this aircraft is restricted to admins. Please contact an admin if you need access.',
  'text.account': 'Account',
  'text.accountSettings': 'Account Settings',
  'text.accountSettings.changePasswordInfo':
    'If you would like to change your password, please click on the button below for full instructions.',
  'text.accountSettings.changePinInfo':
    'Your pin is used to ensure details are correctly saved against actions on the iPad Application. Update your pin using the link below',
  'text.accountSettings.changePassword': 'Change password',
  'text.accountSettings.changePin': 'Change PIN',
  'text.accountSettings.complete': 'Complete',
  'text.accountSettings.dateFormat': 'Date Format',
  'text.accountSettings.dateFormatUpdated': 'Date Format Successfully Updated',
  'text.accountSettings.DayMonthYear': 'DD-MM-YYYY',
  'text.accountSettings.DayMonthYearExpanded': 'DD-MMM-YYYY',
  'text.accountSettings.display': 'Display',
  'text.accountSettings.fileSizeWarning': 'Please upload a file of size 1 megabyte or smaller!',
  'text.accountSettings.imageTypes': 'Image files must be JPG, PNG or SVG!',
  'text.accountSettings.imageNotPresent': 'Image is required',
  'text.accountSettings.MonthDayYear': 'MM-DD-YYYY',
  'text.accountSettings.navigation': 'Navigation',
  'text.accountSettings.passwordManagement': 'Password Management',
  'text.accountSettings.profilePicture': 'Profile Picture',
  'text.accountSettings.side': 'Side',
  'text.accountSettings.top': 'Top',
  'text.accountSettings.updateImage': 'Update image',
  'text.accountSettings.uploadImage': 'Upload image',
  'text.accountSettings.uploadProfileImage': 'Upload a profile image',
  'text.accountSettings.userManagement': 'User Management',
  'text.accountSettings.uploadProfileSuccessful': 'Profile image successfully uploaded',
  'text.accountSettings.YearMonthDay': 'YYYY-MM-DD',
  'text.accountSettings.YearMonthDayExpanded': 'YYYY-MMM-DD',
  'text.active': 'Active',
  'text.activeLastUpdated': 'Active - last updated',
  'text.actual': 'Actual',
  'text.actualArrival': 'Actual Arrival',
  'text.actualFuelBurn': 'Actual Fuel Burn',
  'text.actualUplift': 'Actual Uplift',
  'text.add': 'Add',
  'text.addCabinIssue': 'Add cabin issue',
  'text.addChapter': 'Add Chapter',
  'text.addCustomField': 'Add custom field',
  'text.addDefect': 'Add {field}',
  'text.addDeferral': 'Add deferral',
  'text.addedBy': 'Added by',
  'text.addAircraftFailed': 'Adding aircraft user has failed',
  'text.addAircraftSuccess': 'Adding aircraft user has succeeded',
  'text.addASingleChoice': 'Add a single-choice answers to this question',
  'text.additionalCrew': 'Additional Crew',
  'text.additionalLimits': 'Additional limits',
  'text.additionalValues': 'Additional Values',
  'text.addNewCustomField': 'Creating new custom field',
  'text.addNewFRAT': 'Add new FRAT',
  'text.addNewFRATName': 'Name',
  'text.addNewFRATQuestion': 'Add new question',
  'text.addNewItem': 'Add new item',
  'text.addNewTripUpdate': 'Add new',
  'text.address': 'Address',
  'text.addUplift': 'Add uplift',
  'text.adjustment': 'Adjustment',
  'text.aircraft': 'Aircraft',
  'text.aircraftLocked': 'Aircraft locked',
  'text.aircraftWeight': 'Aircraft weight',
  'text.aircraftCreationError': 'Aircraft creation error',
  'text.aircraftExist': 'Aircraft already exists',
  'text.aircraftFRAT': 'Aircraft FRAT',
  'text.aircraftHours': 'Aircraft Hours',
  'text.aircraftInSetupMode': 'Aircraft in setup mode',
  'text.aircraftSerial': 'Aircraft Serial',
  'text.aircraftTotalsVisibilitySavedSuccessfully': 'Aircraft totals visibility saved successfully',
  'text.aircraftUnlockText':
    'This aircraft will be unlocked and changes will be possible on the aircraft again. Do you wish to continue?',
  'text.aircraftUserPassword':
    'This aircraft user password is only visible now and once this window is closed it will be invisible. Please take note of the password, or reset password.',
  'text.allowedRectificationIntervals':
    'The allowed rectification intervals are listed Remarks column for the MEL condition. Please enter all that apply',
  'text.allowMissingMxCheck': 'Allow missing mx check when only requiring erops',
  'text.amberWarningOverride': 'Amber warning override',
  'text.amendAtaDetailsPublic': 'Amend ATA Details on Public Page',
  'text.amendAtaDetailsPublicTooltip': 'Allows ATA amendments on the public page when resolving a defect',
  'text.amosUserSign': 'AMOS User Sign',
  'text.anyOtherInfo': 'Any other information',
  'text.applyDefectExtension': 'Apply an extension to this {field}?',
  'text.applyTolerance': 'Apply tolerance',
  'text.applyToleranceSubmit': 'Apply tolerance and submit',
  'text.apu': 'APU',
  'text.apuThreshold': 'APU Threshold',
  'text.airport': 'Airport',
  'text.aocNumber': 'AOC Number',
  'text.apuHours': 'APU hrs',
  'text.apuHoursLeft': 'APU hours left',
  'text.apuHoursLimit': 'APU Hours Limit',
  'text.apuHoursNotSet': 'APU hrs - not set',
  'text.approachAndLanding': 'Approach and Landing',
  'text.approve': 'Approve',
  'text.approvalConfirmText': 'Are you sure you want to approve this deferral?',
  'text.approvalNumber': 'Approval Number',
  'text.approvalRelease': 'Approval Release',
  'text.approvalReleaseMode': 'Approval Release Mode',
  'text.approvalReleaseModeTooltip':
    'Auto release - Release is automatic after each sign off. \n \n After any work completed - Release is required after each sign off. \n \n After approval release required - Release is required for specific items',
  'text.approvalToDeferCaps': 'APPROVAL TO DEFER',
  'text.area': 'Area',
  'text.archive': 'Archive',
  'text.archivingAircraft':
    'Archiving an aircraft will retain it on your account, but remove it from the active fleet.',
  'text.areEROPSRequired': 'Are {field} checks required?',
  'text.areThereAnyLimitations': 'Are there any limitations?',
  'text.areYouSureAPUMode': 'Are you sure you want to change the APU Mode?',
  'text.arrival': 'Arrival',
  'text.asWithAllAI': 'As with all AI, please double check important information',
  'text.ata': 'ATA',
  'text.ataError': 'ATA error',
  'text.ataCannotBe0': 'ATA Chapter cannot be 0',
  'text.ataCannotBeEmpty': 'ATA Chapter cannot be empty',
  'text.attachmentProblem': 'There is a problem with this attachment',
  'text.aNew': 'a new',
  'text.anUpdated': 'an updated',
  'text.apuUplift': 'APU Uplift',
  'text.aRemoved': 'a removed',
  'text.basicAircraftInfo': 'Basic Aircraft Info',
  'text.backToAllItems': 'Back to all items',
  'text.billingStatusTooltip':
    'Active: The aircraft will be available on Techlog and billable. Setup: The aircraft will only become available on Techlog once it is made active',
  'text.blocksOff': 'Blocks off',
  'text.blocksOn': 'Blocks on',
  'text.blocksTime': 'Blocks Time',
  'text.bowser': 'Bowser',
  'text.bowserUplift': '{field} Uplift',
  'text.bugUplift': 'BUG Uplift',
  'text.calendarDaysLimit': 'Calendar Days Limit',
  'text.calendarHoursLimit': 'Calendar Hours Limit',
  'text.calculated': 'Calculated',
  'text.capabilities': 'Capabilities',
  'text.callsign': 'Callsign',
  'text.canAddCRS': 'Can add CRS',
  'text.canAddIssue': 'Can add Issue',
  'text.canAddMXCheck': 'Can add MX Check',
  'text.canAddUplift': 'Can add Uplift',
  'text.cancel': 'Cancel',
  'text.cancelDeferral': 'Cancel deferral',
  'text.canCloseTrip': 'Can close trip',
  'text.canSignPreflight': 'Can sign preflight',
  'text.canSignPostflight': 'Can sign postflight',
  'text.category': 'Category',
  'text.categoryALimitsError': 'Category A limits error',
  'text.categoryALimits': 'Category A defects must have at least one none zero limit specified',
  'text.categoryNotSet': 'Category not set',
  'text.centrikIntegration': 'Document management and ASR Reporting.',
  'text.close': 'Close',
  'text.defectProcessApprovalMethods': 'Defect Process Approval Methods',
  'text.defectProcessApprovalMethodsTooltip': 'Approval method for selected defect processes',
  'text.defectProcessesRequiringApproval': 'Defect Processes Requiring Approval',
  'text.defectProcessesRequiringApprovalTooltip': 'Defect processes that need to be approved',
  'text.draftItemsImported':
    'Draft items were imported. Please review the items below to add them to the maintenance timeline.',
  'text.draftItemImported':
    'Draft item was imported. Please review the item below to add it to the maintenance timeline.',
  'text.campIntegration': 'Automatically synchronise aircraft due list, discrepancies and actuals.',
  'text.carriedForward': 'CARRIED FORWARD',
  'text.broughtForwardFromPrevious': 'Brought forwards',
  'text.clickOrDragFile': 'Click or drag file to this area to upload.',
  'text.clickToAdd': 'Click to add',
  'text.copiedToClipboard': 'Copied to clipboard',
  'text.correctErrors': 'Please complete required fields.',
  'text.uploadSchematicImage':
    'Drag and drop your file and fill in the details. You can repeat the action for multiple views',
  'text.closeIcon': 'close icon',
  'text.clickingHere': 'clicking here',
  'text.closeTicket': 'Close ticket',
  'text.comments': 'Comments',
  'text.configurationDeviationList': 'Configuration Deviation List',
  'text.confirmationReportText':
    'I confirm that to the best of my knowledge the information submitted is complete and correct.',
  'text.confirmReviewedAndActioned': 'Confirm you have reviewed and actioned the below',
  'text.confirmYes': 'Yes',
  'text.confirmNo': 'No',
  'text.contact': 'Contact support@trustflight.com',
  'text.contactToDisable': 'Contact support@trustflight.com to disable',
  'text.createdBy': 'Created By',
  'text.createManualTripUpdates': 'Create manual trip updates',
  'text.crsAcceptance': '{field} ACCEPTANCE',
  'text.crsLong': 'Crs Long',
  'text.crsShort': 'Crs Short',
  'text.crsSignoffCrewDetails': 'Crs signoff crew details',
  'text.crsSignoff': 'CRS signoff',
  'text.cyclesLimit': 'Cycles Limit',
  'text.individualThresholds': 'Individual thresholds',
  'text.individualThresholdsTooltip':
    'Override the default value for when the maintenance item changes from green to amber.',
  'text.insertAfter': 'Insert after',
  'text.insertBefore': 'Insert before',
  'text.inspectorSignoff': 'Inspector Signoff',
  'text.inTolerance': 'In Tolerance Items',
  'text.crop': 'Crop your image',
  'text.cumulative': 'Cumulative',
  'text.current': 'Current',
  'text.customFields': 'Custom Fields',
  'text.customFieldDetailsAreAsFollows': 'The custom field details are as follows:',
  'text.customFieldPrompt1': 'What kind of custom fields can I create?',
  'text.customFieldPrompt2': 'Create a number field in the preflight section',
  'text.customLocation': 'Custom location',
  'text.customFieldPrompt3': 'Create a dropdown field for a new trip',
  'text.customSettings': 'Custom settings',
  'text.cycles': 'Cycles',
  'text.cyclesLeft': 'Cycles left',
  'text.cyclesPerHour': 'Cycles per Hour',
  'text.cph': 'HpC',
  'text.changes': 'Changes',
  'text.damageMaps': 'Dent & Buckle',
  'text.dataFetchError': 'Data fetch error',
  'text.dataTypeEnteredIntoField': 'The data type to be entered into the field',
  'text.date': 'Date',
  'text.dateAdded': 'Date added',
  'text.dateCreated': 'Date Created',
  'text.dateModified': 'Date modified',
  'text.dateRecorded': 'Date Recorded',
  'text.dateRecordedCaps': 'DATE RECORDED',
  'text.dateDue': 'Date Due',
  'text.dateDueCaps': 'DATE DUE',
  'text.dateFormatUpdated': 'Date Format updated successfully',
  'text.dateResolved': 'Date Resolved',
  'text.dateClosed': 'Date Closed',
  'text.dateOpened': 'Date Opened',
  'text.dateCompleted': 'Date Completed',
  'text.dateCompletedCaps': 'DATE COMPLETED',
  'text.dayAgo': 'day ago',
  'text.daysAgo': 'days ago',
  'text.day': 'Day',
  'text.days': 'Days',
  'text.daysLeft': 'Days left',
  'text.daysThreshold': 'Days threshold',
  'text.default': 'Default',
  'text.defaultDateFormat': 'Default date format',
  'text.defaultImage': 'default image',
  'text.defineMel': 'Define the MEL settings below.',
  'text.defineSubscription': 'Define your subscription settings below to meet your specific needs.',
  'text.delete': 'Delete',
  'text.deleteCabinIssue': 'Delete Cabin Issue',
  'text.deleteCategory': 'Delete category',
  'text.deleteChapter': 'Delete Chapter',
  'text.deleteCategoryAreYouSure': 'Are you sure you want to delete this category?',
  'text.deleteConfirm': 'Are you sure you want to delete this item? This action cannot be undone.',
  'text.deleteConfirmMulti': 'Are you sure you want to delete these items? This action cannot be undone.',
  'text.tripDeletionFailed': 'Trip deletion failed',
  'text.tripCouldNotBeDeleted': 'Trip could not be deleted as it has an associated {field}.',
  'text.tripUpdateDeletionFailed': 'Trip update deletion failed',
  'text.tripUpdateCouldNotBeDeleted': 'Trip update could not be deleted as it has an associated {field}.',
  'text.deleteMel': 'Delete MEL',
  'text.deleteMMEL': 'Delete MMEL',
  'text.dentAndBuckle': 'Dent & Buckle',
  'text.remoteDeicing': 'Remote Deicing',
  'text.hideAddWorkpackButton': 'Hide add workpack button',
  'text.hideAndAutofillUsernameAndEmployeeNumber': 'Hide and autofill username and employee number',
  'text.hideReportByCabin': 'Hide report by cabin',
  'text.hidePart145Email': 'Hide part 145 email',
  'text.hidePart145EmailTooltip': 'Hides the part 145 email on the CRS page',
  'text.hideSRPLinks': 'Hide SRP links',
  'text.removeOption': 'Remove option',
  'text.removeTimeDue': 'Remove time due',
  'text.ddl': 'DDL',
  'text.ddlShort': 'DDL short',
  'text.ddlNumbering': 'DDL Numbering',
  'text.ddlIncludesResolved': 'DDL Includes Resolved Defects',
  'text.disableLink': 'Disable',
  'text.dispatchConditions': 'Dispatch Conditions:',
  'text.displayedAboveFieldOnIpad': 'Displayed above the field on the iPad',
  'text.displayedImage': 'displayedImage',
  'text.displayedOnlyTheseOptions': 'Displayed only on these options',
  'text.displayLastCRS': 'Display last CRS',
  'text.displayOnTheLeftSideOfIpad': 'Displayed on the left side of the row on the iPad',
  'text.displayPreviousValue': 'Display previous value',
  'text.displayRightOfTheField': 'Displayed to the right of the field',
  'text.displaysDataEnteredOnPreviousFlight':
    'Displays the data entered on the previous {sector}, if nothing was entered nothing will be displayed. Displayed below the field',
  'text.daysRemaining': 'Days Remaining',
  'text.defectConfiguration': 'Defect Configuration',
  'text.defects': 'Defects',
  'text.defectsReported': 'Defects Reported',
  'text.def': 'DEF',
  'text.defect': 'Defect',
  'text.defectDeferral': 'Defect Deferral',
  'text.defectExtensionNoDateDue': 'No due date has been set for this {field} and so this {field} will not expire',
  'text.defectPlural': 'Defect Plural',
  'text.defectShort': 'Defect Short',
  'text.defectWillNotExpire':
    'You have applied an extension to this defect with no due date. This defect will not expire.',
  'text.defectType': 'Defect Type',
  'text.defectTypeCaps': '{field} TYPE',
  'text.defectTypesOnDDL': 'Defect types on DDL',
  'text.deferUsingDefaultCannotUnselect': 'Defer using default option cannot be unselected',
  'text.deferUntil': 'DEFER UNTIL',
  'text.deferUsing': 'Defer using',
  'text.deferUsingDefault': 'Defer using default',
  'text.deferUsingOptions': 'Defer using options',
  'text.deferral': 'Deferral',
  'text.deferralCaps': 'DEFERRAL',
  'text.deferralDetails': 'Deferral details',
  'text.deferralEmployeeNumber': 'Deferral employee number',
  'text.deferralInterval': 'Deferral Interval',
  'text.deferralLicenceNumberRequired': 'Deferral licence number required',
  'text.deferralOptions': 'Deferral Options',
  'text.deferralOptionsCaps': 'DEFERRAL OPTIONS',
  'text.deferralPeriod': 'Deferral Period',
  'text.deferralPending': 'Deferral pending',
  'text.deferralSignoff': 'Deferral Signoff',
  'text.deferralApprovalReference': 'Deferral approval reference',
  'text.deferralApprovalReferenceError': '{field} error',
  'text.deleteAircraft': 'Delete aircraft',
  'text.deletingAircraft':
    'Deleting an aircraft will permanently remove it from your account, along with all associated data. This option can only be used for Demo aircraft.',
  'text.departure': 'Departure',
  'text.description': 'Description',
  'text.details': 'Details',
  'text.documentCategory': 'Document category',
  'text.documentConfiguration': 'Document configuration',
  'text.documentOperator': 'Operator name',
  'text.documents': 'Documents',
  'text.document': 'Document',
  'text.documentValidRange': 'Document valid range',
  'text.doNotDefer': 'Do Not Defer',
  'text.dropdownIcon': 'dropdown icon',
  'text.dragAndDrop': 'Drag and drop your file.',
  'text.dragDropFile': 'Drag and drop files here or ',
  'text.draftQueueSummaryFor': 'Draft Queue Summary for ',
  'text.duplicate': 'Duplicate',
  'text.duplicateItems': 'Duplicate Items',
  'text.draftRepeats': 'Draft Repeats',
  'text.browse': 'browse',
  'text.due': 'Due',
  'text.duration': 'Duration',
  'text.enableFrat': 'Enable FRAT',
  'text.enableMaxWeight': 'Enable max weight',
  'text.enrouteClimb': 'Enroute Climb',
  'text.edit': 'Edit',
  'text.editDeferral': 'Edit deferral',
  'text.editFRATQuestion': 'Edit question',
  'text.editItem': 'Edit Item',
  'text.editIcon': 'edit icon',
  'text.editPin': 'Edit PIN',
  'text.editMel': 'Edit the settings of this MEL',
  'text.editOperatorSettings': 'Edit operator settings',
  'text.editWorkpack': 'Use this page to update this workpack, composed of all relevant defects and maintenance items.',
  'text.employeeIdentifier': 'Employee Identifier',
  'text.employeeNumber': 'Employee Number',
  'text.end': 'End',
  'text.copyEmailSent': '(If entered a copy of the SRP will be sent to the email address below)',
  'text.engines': 'Engines',
  'text.engineer': 'Engineer',
  'text.engineerFirstName': '{field} First Name',
  'text.engineerLastName': '{field} Last Name',
  'text.engineerLicenceRequired': 'Engineer Licence Required',
  'text.engineerLicenceNumber': 'Engineer Licence Number',
  'text.engineUplift': 'Engine Uplift',
  'text.engShort': 'Eng',
  'text.engine': 'Engine',
  'text.e1': 'E1',
  'text.engine1': 'Engine 1',
  'text.e2': 'E2',
  'text.engine2': 'Engine 2',
  'text.e3': 'E3',
  'text.engine3': 'Engine 3',
  'text.e4': 'E4',
  'text.engine4': 'Engine 4',
  'text.engineThreshold': 'Engine Threshold',
  'text.engineeringWork': 'ENGINEERING WORK',
  'text.enterLeonOperatorId':
    'Each operator in Leon is given dedicated domain which is used to sign in to the system. For example our demo operator is given demo.leon.aero domain. The yourOprId is the first segment of the domain. So in case of demo operator oprId is demo. If you are not sure what is yourOprId just ask any user what www address it uses to sign in to the Leon.',
  'text.enterRocketrouteDetails': 'Enter your RocketRoute details below to link your account with the Tech Log',
  'text.enterFl3xxDetails': 'Enter your Fl3xx details below to link your account with the Tech Log',
  'text.enterRusadaDetails': 'Enter your Rusada details below to link your account with the Tech Log',
  'text.enterPONumber': 'Enter {po} {suffix}',
  'text.enterTraxxallDetails': 'Enter your Traxxall details below to link your account with the Tech Log',
  'text.enterValue': 'Enter value',
  'text.errorCreatingDefect': 'Error creating new defect',
  'text.errorOccured': 'Error, occured, please try again',
  'text.errorOccuredTryAgain': 'Error, occured, please try again',
  'text.errorUnableToConnect': 'Error - unable to connect',
  'text.errorUpdatingCabinIssue': 'Error updating cabin issue',
  'text.errorDeletingCabinIssue': 'Error deleting cabin issue',
  'text.errorAddingCabinIssue': 'Error adding cabin issue',
  'text.erops': 'EROPS',
  'text.eropsWarning': 'This will flag the aircraft as not currently capable of {field}',
  'text.estimatedShort': 'Est',
  'text.estimatedUpliftShort': 'Est. Uplift',
  'text.every': 'Every',
  'text.exceptEROPS': '{field} {sectors} Prohibited',
  'text.extendedOn': 'Extended On',
  'text.extensionAdded': 'Extension Added',
  'text.extensionDue': 'New Due Date',
  'text.fieldDataDisplayedOn': 'The field the data will be displayed on',
  'text.files': '.JPEG .JPG .PNG',
  'text.formatting': 'Formatting',
  'text.formCannotBeBlank': 'Form cannot be blank',
  'text.formError': 'Form Error',
  'text.pdfFiles': '.PDF .JPG .PNG',
  'text.failedToUpdateTrip': 'Failed To Update Trip',
  'text.failedToCreateMxItem': 'Failed to create Mx Item',
  'text.failedToCreatePublicUrl': 'Failed to create public URL',
  'text.failedToGetCRS': 'Failed to get {field} page',
  'text.favourites': 'Favourites',
  'text.tripDateChanged':
    'Are you sure you wish to change the trip date? This will cause inconsistencies with the {sector} times, which will need to be manually updated',
  'text.tripUpdated': 'Trip Updated',
  'text.completeTrip': 'Complete Trip',
  'text.confirmCompleteTrip': 'Are you sure you want to complete this trip? This action cannot be undone.',
  'text.tripCompleted': 'Trip Completed',
  'text.problemCompletingTrip': 'There was a problem completing the trip',
  'text.filledFlightPlans': 'Filed flight plans, crew details & aircraft details.',
  'text.fileReadyInfo': 'File uploaded. Hit "Complete" to save the file details.',
  'text.fileSizeLimit': 'It should not exceed 1MB',
  'text.firstName': 'First Name',
  'text.fleetStyleUpdated': 'Fleet page style updated',
  'text.flightConfiguration': 'Flight Configuration',
  'text.flightCycles': 'Flight Cycles',
  'text.flightCyclesCap': 'FLIGHT CYCLES',
  'text.flightCyclesLimit': 'Flight Cycles Limit',
  'text.flightCyclesNotSet': 'Flight Cycles: not set',
  'text.flightDaysLimit': 'Flight Days Limit',
  'text.flightHours': 'Flight Hrs',
  'text.flightHoursCap': 'FLIGHT HOURS',
  'text.flightHoursLimit': 'Flight Hours Limit',
  'text.flightHoursNotSet': 'Flight Hrs: not set',
  'text.flightLimit': 'Flight Limit',
  'text.flightMonthLimit': 'Flight Month Limit',
  'text.flightTime': 'Flight Time',
  'text.fluidType': 'Fluid Type',
  'text.flightRiskAssessment': 'Flight Risk Assessment',
  'text.flightRiskAssessmentTool': 'Flight Risk Assessment Tool (FRAT)',
  'text.frat': 'FRAT',
  'text.fratResult': 'FRAT Result',
  'text.from': 'From',
  'text.fuel': 'Fuel',
  'text.fuelBurnEnabled': 'Fuel Burn Enabled',
  'text.fuelBurnEnabledTrips': 'Fuel Burn enabled on trips',
  'text.fuelBurnEnabledTripsTooltip': 'Toggle this to true to allow users to add fuel burn via trip updates',
  'text.fuelBurnEnabledMx': 'Fuel Burn enabled on maintenance checks',
  'text.fuelBurnEnabledMxTooltip':
    'Toggle this to true to allow users to add fuel burn via maintenance checks and releases',
  'text.fuelBurnJustification': 'Fuel Burn Justification',
  'text.fuelBurnRequired': 'Fuel Burn Required',
  'text.fuelDelta': 'Fuel Delta',
  'text.fuelOnBoard': 'FUEL ON BOARD',
  'text.fuelPerHour': 'Fuel per Hour',
  'text.fph': 'FBpH',
  'text.fuelType': 'Fuel Type',
  'text.fuelUplift': 'Fuel Uplift',
  'text.fuelUpliftRequired': 'Fuel Uplift Required',
  'text.fuelUpliftAttachmentsRequired': 'Fuel Uplift Attachments Required',
  'text.fuelUsed': 'Fuel Used',
  'text.grantAccess': 'Grant access',
  'text.genericErrorMessage': 'There was an error with your request. If issue persists please contact support',
  'text.heading': 'Heading',
  'text.hide': 'Hide',
  'text.hideAddAnotherFlightPopUpiPad': 'Display add another flight popup on iPad',
  'text.hideAttachment': 'Hide Attachment',
  'text.hourAgo': 'hour ago',
  'text.hoursAgo': 'hours ago',
  'text.hoursLeft': 'Hours left',
  'text.howToCompleteThisForm': 'How to complete this form',
  'text.howWouldYouLikeToDefer': 'How would you like to defer this item?',
  'text.hideAircraftTotals': 'Hide aircraft totals',
  'text.hideBookItemTable': 'Hide Book Item Table',
  'text.hours': 'Hours',
  'text.hoursThreshold': 'Hours threshold',
  'text.hydraulicFluid': 'Hydraulic Fluid',
  'text.hydraulicUplift': 'Hydraulic Uplift',
  'text.idgUplift': 'IDG Uplift',
  'text.issueStatus': 'Issue status',
  'text.iCertifyPart145':
    'I certify that the work specified, except as otherwise specified, was carried out in accordance with Part-145 and in respect to that work the aircraft/aircraft component is considered ready for release to service.',
  'text.iCertifyPart145Document1':
    'I certify that the uploaded document is authentic and carried out in accordance with Part-145.',
  'text.iCertifyPart145Document2':
    'In respect to that work the aircraft/aircraft component is considered ready for release to service.',
  'text.iConfirmCorrectness':
    'I confirm that to the best of my knowledge the information submitted is complete and correct.',
  'text.iConfirmMessage':
    'I confirm that to the best of my knowledge the information submitted is complete and correct.',
  'text.imageTypes': '.JPG .PNG',
  'text.imageUploader.reupload': 'Drag and drop or click to reupload a profile picture. It should not exceed 1MB',
  'text.imageUploader.upload': 'Drag and drop or click here to upload a profile picture. It should not exceed 1MB.',
  'text.incompleteReleaseDefects':
    'This release contains incomplete defects. Would you like the enter the resolution details, or leave them as unresolved and move them to a new workpack?',
  'text.incompleteReleaseWorkpack':
    'The workpack contains items that were not completed as part of this release. They will be moved to a new workpack.',
  'text.incTaxi': 'Inc. Taxi',
  'text.initialSmall': 'Initial',
  'text.initial': 'INITIAL',
  'text.integrationsDescriptionFailed':
    'Unfortunately we were unable to link this account. Please check your details are correct, or contact us for support.',
  'text.integrationsDescriptionSuccess':
    'Your account has been successfully linked to RocketRoute. Within 24hrs your account should be fully configured to show filed flights from RocketRoute.',
  'text.integrationPageDescription': 'Enable and manage third party integrations with the Tech Log.',
  'text.intermittentFault': 'Intermittent fault',
  'text.intermittentFaultPlural': 'Intermittent fault plural',
  'text.interval': 'Interval',
  'text.intervalAOptions': 'Interval A Options',
  'text.ipadConfiguration': 'iPad Configuration',
  'text.item': 'Item',
  'text.itemCreated': 'Item created',
  'text.itemNum': 'Item no.',
  'text.itemDetails': 'Item details',
  'text.itemTitle': 'Item Title',
  'text.itemType': 'Item Type',
  'text.itemStatus': 'Item Status',
  'text.itemUpdated': 'Item updated',
  'text.importedItems': 'Imported Items',
  'text.jl': 'JL',
  'text.journeyLog': 'Journey Log',
  'text.justificationForFuel': 'JUSTIFICATION FOR FUEL CHANGE',
  'text.justificationRequired': 'Justification required',
  'text.landing': 'Landing',
  'text.landings': 'Landings',
  'text.last': 'Last',
  'text.lastName': 'Last Name',
  'text.leaveBlank': 'leave blank if not required',
  'text.lessThan24Hours': 'Less than 24 hours',
  'text.leonIntegration': 'Automatically synchronise flight and crew details',
  'text.rusadaIntegration': 'Automatically synchronise flights',
  'text.fl3xxIntegration': 'Automatically synchronise flight details and crew.',
  'text.licenceNumber': 'Licence Number',
  'text.limitations': 'Limitations',
  'text.limitationsError': 'Limitations error',
  'text.limitationsPleaseAdd': 'Please add limitation or click no limitations',
  'text.limitation': 'Limitation',
  'text.limitAddToDeferredDefects': 'Limit Add to Deferred Defects',
  'text.limitDeletionToAdmins': 'Limit deletion to admins',
  'text.limitType': 'Limit Type',
  'text.linkSuccessful': 'Link successful',
  'text.llpItems': 'LLP Items',
  'text.llpItem': 'LLP Item',
  'text.llp': 'LLP',
  'text.location': 'Location',
  'text.lockAircraft': 'Lock Aircraft',
  'text.lockingAircraft':
    'Locking an aircraft will remove it from non-admin users and prevent record changes until the aircraft is unlocked.',
  'text.uplift': 'UPLIFT',
  'text.uploadText': 'Upload schematic files to your {field} library, then attach them to categories.',
  'text.utcOffset': 'UTC Offset',
  'text.lastSync': 'Last Sync',
  'text.loading': 'Loading...',
  'text.logbookColumnsSaved': 'Logbook columns saved successfully',
  'text.mel': 'MEL',
  'text.mmel': 'MMEL',
  'text.melLong': 'MEL - Minimum Equipment List',
  'text.mainDetails': 'Main details',
  'text.maintenance': 'Maintenance',
  'text.maintenanceChecks': 'Maintenance checks',
  'text.maintenanceChecksImpactServiceability': 'Maintenance checks impact serviceability',
  'text.maintenanceItem': 'Maintenance Item',
  'text.maintenanceProceduresError': 'Maintenance procedures error',
  'text.maintenanceProceduresChecked': 'Maintenance procedures needs checked',
  'text.maintenanceSignoff': 'Maintenance Signoff',
  'text.makeActive': 'Make Active',
  'text.makeAircraftActiveFromSetup':
    'Moving an aircraft to Active will make it available to the Tech Log Application users, and mark the aircraft’s Billing Start Date as today.',
  'text.editMelDescription': 'Modify the details of this MEL with the form below.',
  'text.maxDecrementPerWing': 'Max Decrement Per Wing',
  'text.maximumCharacters': 'Maximum characters',
  'text.maxWeight': 'Max Weight',
  'text.maxWeightError': "Aircraft weight entered is greater than the aircraft's max weight",
  'text.maxWeightWarning': "Be aware the aircraft weight entered is considerably less than the aircraft's max weight",
  'text.mixRatio': 'Mix Ratio',
  'text.minimumEquipmentList': 'Minimum Equipment List',
  'text.minimumCharacters': 'Minimum characters',
  'text.minuteAgo': 'minute ago',
  'text.minutesAgo': 'minutes ago',
  'text.mode': 'Mode',
  'text.modifiedBy': 'Modified by',
  'text.modifySubscription': 'Modify your subscription settings below to meet your specific needs.',
  'text.months': 'Months',
  'text.monthsLeft': 'Months left',
  'text.monthsNotSet': 'Months: not set',
  'text.mxCheckShort': 'MX Check Short',
  'text.mxCheck': 'MX Check',
  'text.mxCheckPlural': 'MX Check Plural',
  'text.name': 'Name',
  'text.nameOverride': 'Name override',
  'text.nef': 'NEF',
  'text.nefExpanded': 'NEF - Non-Essential Equipment and Furnishing',
  'text.newCaps': 'NEW',
  'text.newCategoryName': 'New category name',
  'text.newWorkpack': 'Use this page to create a new workpack, composed of all relevant defects and maintenance items.',
  'text.nextDue': 'Next due',
  'text.noAircraftFound': 'No aircraft found',
  'text.noApplyableTolerances':
    'Items selected do not have applyable tolerances, please select edit item to add tolerances if required.',
  'text.noItemsFound': 'No items found in this workpack',
  'text.noItemsFoundMX': 'No maintenance items found for given time period',
  'text.noItemsMatchMX': 'No matching items found',
  'text.fetching': 'Fetching...',
  'text.noCapabilitiesAvailable': 'No capabilities available',
  'text.noCategory': 'No Category',
  'text.noCrew': 'Please add your first crew member.',
  'text.noData': 'No Data',
  'text.noDataAvailable': 'No Data Available',
  'text.noDefects': 'No Defects',
  'text.noDeferralOptionsForDefect': 'No deferral options for the defect.',
  'text.noItemsImported': 'There are no new items.',
  'text.noAircraftSelected': 'No aircraft selected',
  'text.noScheduled': 'No Scheduled Items',
  'text.noOfLandings': 'NO. OF LANDINGS',
  'text.noOop': 'No Out of Phase Items',
  'text.noLlp': 'No Life Limited Parts',
  'text.noPeopleFound': 'No people found',
  'text.noUserFound': "We couldn't find results for {field}",
  'text.noUserFoundInOrg': "We couldn't find results for {field} inside {organisation}",
  'text.none': 'None',
  'text.nonEssentialEquipmentFurnishing': 'Non-Essential Equipment and Furnishings',
  'text.noSearchMatching': 'No results found for your search criteria.',
  'text.noSignature': 'No signature found.',
  'text.notAvailableShort': 'N/a',
  'text.notDeferred': 'Not Deferred',
  'text.notInstalled': 'Not Installed',
  'text.notes': 'Notes',
  'text.noteNoPasswordStored':
    'Please note for security reasons we do not store any of your third party username or password details.',
  'text.notesToCrew': 'Notes to crew',
  'text.notIntegrated': 'Not Integrated',
  'text.notSet': 'Not Set',
  'text.aiBeta': 'TrustFlight AI (beta)',
  'text.aiSearchingUsing': "You're searching using",
  'text.aiEnhanceYourSearch': 'Enhance your search with',
  'text.oil': 'Oil',
  'text.oilAdded': 'Oil Added',
  'text.oilConsumption': 'Oil Consumption',
  'text.oilUplift': 'Oil Uplift',
  'text.onlyOneIndividualThreshold': 'Only one individual threshold can be set, please remove one of them',
  'text.order': 'Order',
  'text.origin': 'Origin',
  'text.engineOilConsumption': 'Engine Oil Consumption',
  'text.operationalProceduresError': 'Operational procedures error',
  'text.operationalProceduresChecked': 'Operational procedures needs checked',
  'text.operator': 'Operator',
  'text.operatorDetails': 'Operator Details',
  'text.options': 'Options',
  'text.overdue': 'Overdue',
  'text.overdueAccountMessage':
    'Account overdue - please liaise with your Finance department to normalise the account to ensure continued service.',
  'text.oopItems': 'OOP Items',
  'text.oop': 'OOP',
  'text.oopItem': 'OOP Item',
  'text.part145': 'Part 145',
  'text.part145ApprovalNumber': 'Part 145 Approval Number',
  'text.partDescription': 'Part Description',
  'text.partReplacements': 'Part replacements',
  'text.partReplacementBatchOnField': 'Part replacement batch on field',
  'text.partReplacementDescriptionField': 'Part replacement description field',
  'text.partReplacementPositionField': 'Part replacement position field',
  'text.partReplacementTraceOffField': 'Part replacement trace off field',
  'text.passengers': 'Passengers',
  'text.passwordMinLength': 'Password minimum length',
  'text.passwordGenerated': 'Password generated',
  'text.malePassengers': 'Male',
  'text.femalePassengers': 'Female',
  'text.childrenPassengers': 'Children',
  'text.infantPassengers': 'Infant',
  'text.baggageWeight': 'Baggage weight',
  'text.pending': 'Pending',
  'text.percentage': 'Percentage',
  'text.personModeTooltip': 'Leaving the option below empty will grant access to everyone',
  'text.permissionsTooltip':
    'Permissions over the newly created aircraft will be granted to users based on the option selected here: Organisation: The aircraft will be available to all users within the organisation. Default: Only administrators within the organisation will be granted access to the new aircraft. Duplicate from Aircraft: This will assign permissions to the same set of users that have access to the selected, existing, aircraft.',
  'text.phoneNumber': 'Phone Number',
  'text.pilotInCommand': 'Pilot in Command',
  'text.pilotFlying': 'Pilot Flying',
  'text.pilotMonitoring': 'Pilot Monitoring',
  'text.pilotPerformingTakeOff': 'Pilot Performing Take Off',
  'text.pilotPerformingLanding': 'Pilot Performing Landing',
  'text.pic': 'PIC',
  'text.pin': 'PIN',
  'text.pinReset': 'PIN reset',
  'text.pinUpdatedSuccessfully': 'PIN updated successfully',
  'text.pinUserMustHaveEmployeeNumber': 'PIN Users must have employee numbers to authenticate',
  'text.pinUserMustHaveEmployeeNumberTooltip':
    'PIN users can only log in if their employee number is set. Users without an employee number will not be able to log in until it is provided.',
  'text.placardsError': 'Placards error',
  'text.placardsChecked': 'Placards needs checked',
  'text.placardsNumberOptional': 'Placards Number (optional)',
  'text.planned': 'Planned',
  'text.plannedArrival': 'Planned Arrival',
  'text.plannedActualArrival': 'Planned/Actual Arrival',
  'text.plannedFuelBurn': 'Planned Fuel Burn',
  'text.pleaseAddArectificationExtendedOnDate': 'Please add a rectification extended on date',
  'text.pleaseAddARectificationNewDateDue': 'Please add a rectification new date due',
  'text.pleaseEnterAdditional': 'Please enter additional information',
  'text.pleaseEnterA6DigitPin': 'Please enter a 6 digit PIN',
  'text.pleaseEnterA6DigitPinOnlyNumbers': 'Please enter a 6 digit PIN with only numbers',
  'text.detailsError': 'Details error',
  'text.pleaseEnterDetails': 'Please enter details with at least five characters',
  'text.pleaseEnterDeferralApprovalReference': 'Please enter {field} (character count more than 4 required)',
  'text.pleaseEnterRectificationInterval': 'Please enter all rectification interval limits listed in the MEL',
  'text.pleaseEnterReasonToDefer': 'Please enter the reason to defer',
  'text.pleaseEnterReference': 'Please add a reference (character count more than 4 required)',
  'text.pleaseEnterReferenceNumber':
    'Please enter a Workpack Reference, {po} {suffix} or other reference for the work undertaken and fill out the work below.',
  'text.pleaseNoteChangingDeferral':
    'Please note changing the deferral period may alter the current status of the defect.',
  'text.pleaseSelectAircraft': 'Please select aircraft to view data',
  'text.pleaseSelectALocation': 'Please select a location',
  'text.pleaseSelectArea': 'Please select an area',
  'text.pleaseSelectASeat': 'Please select a seat',
  'text.defectTypeError': 'Defect type error',
  'text.pleaseSelectDefectType': 'Please select a defect type',
  'text.pleaseSelectSeverity': 'Please select a severity',
  'text.rectificationCategoryError': 'Rectification category error',
  'text.rectificationExtensionError': 'Rectification extension error',
  'text.reorder': 'Reorder',
  'text.pleaseSelectRectificationCategory': 'Please select a rectification category',
  'text.precision': 'Precision',
  'text.preview': 'Preview',
  'text.previousPeriod': 'Previous Period',
  'text.processOrder': 'Process Order',
  'text.propellors': 'Propellors',
  'text.propellor1': 'Propellor 1',
  'text.propellor2': 'Propellor 2',
  'text.propellor3': 'Propellor 3',
  'text.propellor4': 'Propellor 4',
  'text.publicShareLink': 'Public share link',
  'text.quantity': 'Quantity',
  'text.quantityRemaining': 'Qty remaining',
  'text.question': 'Question',
  'text.read': 'Read',
  'text.reasonToDefer': 'Reason to defer',
  'text.reasonToDeferError': 'Reason to defer error',
  'text.pleaseAddReasonToDefer': 'Please add a reason to defer (character count more than 4 required)',
  'text.receipt': 'Receipt',
  'text.rectifications': 'Rectifications: ',
  'text.referenceError': 'Reference error',
  'text.referenceNumberHere': 'Reference number here',
  'text.referenceRequired': 'Reference required',
  'text.reject': 'Reject',
  'text.rejectAll': 'Reject All',
  'text.releaseNotes': 'Release Notes',
  'text.repeatFrom': 'Repeat from',
  'text.resolution': 'Resolution',
  'text.resolutionType': 'Resolution Type',
  'text.resolved': 'Resolved',
  'text.resolvedBy': 'Resolved by',
  'text.restrictReleaseSigneeType': 'Restrict release signee type',
  'text.restrictReleaseSigneeTypeTooltip': 'Restricts the signoff types available when creating a Release on the iPad',
  'text.restrictApprovalReleaseCompletion': 'Restrict approval release completion',
  'text.reviewDraftItemsIndividually': 'Review draft items individually',
  'text.reviewDraftItemIndividually': 'Review draft item individually',
  'text.removeDeferral': 'Remove deferral',
  'text.removedItems': 'Removed Items',
  'text.s': 's',
  'text.save': 'Save',
  'text.save&AddNewField': 'Save & add new field',
  'text.saveMyChanges': 'Save my changes',
  'text.safPercentage': 'SAF Percentage',
  'text.scheduledItems': 'Scheduled Items',
  'text.sch': 'SCH',
  'text.schematics': 'schematics',
  'text.seat': 'Seat',
  'text.secondAgo': 'second ago',
  'text.secondsAgo': 'seconds ago',
  'text.secondsThreshold': 'Seconds threshold',
  'text.sector': 'Sector',
  'text.sectorPlural': 'Sector Plural',
  'text.sectorDefects': 'Sector Defects',
  'text.sectorUplifts': '{sectorNameOverride} Uplifts',
  'text.selectAircraftType': 'Select aircraft type',
  'text.selectExistingAircraft':
    'Select an existing aircraft in your fleet to use as a basis for this new aircraft. The new aircraft will have the same application settings as the existing one, including custom fields, fuel and oil units, and maintenance settings.',
  'text.selectOperator': 'Select operator',
  'text.selected': 'selected',
  'text.selectedAircraft': 'Selected Aircraft',
  'text.selectedItems': 'Selected Items',
  'text.selectMode': 'Select mode',
  'text.send': 'Send',
  'text.sendPasswordEmail':
    'Send an email with a "Reset your password" link to the user. so they can reset their account password',
  'text.sendPINEmail':
    'Send an email with a "Reset your PIN" link to the user. so they can reset their account password',
  'text.show': 'Show',
  'text.showAdvisoryOnlyDefectsOnAdd': 'Show Advisory Only Defects on Add',
  'text.showAircraftTotals': 'Show aircraft totals',
  'text.showAmosUserSign': 'Show AMOS user sign',
  'text.showBookItemTable': 'Show Book Item Table',
  'text.showDefectReportedByType': 'Show defect reported by type',
  'text.showEmployeeIdentifier': 'Show Employee Identifier',
  'text.showEmployeeNumber': 'Show Employee Number',
  'text.showLess': 'Show less',
  'text.showMore': 'Show more',
  'text.showAttachment': 'Show attachment',
  'text.showAllDraftItems': 'Show all draft Iitems',
  'text.showLastTimeCompletedOnPreflight': 'Show last time completed on preflight',
  'text.showLessDraftItems': 'Show less items',
  'text.showPilotFlying': 'Show Pilot Flying/Monitoring',
  'text.showPilotPerforming': 'Show Pilot Performing Take off/Landing',
  'text.showPOAndReference': 'Make the PO or Reference number required on the iPad CRS flow',
  'text.showPOAndReferenceTooltip': 'Make the PO or Reference number (when applicable) required on the iPad CRS flow',
  'text.skip': 'Skip',
  'text.showOnly': 'Show only',
  'text.showOnlyMyTickets': 'Show Only My Tickets',
  'text.showOnSRP': 'Show on SRP',
  'text.smallestNumberForTheField': 'The smallest number for the field',
  'text.specificGravity': 'Specific Gravity',
  'text.specifyActualArrival': 'Specify a different Actual Arrival Airport',
  'text.srp': 'SRP',
  'text.viewItem': 'View Item',
  'text.viewSRP': 'View {field}',
  'text.stageOfFlightName': 'Stage of Flight name',
  'text.stageOfFlightOnDefectsIsRequired': 'Stage of Flight on defects is required',
  'text.stageOfFliightOptions': 'Stage of Flight Options',
  'text.start': 'Start',
  'text.startOver': 'Start Over',
  'text.status': 'Status',
  'text.statusCaps': 'STATUS',
  'text.statusToggle': 'Status Toggle',
  'text.streamlined': 'Streamlined',
  'text.subscriptionInfoText':
    'Subscriptions allow you to be notified about events that occur on your account. If you create a subscription, you will receive an email each time the event occurs.',
  'text.suspendedAccountMessage':
    'TrustFlight has been suspended. Please contact your Finance department to normalise the account and to get the service reinstated.',
  'text.syncd': 'Synced',
  'text.syncing': 'Syncing...',
  'text.synchronising': 'Synchronising...',
  'text.takeoff': 'Takeoff',
  'text.terminology': 'Terminology',
  'text.terminologySubheader':
    'Throughout the iPad Application and Web Dashboard, we use specific terminology that may not be applicable to your region or operation. This section allows you to replace all instances of these terminologies with phrases that fits your operation better.',
  'text.text': 'Text',
  'text.thereWasAnIssueWithNetwork': 'There was an issue with the network request. Please try again',
  'text.thisAircraftHasNoMEL':
    'This aircraft has no {field} currently, please manually enter the {field} details below.',
  'text.lockAircraftAreYouSure':
    'This aircraft will be locked from all changes and removed from non-admin users until it is unlocked. Do you wish to continue?',
  'text.userNotVerifiedAccount': `This user hasn't verified their account yet. You can resend the "Confirm Registration" email so that
                  they can verify their email address and activate their account.`,
  'text.thisChapterContainsMultipleOptions': 'This chapter contains multiple options',
  'text.thisDefectNotResolved':
    'This Defect has not been resolved. Please share the following link to allow a Part 445 to update the defect status:',
  'text.thisWillRenderAircraftUnservicable': 'This will render the aircraft unserviceable',
  'text.thisMayCauseHistorical': 'This may cause historical SRP issues.',
  'text.timeCompleted': 'Time Completed',
  'text.to': 'To',
  'text.today': 'Today',
  'text.toggleView': 'Toggle view:',
  'text.toMakeAnyEditsYouMustProvideJustification':
    'To make any edits, you must provide a justification for modifying the existing resolution.',
  'text.tolerance': 'Tolerance',
  'text.totalHoursFlown': 'Total Hours Flown',
  'text.totalFuelUsed': 'Total Fuel Used',
  'text.totals': 'Totals',
  'text.totalled': 'Totalled',
  'text.traxxallIntegration': 'Automatically synchonrise defects, maintenance items and workpacks from Traxxall.',
  'text.trip': 'Trip',
  'text.tripNum': 'Trip #',
  'text.tripConfiguration': 'Trip configuration',
  'text.tripSortOrder': 'Trip sort order',
  'text.tripUpdate': 'Trip Update',
  'text.tripUpdatesInfo':
    'Trip Updates allow recording aircraft state changes in the form of additional hours or cycles, or overriding the totals carried forward if, for example, part changes have occurred.',
  'text.troubleshootingStepsError': 'Troubleshooting Error',
  'text.pleaseEneterTaskDescription': 'Please enter a task description',
  'text.pleaseEnterTroubleshootingSteps':
    'Please enter the troubleshooting steps taken (character count more than 1 required)',
  'text.troubleshootingStepsNameOverride': 'Troubleshooting steps name override',
  'text.troubleshootingStepsRequired': 'Troubleshooting steps required',
  'text.troubleshootingSteps': '{field} taken',
  'text.tryAgain': 'Try again',
  'text.type': 'Type',
  'text.updates': 'Updates',
  'text.updateFailed': 'Update failed',
  'text.updateTemplateMetadata': 'Update Metadata',
  'text.updateTemplateMetadataSuccess':
    'Metadata successfully updated. {sectors_count} sectors, {maintenance_count} rows detected.',
  'text.updateTemplateMetadataError': 'Unable to update metadata. Please try again later.',
  'text.uniqueIdentifierForTheField': 'A unique identifier for the field',
  'text.unit': 'Unit',
  'text.unknown': 'Unknown',
  'text.unableToAddAdditionalTask':
    'Unable to add additional items outside of a workpack. To add additional items, create a new workpack with all items included',
  'text.unableToCreateAircraft': 'Unable to create aircraft',
  'text.unlock': 'Unlock',
  'text.unlockTimeDue': 'Unlock time due',
  'text.unableToLoadImage': 'Unable to load image file.',
  'text.unableToSync': 'Unable to synchronise. Please try again',
  'text.uploadAttachmentToView': 'Upload an attachment to view while editing.',
  'text.uploadIcon': 'upload icon',
  'text.uploadSchematic': 'Upload schematic',
  'text.unlockAircraft': 'Unlock Aircraft',
  'text.usedFuel': 'Used fuel',
  'text.user': 'User',
  'text.userManagement': 'User Management',
  'text.usingACrewSignoff': 'Using a crew signoff',
  'text.usingADeferralApproval': 'Using a deferral approval reference',
  'text.updatedItems': 'Updated Items',
  'text.validFor30days':
    'This link (valid for 30 days) will allow a third party to view and input a resolution to the defect, without requiring a log in.',
  'text.validFor30daysWorkpack':
    'This link (valid for 30 days) will allow a third party to view this workpack, without requiring a log in.',
  'text.valueShownInBoxWhenFieldLoaded': 'The value to be shown in the box when the field is loaded',
  'text.view': 'View',
  'text.wouldYouLikeToAddAdditionalItems': 'Would you like to add any additional items?',
  'text.wouldYouLikeToDeferThisDefect': 'Would you like to defer this defect?',
  'text.yearOnYear': 'Year on Year',
  'text.youAreViewing': 'You are viewing: ',
  'text.youCanAlsoUpload': 'You can also upload your file by',
  'text.resendActivationLink': 'Re-send activation link',
  'text.repairCategory': 'Repair category',
  'text.timeDue': 'Time due',
  'text.title': 'Title',
  'text.titleForEngineerLicenceAuthorisationField': 'Title for engineer licence/authorisation field',
  'text.threshold': 'Threshold',
  'text.noDetails': 'No details',
  'text.repeat': 'Repeat',
  'text.after': 'After',
  'text.thresholdShort': 'thr.',
  'text.selectAttachments': 'Select attachments...',
  'text.addMoreAttachments': 'Add more attachments...',
  'text.addOption': 'Add option',
  'text.reportedAndSignedBy': 'Reported and signed by:',
  'text.reportedBy': 'Reported by:',
  'text.reportedOn': 'Reported on:',
  'text.editedBy': 'Edited by:',
  'text.editedOn': 'Edited on:',
  'text.deferred': 'Deferred',
  'text.deferredBy': 'Deferred by:',
  'text.approveDeferral': 'Approve Deferral',
  'text.unableToApproveDeferral': 'Unable to approve deferral',
  'text.deferredOn': 'Deferred on:',
  'text.reviewing': 'Reviewing',
  'text.required': 'Required',
  'text.requirement': 'Requirement',
  'text.sic': 'SIC',
  'text.signed': 'Signed:',
  'text.iPadSignoffOptions': 'iPad signoff options',
  'text.scheduledMX': 'Scheduled MX',
  'text.pleaseSignHere': 'Please sign here',
  'text.weight': 'Weight',
  'text.weighting': 'Weighting',
  'text.within3Days': 'Within 3 days',
  'text.within10Days': 'Within 10 days',
  'text.within30Days': 'Within 30 days',
  'text.within60Days': 'Within 60 days',
  'text.within120Days': 'Within 120 days',
  'text.within365Days': 'Within 365 days',
  'text.whatCustomFieldCreateToday': 'What custom field would you like to create today?',
  'text.whenSetToShowOnlyUsersWithPermissionCanCompleteReleases':
    'When set to show only users with permission can complete releases. When set to hidden all users are able to complete releases',
  'text.pleaseSignHereToConfirmDefect': 'Please sign here to confirm defect',
  'text.warningCaps': 'WARNING',
  'text.workpack': 'Workpack',
  'text.workpackPlural': 'Workpack Plural',
  'text.workpackConfiguration': 'Workpack configuration',
  'text.workpackId': 'Workpack Id',
  'text.workpackReference': 'Workpack Reference',
  'text.write': 'Write',
  'text.melNoItems': 'There are no items in this chapter.',
  'text.multipleItemsOnSameRowOrderDisplayedIn':
    'When multiple items are on the same row the order they will be displayed in',
  'text.other': 'OTHER',
  'text.otherLimit': 'Other Limit',
  'text.nondeferred': 'NONDEFERRED',
  'text.noChaptersInMEL': 'There are no chapters in this MEL',
  'text.noChaptersInMMEL': 'There are no chapters in this MMEL',
  'text.melOneItemChapter': 'item in this chapter.',
  'text.melThereAre': 'There are',
  'text.melThereIs': 'There is',
  'text.xMelItemsInThisChapter': 'items in this chapter.',
  'text.youreEditingChapter': "You're editing chapter",
  'text.sectionDetails': 'Section details',
  'text.subSection': 'SUB SECTION',
  'text.subSectionDetails': 'Sub-Section details',
  'text.subSubSection': 'SUB SUB SECTION',
  'text.subSubSectionDetails': 'Sub-Sub-Section details',
  'text.seeFile': 'See file',
  'text.setDefault': 'Set as Default',
  'text.browserNotSupported': 'Your browser ({browser}) is not supported',
  'text.browserUseFollowing': 'Please use one of the following:',
  'text.browserEdge': 'Microsoft Edge',
  'text.browserChrome': 'Google Chrome',
  'text.browserFirefox': 'Mozilla Firefox',
  'text.browserSafari': 'Apple Safari',
  'text.getInTouch': 'Get in touch with us at',
  'text.generateDocError': 'Generate document error',
  'text.GPUUsage': 'GPU usage',
  'text.repeatedItems': 'Repeated Items',
  'text.expandAllItems': 'Expand All Items',
  'text.expandAllChapters': 'Expand All Chapters',
  'text.package': 'Package',
  'text.packages': 'Packages',
  'text.airframe': 'Airframe',
  'text.camp': 'CAMP',
  'text.cabinIssue': 'Cabin issue',
  'text.cabinIssueAdded': 'Cabin issue added',
  'text.cabinIssueUpdated': 'Cabin issue updated',
  'text.cabinIssueDeleted': 'Cabin issue deleted',
  'text.cabinIssueDetails': 'Cabin issue details',
  'text.cabinIssuePlural': 'Cabin issues',
  'text.cabinIssueShort': 'Cabin Issue Short',
  'text.cas': 'CAS',
  'text.casLong': 'CAS - Crew Alerting System',
  'text.cdl': 'CDL',
  'text.cdlLong': 'CDL - Configuration Deviation List',
  'text.trustflight': 'TrustFlight',
  'text.techlog': 'TechLog',
  'text.showing': 'Showing',
  'text.showStageOfFlightOnDefects': 'Show Stage of Flight on Defects',
  'text.nextXDays': 'Next {days} days',
  'text.largestNumberForTheField': 'The largest number for the field',
  'text.lastXDays': 'Last {days} days',
  'text.withinXDays': 'Within {days} days',
  'text.next24Hours': 'Next 24 hours',
  'text.nextYear': 'Next year',
  'text.nextYearMore': 'Next year & more',
  'text.lastYearMore': 'Last year & more',
  'text.allFieldsOptional': 'All of the fields below are optional',
  'text.allTime': 'All time',
  'text.clearAll': 'Clear All',
  'text.remaining': 'remaining',
  'text.remarksError': 'Remarks error',
  'text.remarksChecked': 'Remarks needs checked',
  'text.toleranceNotAvailable': 'Tolerance not available.',
  'text.overdueItems': 'Overdue Items',
  'text.tommorow': 'Tommorow',
  'text.of': 'of',
  'text.ifEnabledReferenceRequired': 'If enabled, reference is required to proceed with defect creation',
  'text.itemsPerPage': 'Items per page',
  'text.imported': 'Imported',
  'text.importedMaintenanceRequiresApproval': 'Imported maintenance requires approval',
  'text.createANewCustomField': 'Create a new custom field',
  'text.createANewCustomFieldPreflight': 'Create a new custom field for preflight',
  'text.createANewCustomFieldPostflight': 'Create a new custom field for postflight',
  'text.created': 'Created',
  'text.creationError': 'Creation error',
  'text.crewAlertingSystem': 'Crew Alerting System',
  'text.editedIn': 'Edited in',
  'text.manageItem': 'Manage item',
  'text.manageQuestion': 'Manage question',
  'text.lastCompleted': 'Last Completed',
  'text.viewWorkpack': 'View {field}',
  'text.itemInsidePackage': 'Items inside this package {number}',
  'text.poNotSpecified': '{po_short_name} Not Specified',
  'text.positioning': 'Positioning',
  'text.poSuffix': 'PO Suffix',
  'text.poShort': 'PO Short',
  'text.poLong': 'PO Long',
  'text.newFieldDisplayedAfterThisField': 'The new field will be displayed after this field',
  'text.newFRATName': 'New FRAT name',
  'text.newWorkpackCaps': 'NEW WORKPACK',
  'text.numberOfDecimalPlacesRecorded': 'The number of decimal places to be recorded',
  'text.addToExistingWorkpack': 'Add to existing {field} or create a new one',
  'text.addToWorkpack': 'Add to {field}',
  'text.createNewWorkpack': 'Create a new {field}',
  'text.youHaveSelected': 'You have selected',
  'options.resetAll': 'Reset all',
  'options.deselectAll': 'Deselect all',
  'options.selectAll': 'Select all',
  'options.allTypes': 'All types',
  'options.allOperators': 'All operators',
  'period.all': 'All',
  'period.allTime': 'All time',
  'period.APUhours': 'APU hours',
  'period.fltHours': 'Flight hours',
  'period.hourly': 'Hourly',
  'period.hoursShort': 'Hrs',
  'period.hrs': 'hrs',
  'period.landings': 'Landings',
  'period.maxFL': 'Max FL',
  'period.mins': 'mins',
  'period.cycles': 'cycles',
  'period.Cycles': 'Cycles',
  'period.days': 'Days',
  'period.thisWeek': 'This week',
  'period.lastWeek': 'Last week',
  'period.thisMonth': 'This month',
  'period.lastMonth': 'Last month',
  'period.last30Days': 'Last 30 days',
  'period.last7Days': 'Last 7 days',
  'period.last3Month': 'Last 3 months',
  'period.last90Days': 'Last 90 days',
  'period.thisYear': 'This year',
  'period.lastYear': 'Last year',
  'period.custom': 'Custom',
  'period.pressurisedCycle': 'Pressurised cycle',
  'tooltip.addNewDefect': 'This form allows a defect to be added to an Aircraft',
  'tooltip.addingPaperRecord': 'For adding a paper record',
  'tooltip.servicing': 'These values keep track of fuel & oil added to the aircraft',
  'tooltip.aircraftSettings':
    'These are settings used to configure the app specifically for this aircraft, for example fuel capacity allows us to check if too much fuel is being uplifted!',
  'tooltip.basicAircraftInfo': 'This information is used to create the basic aircraft profile',
  'tooltip.createNewAircraft': 'This form will guide you through creating a new aircraft for use in the Tech Log app',
  'tooltip.fuelDelta': 'Fuel Delta',
  'tooltip.fuelDeltaExplain': ' = Cumulative [Fuel Used - Fuel Uplifted] ',
  'tooltip.fuelDeltaPositive': 'Positive',
  'tooltip.fuelDeltaPositiveExplain': ' = more used than uplifted',
  'tooltip.generateLink3rdParty': 'Generates a link for a third party (Part 145) to update the record',
  'tooltip.nextChecks': 'These values are used to calculate show the time remaining until next checks within the app',
  'tooltip.resolveDefect': 'Opens a new tab for a third party (Part 145) to add a resolution',
  'tooltip.startingTotals':
    'Additional {field} logged using the Tech Log will added to the starting totals created here. Starting totals can also be modified once the aircraft has been created',
  'units.apuHoursLeft': 'APU hrs left',
  'units.apuHourLeft': 'APU hr left',
  'units.apuHours': 'APU hours',
  'units.apuHour': 'APU hour',
  'units.cycles': 'cycles',
  'units.cyclesCap': 'CYCLES',
  'units.cycle': 'cycle',
  'units.cyclesLeft': 'cycles left',
  'units.cycleLeft': 'cycle left',
  'units.daysLeft': 'days left',
  'units.dayLeft': 'day left',
  'units.day': 'day',
  'units.days': 'days',
  'units.fuelPerHour': 'Fuel burn per hour',
  'units.hoursLeft': 'hrs left',
  'units.hourLeft': 'hr left',
  'units.hours': 'hours',
  'units.hoursCap': 'HOURS',
  'units.hoursShort': 'hrs',
  'units.hour': 'hour',
  'units.hourShort': 'hr',
  'units.hoursPerCycle': 'Hours per cycle',
  'units.lb': 'lb',
  'units.ltr': 'ltr',
  'units.kg': 'kg',
  'units.ml': 'ml',
  'units.qt': 'qt',
  'units.usg': 'usg',
  'units.litres': 'Litres',
  'units.months': 'months',
  'units.monthsLeft': 'months left',
  'units.monthLeft': 'month left',
  'units.monthsLeftShort': 'mos left',
  'units.monthLeftShort': 'mo left',
  'units.monthShort': 'mo',
  'units.monthsShort': 'mos',
  'units.totalHoursFlown': 'Total Hours Flown',
  'units.totalFuelUsed': 'Total Fuel Used',
  'units.daysAgo': 'days ago',
  'user.logout': 'Logout',
  'user.changePassword': 'Change password',
  'form.button.add': 'Add',
  'form.button.applyTolerance': 'Apply tolerance',
  'form.button.addAircraftFilter': 'Add an aircraft',
  'form.button.addAircraft': 'Add a new aircraft',
  'form.button.addCrewMember': 'Add crew member',
  'form.button.addCRS': 'Add {field}',
  'form.button.addComment': 'Add comment',
  'form.button.addDamageMap': 'Add a new schematic',
  'form.button.addDefect': 'Add a defect',
  'form.button.addItem': 'Add item',
  'form.button.addMEL': 'Add MEL',
  'form.button.addNew': 'Add new',
  'form.button.addNewDocument': 'Add a new document',
  'form.button.addNewItem': 'Add a new item',
  'form.button.addNewUser': 'Add a new user',
  'form.button.addPerson': 'Add person',
  'form.button.addPart': 'Add part',
  'form.button.addNewSection': 'Add new section',
  'form.button.approve': 'Approve',
  'form.button.addDispatchCondition': 'Add another dispatch condition',
  'form.button.addRectification': 'Add another rectification',
  'form.button.reject': 'Reject',
  'form.button.addSignature': 'Add a signature',
  'form.button.addSubscription': 'Add a new subscription',
  'form.button.addSector': 'Add {sector}',
  'form.button.addSubSection': 'Add sub-section',
  'form.button.addNextSubSection': 'Add next sub-section',
  'form.button.addNextSubSubSection': 'Add next sub-sub-section',
  'form.button.addSubSubSection': 'Add sub-sub-section',
  'form.button.addTemplate': 'Add new template',
  'form.button.addTrip': 'Add a new trip',
  'form.button.addUser': 'Add a user',
  'form.button.addWorkpack': 'Add a new {field}',
  'form.button.all': 'All',
  'form.button.back': 'Back',
  'form.button.Category not set': 'Category not set',
  'form.button.cancel': 'Cancel',
  'form.labels.cannotBeUndone': 'Warning! This action cannot be undone.',
  'form.button.choseFile': 'Choose file',
  'form.button.complete': 'Complete',
  'form.button.clear': 'Clear',
  'form.button.clickToAddFile': 'Click to add',
  'form.button.clickHere': 'clicking here',
  'form.button.close': 'Close',
  'form.button.clearAll': 'Clear all',
  'form.button.clickToUpload': 'Click to upload',
  'form.button.continue': 'Continue',
  'form.button.copyToClipboard': 'Copy to clipboard',
  'form.button.copy': 'Copy',
  'form.button.deicing': 'Deicing',
  'form.button.delete': 'Delete',
  'form.button.selectFile': 'Select file',
  'form.button.deleteDocument': 'Delete document',
  'form.button.deleteFile': 'Delete',
  'form.button.deleteDispatchCondition': 'Delete dispatch condition',
  'form.button.deleteTrip': 'Delete trip',
  'form.button.deleteRectification': 'Delete rectification',
  'form.button.details': 'Details',
  'form.button.done': 'Done',
  'form.button.download': 'Download',
  'form.button.downloadCSV': 'Download CSV',
  'form.button.edit': 'Edit',
  'form.button.editDetails': 'Edit details',
  'form.button.editSettings': 'Edit settings',
  'form.button.editThresholds': 'Edit MX thresholds',
  'form.button.empennage': 'Empennage',
  'form.button.enterResolution': 'Enter resolution details',
  'form.button.fuelUplift': 'Fuel uplift',
  'form.button.fuselage': 'Fuselage',
  'form.button.general': 'General',
  'form.button.generate': 'Generate',
  'form.button.generateCSV': 'Generate CSV',
  'form.button.grid': 'Grid',
  'form.button.link': 'Link',
  'form.button.list': 'List',
  'form.button.melSettings': 'MEL settings',
  'form.button.no': 'No',
  'form.button.openInNewTab': 'Open in new tab',
  'form.button.overrideValues': 'Override values',
  'form.button.remove': 'Remove',
  'form.button.removeCrew': 'Remove crew member',
  'form.button.removeSection': 'Remove section',
  'form.button.removeSelected': 'Remove selected',
  'form.button.removeItem': 'Remove item',
  'form.button.removePhoto': 'Remove photo',
  'form.button.removeSubSection': 'Remove sub-section',
  'form.button.removeSubSubSection': 'Remove sub-sub-section',
  'form.button.powerplant': 'Powerplant',
  'form.button.propeller': 'Propeller',
  'form.button.resetPassword': 'Reset password',
  'form.button.resetPin': 'Reset PIN',
  'form.button.save': 'Save',
  'form.button.saveAndFinish': 'Save and finish',
  'form.button.seeMoreUpdates': 'See more updates',
  'form.button.moreDetails': 'More details',
  'form.button.moveToNewWorkpack': 'Move to a new workpack',
  'form.button.lessDetails': 'Less details',
  'form.button.saveChapter': 'Save chapter',
  'form.button.selectAll': 'Select all',
  'form.button.mxThreshold': 'MX threshold',
  'form.button.saveDetails': 'Save details',
  'form.button.submit': 'Submit',
  'form.button.submitCRS': 'Submit {field}',
  'form.button.submitDetails': 'Submit details',
  'form.button.submitResolution': 'Submit Resolution',
  'form.button.table': 'Table',
  'form.button.tryAgain': 'Try again',
  'form.button.update': 'Update',
  'form.button.upload': 'Upload',
  'form.button.uploadSchematic': 'Upload schematic',
  'form.button.uploadNewSchematic': 'Upload a new schematic',
  'form.button.unknown': 'Unknown',
  'form.button.view': 'View',
  'form.button.viewCRS': 'View {field}',
  'form.button.viewSignature': 'View signature',
  'form.button.windows': 'Windows',
  'form.button.wings': 'Wings',
  'form.button.yes': 'Yes',
  'form.button.youCan': 'You can upload your file by',
  'form.button.airworthy': 'Airworthy',
  'form.button.serviceable': 'Serviceable',
  'form.button.inMaintenance': 'In maintenance',
  'form.button.onGround': 'On ground',
  'form.button.inFlight': 'In flight',
  'form.button.inTolerance': 'In tolerance',
  'form.button.statusUnknown': 'Unknown',
  'form.button.unserviceable': 'Unserviceable',
  'form.button.critical': 'Critical',
  'form.button.addAnotherTask': 'Add another task',
  'form.button.addUplift': '+ Add uplift',
  'form.button.removeTask': 'Remove task',
  'form.button.removePart': 'Remove part',
  'form.button.inspected': 'Inspected',
  'form.button.partReplaced': 'Part replaced',
  'form.button.otherTask': 'Other Task',
  'form.button.fillInCRS': 'Fill in engineer details',
  'form.button.uploadCRS': 'Upload {field} document',
  'form.button.updateItem': 'Update item',
  'form.button.saveComplete': 'Save and complete',
  'form.helper.fillCRS': 'Fill in engineer details required to sign CRS',
  'form.helper.uploadCRS': 'Upload previously signed CRS form and confirm it with your details & signature',
  'form.labels.attachmentIsRequired': 'Attachment is required',
  'form.labels.acHours': 'A/C Hours',
  'form.labels.acLandings': 'A/C Landings',
  'form.labels.active': 'Active',
  'form.labels.actualUplift': 'Actual Uplift',
  'form.labels.aircraft': 'Aircraft',
  'form.labels.aircraftLimitations': 'Aircraft Limitations',
  'form.labels.aircraftType': 'Aircraft Type',
  'form.labels.airport': 'Airport',
  'form.labels.apiKey': 'API Key',
  'form.labels.apiPassword': 'API Password',
  'form.labels.apu': 'APU',
  'form.labels.apuMode': 'APU Mode',
  'form.labels.apuHours': 'APU Hours',
  'form.labels.apuHoursLimit': 'APU Hours Limit',
  'form.labels.apuTotal': 'APU Total',
  'form.labels.assignPermissions': 'Assign permissions to',
  'form.labels.assignPermissionsFromAircraft': 'Copy from',
  'form.labels.area': 'Area',
  'form.labels.arrivalAirport': 'Arrival Airport',
  'form.labels.arrivalFuel': 'Arrival Fuel',
  'form.labels.assembly': 'Assembly',
  'form.labels.ataChapter': 'ATA Chapter',
  'form.labels.attachments': 'Attachments',
  'form.labels.calendarDaysLimit': 'Calendar Days Limit',
  'form.labels.callsign': 'Callsign',
  'form.labels.category': 'Document category',
  'form.labels.chapter': 'Chapter',
  'form.labels.pic': 'PIC',
  'form.labels.position': 'Position',
  'form.labels.company': 'Company',
  'form.labels.confirm': 'Confirm',
  'form.labels.copySettingsExisting': 'Copy settings from existing aircraft?',
  'form.labels.copySettings': 'Copy settings from aircraft',
  'form.labels.cycles': 'Cycles',
  'form.labels.cyclesDue': 'Cycles Due',
  'form.labels.cyclesLimit': 'Cycles Limit',
  'form.labels.date': 'Date',
  'form.labels.dateDue': 'Date Due',
  'form.labels.days': 'Days',
  'form.labels.default': 'Default',
  'form.labels.defectDetails': '{field} Details',
  'form.labels.defectExtendedOn': 'Extended On',
  'form.labels.defectExtensionDateDue': 'New Date Due',
  'form.labels.deferralPeriod': 'Deferral Period',
  'form.labels.deferred': 'Deferred?',
  'form.labels.departureAirport': 'Departure Airport',
  'form.labels.description': 'Description',
  'form.labels.documentInfoText': 'You may want to specify the following details of your document.',
  'form.labels.duplicateFromAircraft': 'Duplicate from aircraft',
  'form.labels.email': 'Email',
  'form.labels.enabled': 'Enabled?',
  'form.labels.endTime': 'End Time',
  'form.labels.engineerAuthorisationNumber': "Engineer's Authorisation/Licence Number",
  'form.labels.engineerFirstName': "Engineer's First Name",
  'form.labels.engineerFirstNameOpt': "Engineer's First Name (opt.)",
  'form.labels.engineerLastName': "Engineer's Last Name",
  'form.labels.engineerLastNameOpt': "Engineer's Last Name (opt.)",
  'form.labels.enterTitle': 'Title',
  'form.labels.eropsPrevented': '{field} Prevented',
  'form.labels.event': 'Event',
  'form.labels.fields': 'Fields',
  'form.labels.filterByAircraft': 'Filter by aircraft',
  'form.labels.firstName': 'First Name',
  'form.labels.sic': 'SIC',
  'form.labels.fleetNumber': 'Fleet Number',
  'form.labels.flightLimit': 'Flight Limit',
  'form.labels.flightDaysLimit': 'Flight Days Limit',
  'form.labels.flightHours': 'Flight Hours',
  'form.labels.flightHourLimit': 'Flight Hours Limit',
  'form.labels.flightHoursDue': 'Flight Hours Due',
  'form.labels.fluidRatio': 'Fluid Ratio',
  'form.labels.fuel': 'Fuel',
  'form.labels.fuelAdjustment': 'Fuel Adjustment',
  'form.labels.fuelCapacity': 'Fuel Capacity',
  'form.labels.fuelUnit': 'Fuel Unit',
  'form.labels.groundServices': 'Ground Services',
  'form.labels.hobbsArrival': 'Hobbs Arrival',
  'form.labels.hobbsDeparture': 'Hobbs Departure',
  'form.labels.hobbsTotal': 'Hobbs Total',
  'form.labels.holdover': 'Holdover',
  'form.labels.hours': 'Hours',
  'form.labels.hoursLimit': 'Hours Limit',
  'form.labels.calendarHoursLimit': 'Calendar Hours Limit',
  'form.labels.initialFuel': 'Initial Fuel',
  'form.labels.interval': 'Interval',
  'form.labels.item': 'Item',
  'form.labels.itemNumber': 'Item No.',
  'form.labels.jlTemplate': 'Journey Log Template',
  'form.labels.landing': 'Landing',
  'form.labels.landings': 'Landings',
  'form.labels.lastName': 'Last Name',
  'form.labels.leonYourOprId': 'yourOprId',
  'form.labels.lifeMonths': 'Life (Months)',
  'form.labels.lifespan': 'Lifespan',
  'form.labels.maintenanceProcedure': 'Maintenance Procedure',
  'form.labels.masterTitle': 'Master Title',
  'form.labels.mel': 'MEL PDF',
  'form.labels.months': 'Months',
  'form.labels.newDeferralPeriod': 'New deferral period:',
  'form.labels.nextCheckApu': 'Next APU Check',
  'form.labels.nextCheckCycles': 'Next Check (cycles)',
  'form.labels.nextCheckDate': 'Next Check (date)',
  'form.labels.nextCheckHours': 'Next Check (hours)',
  'form.labels.no': 'No.',
  'form.labels.notes': 'Notes',
  'form.labels.offBlocks': 'Off Blocks',
  'form.labels.oil': 'Oil',
  'form.labels.oilUnit': 'Oil Unit',
  'form.labels.oilUplift': 'Oil Uplift',
  'form.labels.onBlocks': 'On Blocks',
  'form.labels.operator': 'Operator',
  'form.labels.operationalLimitations': 'Operational Limitations',
  'form.labels.operationalProcedure': 'Operational Procedure',
  'form.labels.operatorName': 'Operator name',
  'form.labels.optional': '(optional)',
  'form.labels.organisation': 'Organisation',
  'form.labels.other': 'Other',
  'form.labels.otherLimit': 'Other Limit',
  'form.labels.pageNo': 'Page No.',
  'form.labels.part145Approval': '{field} Approval',
  'form.labels.part145Organisation': '{field} Organisation',
  'form.labels.partNum': 'Part No.',
  'form.labels.password': 'Password',
  'form.labels.placards': 'Placards',
  'form.labels.placardProcedure': 'Placard Procedure',
  'form.labels.plannedUplift': 'Planned Uplift',
  'form.labels.qtyInstalled': 'Qty Installed',
  'form.labels.qtyRequired': 'Qty Required',
  'form.labels.quantity': 'Quantity',
  'form.labels.reporter': 'Reporter',
  'form.labels.recordType': 'Record Type',
  'form.labels.rectificationIntervalExtension': 'Rectification Extension',
  'form.labels.registration': 'Registration',
  'form.labels.remarks': 'Remarks',
  'form.labels.resolutionDate': 'Resolution Date',
  'form.labels.resolutionDateUTC': 'Resolution Date (UTC)',
  'form.labels.resolutionNotes': 'Notes',
  'form.labels.resolutionType': 'Resolution Type',
  'form.labels.role': 'Role',
  'form.labels.section': 'Section',
  'form.labels.selectedAircraft': 'Selected Aircraft',
  'form.labels.sequenceNumber': 'Sequence No.',
  'form.labels.serialNum': 'Serial No.',
  'form.labels.setup': 'Setup',
  'form.labels.selectCategory': 'Category',
  'form.labels.signature': 'Signature',
  'form.labels.srpNo': 'Start SRP No.',
  'form.labels.srpTemplate': 'SRP Template',
  'form.labels.source': 'Source',
  'form.labels.startTime': 'Start Time',
  'form.labels.status': 'Status',
  'form.labels.subSection': 'Subsection',
  'form.labels.subSectionTitle': 'Subsection Title',
  'form.labels.system': 'System',
  'form.labels.takeOff': 'Takeoff',
  'form.labels.taskRef': 'Task Reference',
  'form.labels.times': 'Times',
  'form.labels.timeLimit': 'Time Limit',
  'form.labels.title': 'Title',
  'form.labels.trip': 'Trip',
  'form.labels.type': 'Type',
  'form.labels.unit': 'Unit',
  'form.labels.username': 'Username',
  'form.labels.year': 'Year',
  'form.labels.validRange': 'Document valid range',
  'form.labels.details': 'Details',
  'form.labels.operatorReference': 'Operator Ref.',
  'form.labels.additionalTasks': 'Did you carry out any additional work not described in the workpack?',
  'form.labels.additionalTasksList': 'Submitted tasks',
  'form.labels.admin': 'Admin',
  'form.labels.taskRefNo': 'Task Reference No.',
  'form.labels.taskRefDetails': 'Details',
  'form.labels.tripNum': 'Trip #',
  'form.labels.documents': 'Documents',
  'form.labels.resolutionDetails': 'Please provide resolution details.',
  'form.labels.limitations': 'Limitations',
  'form.labels.limitationsSpecify': 'Limitations (Please specify)',
  'form.labels.partNumber': 'Part Number',
  'form.labels.partPosition': 'Position',
  'form.labels.traceOff': 'Trace Off',
  'form.labels.partDescription': 'Description',
  'form.labels.partNumberOff': 'Part Number Off',
  'form.labels.partNumberOn': 'Part Number On',
  'form.labels.serialOff': 'Serial Off',
  'form.labels.serialOn': 'Serial On',
  'form.labels.batchLotNumber': 'Batch/Lot Number',
  'form.labels.batchNumberOn': 'Batch number on',
  'form.label.firstName': 'First Name',
  'form.label.lastName': 'Last Name',
  'form.label.email': 'Email',
  'form.labels.crsDocument': 'CRS Document',
  'form.labels.signedBy': 'Signed by',
  'form.labels.workpackRefNo': 'Workpack Ref. No.',
  'form.labels.name': 'Name',
  'form.labels.taskDescription': 'Task Description',
  'form.labels.threshold': 'Threshold',
  'form.labels.tolerance': 'Tolerance',
  'form.labels.nextDue': 'Next Due',
  'form.option.addNewTrip': '+ Add a new Trip',
  'form.option.aircraft': 'Aircraft',
  'form.option.convert': 'Convert',
  'form.option.deferred': 'Deferred',
  'form.option.delete': 'Delete',
  'form.option.edit': 'Edit',
  'form.option.noFault': 'No fault found',
  'form.option.rectified': 'Rectified',
  'form.option.replaced': 'Replaced',
  'form.option.draft': 'Draft',
  'form.option.active': 'Active',
  'form.option.complete': 'Complete',
  'form.option.pending': 'Pending Acceptance',
  'form.option.organisation': 'Organisation',
  'form.option.master': 'Master',
  'form.placeholder.aircraftLimitations':
    'Please enter limitations that will be shown to the crew when this item is selected',
  'form.placeholder.email': 'Email',
  'form.placeholder.enterTitle': 'Enter a title',
  'form.placeholder.enterTripNumber': 'Enter trip no.',
  'form.placeholder.filterData': 'Filter table data...',
  'form.placeholder.firstName': 'First Name',
  'form.placeholder.lastName': 'Last Name',
  'form.placeholder.mainLandingGear': 'eg. Main Landing Gear',
  'form.placeholder.noFiltersApplied': 'No filters applied',
  'form.placeholder.optionalTitle': 'Optional Title',
  'form.placeholder.organisation': 'Organisation',
  'form.placeholder.pleaseSelect': 'Please select',
  'form.placeholder.placardProcedure': 'Please enter placard procedure',
  'form.placeholder.operationalProcedure': 'Please enter operational procedure',
  'form.placeholder.maintenanceProcedure': 'Please enter maintenance procedure',
  'form.placeholder.remarks': 'Please enter remarks',
  'form.placeholder.selectCategory': 'Select a category',
  'form.placeholder.registration': 'eg. G-ABCD',
  'form.placeholder.searchATAChapter': 'Search for ATA chapter',
  'form.placeholder.searchByUser': 'Search by e.g. name, email or role',
  'form.placeholder.searchHelpdesk': 'Search Helpdesk',
  'form.placeholder.searchMX': 'Search by e.g. ref number...',
  'form.placeholder.searchRegistration': 'Search registration',
  'form.placeholder.selectAircraft': 'Select an aircraft',
  'form.placeholder.selectAirport': 'Select an airport',
  'form.placeholder.selectPilotInCommand': 'Select Pilot in Command',
  'form.placeholder.selectSecondInCommand': 'Select Second in Command',
  'form.placeholder.selectPerson': 'Select a person',
  'form.placeholder.serialNo': 'eg. 560-001',
  'form.placeholder.source': 'Source',
  'form.placeholder.washer': 'eg. Washer',
  'form.placeholder.searchAircraft': 'Search aircraft...',
  'form.placeholder.selectMELItem': 'Select MEL Item',
  'form.placeholder.search': 'Search...',
  'form.placeholder.searchTrips': 'Search trips...',
  'form.placeholder.taskRefNo': 'XXXXXXX',
  'form.placeholder.taskRefDetails': 'Enter details of the performed task...',
  'form.placeholder.yourFirstName': 'Your First Name',
  'form.placeholder.yourLastName': 'Your Last Name',
  'form.placeholder.yourPart145Org': 'Your Part 145 Organisation',
  'form.placeholder.yourPart145App': 'Your Part 145 Approval',
  'form.placeholder.resolutionNotes': 'Additional information (optional)',
  'form.placeholder.enterDetails': 'Enter details here...',
  'form.placeholder.enterLimitations': 'Enter limitations here...',
  'form.placeholder.xxx': 'XXXXXXXX',
  'form.placeholder.xxxx': 'XXXX',
  'form.placeholder.yourWorkpackRefNo': 'Your Workpack Reference Number',
  'form.placeholder.addSelectWorkpack': 'Select or add a new workpack...',
  'form.placeholder.selectWorkpack': 'Select workpack...',
  'form.placeholder.selectArea': 'Please select area',
  'form.placeholder.itemName': 'Enter a description of the task to be completed',
  'form.placeholder.searchATA': 'Search for ATA Chapter',
  'form.radio.active': 'Active',
  'form.radio.paused': 'Paused',
  'form.question.areYouSureConvertFault':
    'Are you sure you want to convert this intermittent fault into a defect? This action cannot be undone and original intermittent fault will be hidden',
  'form.question.areYouSureDelete': 'Are you sure you want to delete this item?',
  'form.question.areYouSureDeleteCabinIssue': 'Are you sure you want to delete this cabin issue?',
  'form.question.areYouSureDeleteDefect': 'Are you sure you want to delete this defect?',
  'form.question.areYouSureDeleteNote': 'Are you sure you want to delete this note?',
  'form.question.areYouSureDeleteFault': 'Are you sure you want to delete this fault?',
  'form.question.areYouSureDeleteSubscription': 'Are you sure you want to delete this subscription?',
  'form.question.areYouSureDeleteUser': 'Are you sure you want to delete this user?',
  'form.question.areYouSureDeleteTemplate': 'Are you sure you want to delete this template?',
  'form.question.areYouSureDeleteTrip': 'Are you sure you want to delete this Trip?',
  'form.question.areYouSureDeleteSector': 'Are you sure you want to remove this {sector} from the Trip?',
  'form.question.areYouSureDeleteWorkpack': 'Are you sure you want to delete this workpack?',
  'form.question.areYouSureIncompleteItems':
    'Are you sure you have completed all tasks? There are unresolved items, these will be moved to a new workpack',
  'form.question.areYouSureDeleteChapter': 'Are you sure you want to delete {field}?',
  'form.question.areYouSureDeleteDispatchCondition': 'Are you sure you want to delete this dispatch condition?',
  'form.question.areYouSureDeleteItem': 'Are you sure you want to delete {field}?',
  'form.question.areYouSureDeleteMEL': 'Are you sure you want to delete this MEL?',
  'form.question.areYouSureDeleteRectification': 'Are you sure you want to delete this rectification?',
  'form.question.areYouSureMultiApprove': 'Are you sure you want to approve these items?',
  'form.question.areYouSureMultiDelete': 'Are you sure you want to delete these items?',
  'form.question.areYouSureDisable': 'Are you sure you want to disable this integration?',
  'form.question.areYouSureRemoveCrew': 'Are you sure you want to remove this crew member from aircraft?',
  'form.question.areYouSureRemoveCrewMultiple': 'Are you sure you want to delete these crew members from aircraft?',
  'form.question.confirmDeicingDelete': 'Are you sure you want to delete this deicing?',
  'form.question.confirmMELSectionDelete': 'Are you sure you want to remove this item?',
  'form.question.confirmUpliftDelete': 'Are you sure you want to delete this uplift?',
  'form.question.settings': 'What are Log Settings?',
  'form.question.areYouSureReset': 'Are you sure you want to reset this password?',
  'form.tooltip.startTypingAirports': 'Start typing to display airports',
  'form.tooltip.startTypingTypes': 'Start typing to display types',
  'form.title.nextMaintenance': 'Next Scheduled Maintenance Due',
  'form.title.nextChecks': 'Next Checks',
  'form.title.startingTotals': 'Starting Totals',
  'form.title.addFlightHours': 'Add Flight Hours Limit',
  'form.title.addCycles': 'Add Flight Cycle Limit',
  'form.title.addMonths': 'Add Flight Month Limit',
  'form.title.addAPUHours': 'Add APU Hour Limit',
  'form.helper.signatureText':
    'I confirm that to the best of my knowledge the information submitted is complete and correct.',
  'form.helper.signatureTextEngineer':
    'Certifies that the work specified, except as otherwise specified, was carried out in accordance with Part 145 and in respect to that work, the aircraft / aircraft component is considered ready for release to service.',
  'form.helper.defectSignatureText':
    'I confirm to my best knowledge the information submitted is complete and correct.',
  'form.message.addItemName': 'Please add item name',
  'form.message.selectATA': 'Please select ATA Chapter',
  'form.message.inputPartNo': 'Please input Part No.',
  'form.message.inputSerialNo': 'Please input Serial No.',
  'text.company': 'Company',
  'text.companyName': 'Company Name',
  'text.complete': 'Complete',
  'text.completionDate': 'Completion date',
  'text.completionDateTooltip':
    'When completing the scheduled maintenance would you like the item to be repeated from the date the maintenance is resolved or the next due date',
  'text.role': 'Role',
  'text.roles': 'Role',
  'title.actions': 'Actions',
  'title.additionalInformation': 'Additional information',
  'title.addNewDefect': 'Add a new {field}',
  'title.addNewMEL': 'Add a new MEL',
  'title.addNewTicket': 'Add New Ticket',
  'title.addNewTrip': 'Add a new Trip',
  'title.addNewUser': 'Add New User',
  'title.aircraft': 'Aircraft',
  'title.aircraftCreated': 'Aircraft successfully created',
  'title.aircraftCrew': 'Aircraft Crew',
  'title.aircraftDocuments': 'Aircraft Documents',
  'title.aircraftFlightLog': 'Aircraft Flight Log',
  'title.aircraftLimitationsApplicable': 'Aircraft Limitations as applicable',
  'title.aircraftMEL': 'Aircraft MEL',
  'title.aircraftSettings': 'Aircraft Settings',
  'title.airframeHours': 'Airframe Hours',
  'title.airport': 'Airport',
  'title.amount': 'Amount',
  'title.approvedBy': 'Approved By',
  'title.apuHours': 'APU Hrs',
  'title.apuCycles': 'APU Cycles',
  'title.approvedItems': 'Approved Items',
  'title.arrival': 'Arrival',
  'title.ata': 'ATA',
  'title.attachments': 'Attachments',
  'title.attachment': 'Attachments',
  'title.attachmentsLower': 'attachments',
  'title.attachmentLower': 'attachments',
  'title.approveItem': 'Approve Item',
  'title.approveItems': 'Approve Items',
  'title.area': 'Area',
  'title.assembly': 'Assembly',
  'title.cabinLog': 'Cabin Log',
  'title.callsign': 'Callsign',
  'title.pic': 'PIC',
  'title.category': 'Category',
  'title.categoryCaps': 'CATEGORY',
  'title.certificateOfRelease': 'Certificate of Release to Service',
  'title.chapter': 'Chapter',
  'title.ataChapter': 'ATA Chapter',
  'title.ataChapterCaps': 'ATA CHAPTER',
  'title.ataSection': 'ATA Section',
  'title.ataSectionCaps': 'ATA SECTION',
  'title.changeDeferralPeriod': 'Change the deferral period',
  'title.changesNotSaved': 'Changes have not been saved',
  'title.columns': 'Columns',
  'title.contents': 'CONTENTS',
  'title.confirmCancel': 'You have made changes, are you sure to cancel?',
  'title.confirmDelete': 'Confirm delete?',
  'title.convertDefect': 'Convert to defect',
  'title.createdAt': 'Created at',
  'title.createdBy': 'Created by',
  'title.createNewAircraft': 'Create new aircraft',
  'title.createNewSubscription': 'Create a new subscription',
  'title.crew': 'Crew',
  'title.CRSSignature': 'CRS Signature',
  'title.currency': 'Currency',
  'title.currentStatus': 'Current Status',
  'title.cycles': 'Cycles',
  'title.cyclesCaps': 'CYCLES',
  'title.damageType': 'Damage Type',
  'title.date': 'Date',
  'title.dateIssued': 'Date Issued',
  'title.day': 'Day',
  'title.days': 'Days',
  'title.daysRemaining': 'Days Remaining',
  'title.daysRemainingShort': 'Days Rem.',
  'title.default': 'Default',
  'title.defectId': 'Defect ID',
  'title.defectNum': 'Defect #',
  'title.defectReport': 'Defect Report',
  'title.defectReportSignature': 'Defect Report Signature',
  'title.defects': 'Defects',
  'title.deferredUntil': 'DEFERRED UNTIL',
  'title.dispatchConditionTitle': 'Dispatch Condition Title',
  'title.deleteDispatchCondition': 'Delete Dispatch Condition',
  'title.deleteDefect': 'Delete Defect',
  'title.deleteDeicing': 'Delete Deicing',
  'title.deleteItem': 'Delete Item',
  'title.deleteItems': 'Delete Items',
  'title.deleteChapter': 'Delete Chapter',
  'title.deleteMel': 'Delete Mel',
  'title.deleteRectification': 'Delete Rectification',
  'title.deleteSubscription': 'Delete Subscription',
  'title.deleteWorkpack': 'Delete Workpack',
  'title.deleteUser': 'Delete User',
  'title.deleteUplift': 'Delete Uplift',
  'title.designator': 'Designator',
  'title.details': 'Details',
  'title.departure': 'Departure',
  'title.deferralReportSignature': 'Defect Report Signature',
  'title.deicing': 'Deicing',
  'title.description': 'Description',
  'title.due': 'due',
  'title.draftItems': 'Draft Items',
  'title.draftRequirements': 'Draft Requirements',
  'title.draftRequirement': 'Draft Requirement',
  'title.edit': 'Edit',
  'title.editDefect': 'Editing {field}: ',
  'title.editDeferral': 'Edit deferral period',
  'title.editMEL': 'Edit MEL',
  'title.editWorkpack': 'Edit Workpack',
  'title.email': 'Email',
  'title.emailaddress': 'Email Address',
  'title.enable': 'Enable',
  'title.engShort': 'Eng',
  'title.eropsPrevented': 'EROPS Prevented',
  'title.errorLinking': 'Unable to link account',
  'title.estimatedDue': 'Est. Due',
  'title.estDueCaps': 'EST DUE',
  'title.estimatedDueLong': 'Estimated Due',
  'title.estimatedShort': 'Est',
  'title.event': 'Event',
  'title.fileName': 'File Name',
  'title.filePreview': 'File Preview',
  'title.filters': 'Filters',
  'title.firstName': 'First Name',
  'title.sic': 'SIC',
  'title.fleet': 'Fleet',
  'title.flightHrs30d': 'Flight hours in the last 30 days',
  'title.flightDaysLimit': 'Flight Days Limit',
  'title.flightsLimit': 'Flights Limit',
  'title.flightsTop30': '{sectors} (Top 30)',
  'title.flightDetails': 'Flight Details',
  'title.flightHoursLimit': 'Flight Hours Limit',
  'title.flightHrs': 'Flight Hrs',
  'title.flightLandings': 'Flight Landings',
  'title.flights30d': '{sectors} (last 30 days)',
  'title.flightsTooltip30d': '{sectors} in the last 30 days',
  'title.fo': 'FO',
  'title.from': 'From',
  'title.fuel': 'Fuel',
  'title.fuelReceiptManagement': 'Fuel Receipt Management',
  'title.fuelUsage': 'Fuel Usage',
  'title.generalSettings': 'General Settings',
  'title.generalSettingsReg': 'General Settings ({field})',
  'title.generalSettingsSub': 'You can amend the settings of the aircraft below',
  'title.globalPermissions': 'Global Permissions',
  'title.group': 'Group',
  'title.helpdesk': 'Helpdesk',
  'title.history': 'History',
  'title.hours': 'Hours',
  'title.hoursCaps': 'HOURS',
  'title.hoursLimit': 'Hours Limit',
  'title.hours&flights': 'Hours & {sectors} (last 30 days)',
  'title.hoursTop30': 'Hours (Top 30)',
  'title.hours30d': 'Hours (last 30 days)',
  'title.id': 'ID',
  'title.integrations': 'Integrations',
  'title.interval': 'Interval',
  'title.lifespan': 'Lifespan',
  'title.item': 'Item',
  'title.itemRef': 'Item Ref.',
  'title.justification': 'Justification',
  'title.lastActive': 'Last Active',
  'title.landing': 'Land',
  'title.lanDing': 'Landing',
  'title.landings': 'Landings',
  'title.lastFlown': 'Last Flown',
  'title.lastFlownCaps': 'LAST FLOWN',
  'title.lastEdited': 'Last Edited',
  'title.lastName': 'Last Name',
  'title.lastUpdated': 'Last Updated',
  'title.lifeLimitedParts': 'Life Limited Parts',
  'title.limitations': 'Limitations',
  'title.licenceNumber': 'Licence Number',
  'title.licenceAuthorisationNo': 'Licence/Authorisation no',
  'title.location': 'Location',
  'title.logSettings': 'Log Settings',
  'title.maintenance': 'Maintenance',
  'title.maintenanceDescriptionView': "View and manage all of {registration}'s maintenance and defect items.",
  'title.maintenanceDescriptionCreate':
    "View and manage all of {registration}'s maintenance and defect items. Create and schedule workpacks.",
  'title.maintenanceWarnings': 'Maintenance Warnings',
  'title.manageTrips': 'Manage Trips',
  'title.manufacturer': 'Manufacturer',
  'title.masterMels': 'Master MELs',
  'title.masterMel': 'Master MEL',
  'title.mel': 'MEL',
  'title.melChapters': 'MEL Chapters',
  'title.mmelChapters': 'MMEL Chapters',
  'title.mels': 'MELs',
  'title.myMels': 'My MELs',
  'title.metrics': 'Metrics',
  'title.model': 'Model',
  'title.months': 'Months',
  'title.mostFlights': 'Most {sectors}',
  'title.mostHours': 'Most Hours',
  'title.mostVisited': 'Most Visited',
  'title.mostVisitedAirports': 'Most Visited Airports',
  'title.mxThresholdsFor': 'Maintenance warnings for ({field})',
  'title.pageEditMel': 'Editing {melType} MEL for {melAircraft}',
  'title.name': 'Name',
  'title.nextDue': 'Next Due',
  'title.new': 'New',
  'title.newWorkpack': 'New Workpack',
  'title.notAvailableShort': 'N/A',
  'title.notes': 'Notes',
  'title.numAircraft': 'No. of Aircraft',
  'title.numberInstalled': 'Number Installed',
  'title.numberRequired': 'Number Required',
  'title.numberRequiredDispatch': 'Number required for dispatch',
  'title.numberShort': 'No.',
  'title.newTypeRequirement': 'New {type} Requirement',
  'title.oilUplift': 'Oil Uplift',
  'title.operator': 'Operator',
  'title.organisation': 'Organisation',
  'title.organisationDocuments': 'Organisation Documents',
  'title.organisationMEL': 'Organisation MEL',
  'title.other': 'Other',
  'title.outOfPhaseItems': 'Out of Phase Items',
  'title.overview': 'Overview',
  'title.owner': 'Owner',
  'title.paid': 'Paid',
  'title.part145Signature': 'Part 145 Signature',
  'title.partNum': 'Part No.',
  'title.part': 'Part',
  'title.pleaseSelectThreshold': 'Please select values to be considered as warning state',
  'title.preview': 'Preview',
  'title.quantity': 'Quantity',
  'title.receipt': 'Receipt',
  'title.recordType': 'Record Type',
  'title.rectificationCategory': 'Rectification Category',
  'title.rectificationDetails': 'Rectification Details',
  'title.rectificationInterval': 'Rectification Interval',
  'title.rectificationIntervalCaps': 'RECTIFICATION INTERVAL',
  'title.rectificationTitle': 'Rectification Title',
  'title.reg': 'Reg',
  'title.ref': 'Ref.',
  'title.reference': 'Reference',
  'title.referenceCaps': 'REFERENCE',
  'title.registration': 'Registration',
  'title.remaining': 'Remaining',
  'title.removeCrew': 'Remove Crew Member',
  'title.reportBy': 'Reported By',
  'title.reportedBy': 'Reported By:',
  'title.resetPasswordEmail': 'Send password reset email?',
  'title.resetPasswordLink': 'Send password reset link',
  'title.resetPinLink': 'Send PIN reset link',
  'title.resolution': 'Resolution',
  'title.resolve': 'RESOLUTION',
  'title.resolvedBy': 'Resolved by',
  'title.resolvedOn': 'Resolved on',
  'title.role': 'Role',
  'title.routeMap': 'Route Map',
  'title.routes': 'Routes',
  'title.removedTypeRequirement': 'Removed {type} Requirement',
  'title.scheduledItems': 'Scheduled Items',
  'title.schedule': 'Schedule',
  'title.sector': 'Sector',
  'title.sectors': 'Sectors',
  'title.serial': 'Serial',
  'title.serialNum': 'Serial No.',
  'title.servicing': 'Servicing',
  'title.sharingLink': 'Sharing Link',
  'title.sharingLinkCaps': 'SHARING LINK',
  'title.shortDefectNum': 'DEF ',
  'title.srp': 'SRP',
  'title.startingTotals': 'Starting Totals',
  'title.status': 'Status',
  'title.statusCaps': 'STATUS',
  'title.subscriptions': 'Subscriptions',
  'title.sureToCancel': 'Sure to cancel?',
  'title.systemSequence': 'System, Sequence number & Item',
  'title.takeOff': 'T/O',
  'title.takeoff': 'Takeoff',
  'title.taskRef': 'Task Ref.',
  'title.templates': 'Templates',
  'title.ticket': 'Ticket',
  'title.ticketDetails': 'Ticket Details',
  'title.time': 'Time',
  'title.timestamp': 'Timestamp',
  'title.threshold': 'Threshold',
  'title.to': 'To',
  'title.totalLandings': 'Total Ldgs',
  'title.tripAttachments': 'Trip Attachments',
  'title.triplog': 'Trip Log',
  'title.trip': 'Trip',
  'title.tripNum': 'Trip Number',
  'title.trips': 'Trips',
  'title.recentTrips': 'Recent Trips',
  'title.tripSector': 'Trip-{sector}',
  'title.tripSectorNum': 'Trip/{sector} #',
  'title.timeline': 'Timeline',
  'title.type': 'Type',
  'title.update': 'Update',
  'title.updateSubscription': 'Update a subscription',
  'title.editSubscription': 'Edit subscription',
  'title.editing': 'Editing: ',
  'title.uplift': 'Uplift',
  'title.uploadImage': 'Upload Image',
  'title.uploadSchematics': 'Upload schematics',
  'title.uploadAircraftDocument': 'Add a New Aircraft Document',
  'title.uploadOrganisationDocument': 'Add a New Organisation Document',
  'title.user': 'User',
  'title.userManagement': 'User Management',
  'title.updateTypeRequirement': 'Update to existing {type} Requirement',
  'title.queueTotal': 'Queue Total',
  'title.workpacks': 'Workpacks',
  'title.workpacksCount': '{field} ({count})',
  'title.intermittentFaultsCount': 'Intermittent Faults ({count})',
  'title.validFrom': 'Valid From',
  'title.validTo': 'Valid To',
  'title.viewAll': 'View all',
  'title.visits': 'Visits',
  'title.visitsTop30': 'Visits (Top 30)',
  'title.year': 'Year',
  'title.estimatedDueCaps': 'EST. DUE',
  'title.none': 'None',
  'title.dashboard': 'Dashboard',
  'title.defect': 'Defect',
  'title.tolerance': 'Tolerance',
  'title.remarks': 'Remarks',
  'title.remarksOrException': 'Remarks or Exceptions',
  'title.placard': 'Placards',
  'title.maintenanceProcedure': 'Maintenance Procedure',
  'title.operationalProcedure': 'Operational Procedure',
  'title.editAircraft': 'Edit Aircraft ({field})',
  'title.sureToClose': 'Are you sure to close this ticket?',
  'title.nefReferenceApplicableCaps': 'NEF REFERENCE (IF APPLICABLE)',
  'title.resolutionType': 'Resolution Type',
  'title.partNumber': 'Part Number',
  'title.serialOff': 'Serial Off',
  'title.serialOn': 'Serial On',
  'title.aircraftOverview': 'Aircraft Overview',
  'title.aircraftPermissions': 'Aircraft Permissions',
  'title.selectedAircraft': 'Selected Aircraft',
  'title.selectRepairedDefects': 'Select repaired {field}',
  'title.selectCompletedMXItems': 'Select completed maintenance items',
  'title.selectCompletedMXChecks': 'Select completed maintenance checks',
  'title.selectAdditionalTasks': 'Select additional tasks',
  'title.enterCompanyPersonalDetails': 'Enter your company and personal details',
  'title.enterRectificationIntervals': 'Enter Rectification Intervals (optional)',
  'title.signForCRS': 'Sign to create a {field}',
  'title.signToConfirmCRS': 'Sign to confirm {field}',
  'title.editResolution': 'Edit Resolution Details',
  'title.resolutionDetails': 'Resolution details',
  'title.userSettings': 'User Settings',
  'title.poNumber': 'PO Number',
  'title.workpacksToReview': 'There are {workpacks} new workpacks to review',
  'subtitle.step15': 'Step 1/5',
  'subtitle.step25': 'Step 2/5',
  'subtitle.step35': 'Step 3/5',
  'subtitle.step45': 'Step 4/5',
  'subtitle.step55': 'Step 5/5',
  'subtitle.step': 'Step {current}/{total}',
  'subtitle.showResolved': 'Show resolved',
  'subtitle.hideResolved': 'Hide resolved',
  'title.cyclesLimit': 'Cycles Limit',
  'title.cyclesRem': 'Cycles Rem.',
  'title.daysRem': 'Days Rem.',
  'title.daysLimit': 'Days Limit',
  'title.hoursRem': 'Hours Rem.',
  'title.apuRem': 'APU Rem.',
  'title.adding': 'Adding: ',
  'title.addingUser': 'Add a new user',
  'title.addMXItem': 'Add new Maintenance Item',
  'title.updateMXItem': 'Update Maintenance Item',
  'title.applyTolerance': 'Apply tolerance for:',
  'title.items': 'Items',
  'title.showResolved': 'Show resolved {type}',
  'title.hideResolved': 'Hide resolved {type}',
  'title.allResolved': 'All {type} resolved',
  'tab.busiestAircraftFlights': 'Busiest Aircraft (by {sectors})',
  'tab.busiestAircraftHours': 'Busiest Aircraft (by hours)',
  'tab.fuelDelta': 'Fuel Delta',
  'tab.llps': 'LLPs',
  'tab.oilConsumption': 'Oil Consumption',
  'tab.outOfPhase': 'Out of Phase',
  'tab.scheduled': 'Scheduled',
  'tab.topAirports': 'Top Airports',
  'tab.totals': 'Totals',
  'tab.listView': 'List View',
  'tab.schematicView': 'Schematic View',
  'tab.tableView': 'Table View',
  'label.sortBy': 'Sort by',
  'label.MELActions': 'MEL Actions',
  'label.MMELActions': 'MMEL Actions',
  'currency.CAD': 'CAD',
  'currency.EUR': 'EUR',
  'currency.GBP': 'GBP',
  'currency.USD': 'USD',
};
