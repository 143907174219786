import { Col, Icon, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams, withRouter } from 'react-router-dom';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import styles from './ExpandableCard.module.less';

const MXDetailsWorkpacks = ({ item, aircraftID }) => {
  const { formatMessage } = useIntl();
  const { id } = useParams();

  const workpackOverride = useGetOperatorSetting('workpack_name', id);
  const workpackPluralOverride = useGetOperatorSetting('workpack_name_plural', id);

  return item.workpacks && item.workpacks.length > 0 ? (
    <Row className={styles.mxWorkpacks}>
      <Col>
        <Col md={24}>
          <div className={styles.partTitle} data-test="workpacksTitle" data-testid="MXDetailsWorkpacks--Title">
            {workpackPluralOverride || formatMessage({ id: 'title.workpacks' })}:
          </div>
          <div className={styles.partDetails} data-test="workpacksDetails" data-testid="MXDetailsWorkpacks--Details">
            {item.workpacks.map((workpack) => {
              return (
                <div key={workpack.id}>
                  <span>
                    {workpack.po_number || formatMessage({ id: 'text.poNotSpecified' }, { po_short_name: 'PO' })}
                  </span>
                  <Link
                    to={{
                      pathname: `/aircraft/${aircraftID}/workpacks/log`,
                      state: { fromLocation: 'maintenance', workpackId: workpack.id, itemId: item.id },
                    }}
                    data-test="viewWorkpackLink"
                  >
                    {formatMessage({ id: 'text.viewWorkpack' }, { field: workpackOverride || 'Workpack' })}{' '}
                    <Icon type="right" />
                  </Link>
                </div>
              );
            })}
          </div>
        </Col>
      </Col>
    </Row>
  ) : null;
};

MXDetailsWorkpacks.propTypes = {
  aircraftID: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
};

export default withRouter(MXDetailsWorkpacks);
