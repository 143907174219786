import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Divider, Label, SectionHeader, Text } from '../../CommonStyledComponents/CommonStyledComponents';
import { Defect } from '../../../models/defects';

interface ViewLimitationsProps {
  defectData: Defect;
  eropsRename: string;
}

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
  @media (max-width: 451px) {
    grid-template-columns: 1fr;
  }
`;

const ViewLimitations: React.FC<ViewLimitationsProps> = ({ defectData, eropsRename }) => {
  const { formatMessage } = useIntl();

  return (
    <div data-testid="ViewLimitations">
      <SectionHeader data-testid="ViewLimitations--Header">
        {formatMessage({ id: 'form.labels.aircraftLimitations' })}
      </SectionHeader>
      <GridSection>
        <div data-testid="ViewLimitations--Limitations">
          <Label>{formatMessage({ id: 'form.labels.aircraftLimitations' })}</Label>
          <Text>{defectData?.limitations || '-'}</Text>
        </div>
        {defectData?.limitations ? (
          <div data-testid="ViewLimitations--EropsPrevented">
            <Label>{formatMessage({ id: 'form.labels.eropsPrevented' }, { field: eropsRename })}</Label>
            <Text>{defectData?.erops_prevented ? 'Yes' : 'No'}</Text>
          </div>
        ) : null}
      </GridSection>
      <Divider />
    </div>
  );
};

export default ViewLimitations;
