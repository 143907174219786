import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import TFButton from '../../TFButton/TFButton';
import ViewNewLayoutMelDetails from './ViewNewLayoutMelDetails';
import ViewMelDetails from './ViewMelDetails';

interface DisplayMelTableProps {
  defectData: any;
  requiredSettings: {
    showRichTextMel: boolean;
    melNameOverride: string;
  };
}

const DisplayMelTable: React.FC<DisplayMelTableProps> = ({ defectData, requiredSettings }) => {
  const [isBookTableVisible, setIsBookTableVisible] = useState(true);
  const { formatMessage } = useIntl();

  const handleMELTableClick = (): void => {
    setIsBookTableVisible(!isBookTableVisible);
  };

  return (
    <>
      <TFButton padding="0" clear handleClick={handleMELTableClick}>
        {isBookTableVisible
          ? formatMessage({ id: 'text.hideBookItemTable' })
          : formatMessage({ id: 'text.showBookItemTable' })}
      </TFButton>
      {requiredSettings.showRichTextMel ? (
        <ViewNewLayoutMelDetails defect={defectData} isBookTableVisible={isBookTableVisible} />
      ) : (
        <ViewMelDetails defect={defectData} isBookTableVisible={isBookTableVisible} />
      )}
    </>
  );
};

export default DisplayMelTable;
