import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import attachmentIcon from '../../assets/icon-filetype-attachments.svg';
import apuIcon from '../../assets/icon-table-apu.svg';
import bugIcon from '../../assets/icon-table-bug.svg';
import engineIcon from '../../assets/icon-table-engine.svg';
import fuelIcon from '../../assets/icon-table-fuel.svg';
import hydraulicIcon from '../../assets/icon-table-hydraulics.svg';
import idgIcon from '../../assets/icon-table-idg.svg';
import oilIcon from '../../assets/icon-table-oil.svg';
import { DashboardState } from '../../models';
import styles from './TripIconTray.module.less';

export const iconSrc = {
  attachment: attachmentIcon,
  apu: apuIcon,
  bug: bugIcon,
  engine: engineIcon,
  fuel: fuelIcon,
  hydraulic: hydraulicIcon,
  idg: idgIcon,
  oil: oilIcon
}

export interface TripInfo {
  id: string;
  attachment_count: number;
  any_apu_uplift: boolean;
  any_engine_uplift: boolean;
  any_oil_uplift: boolean;
  any_fuel_uplift: boolean;
  any_bug_uplift: boolean;
  any_idg_uplift: boolean;
  any_hydraulic_uplift: boolean;
}

interface TripIconTrayProps {
  tripInfo: TripInfo;
  onAttachmentClick: () => void;
}

const TripIconTray: React.FC<TripIconTrayProps> = ({ tripInfo, onAttachmentClick }) => {
  const { formatMessage } = useIntl();
  const {
    aircraft: { aircraftMap }
  } = useSelector((state: DashboardState) => state);
  const { id } = useParams<{ id: string }>();
  const standardFields = aircraftMap.get(id)?.standard_fields;

  const getUpliftText = (nameOverride: string, messageId: string) => {
    return nameOverride
      ? `${nameOverride} ${formatMessage({ id: `title.uplift` })}`
      : formatMessage({ id: messageId });
  };

  // the order of the objects in this array determines the icon order, from left to right
  const upliftConfig = [
    { 
      key: 'apu',
      show: tripInfo.any_apu_uplift,
      src: iconSrc.apu,
      alt: 'apu uplift icon',
      title: getUpliftText(standardFields?.apu?.name_override, 'text.apuUplift')
    },
    {
      key: 'engine',
      show: tripInfo.any_engine_uplift,
      src: iconSrc.engine,
      alt: 'engine uplift icon',
      title: formatMessage({ id: 'text.engineUplift' })
    },
    {
      key: 'oil',
      show: tripInfo.any_oil_uplift,
      src: iconSrc.oil,
      alt: 'oil uplift icon',
      title: getUpliftText(standardFields?.oil_uplifts?.name_override, 'text.oilUplift')
    },
    {
      key: 'fuel',
      show: tripInfo.any_fuel_uplift,
      src: iconSrc.fuel,
      alt: 'fuel uplift icon',
      title: formatMessage({ id: 'text.fuelUplift' })
    },
    {
      key: 'bug',
      show: tripInfo.any_bug_uplift,
      src: iconSrc.bug,
      alt: 'BUG uplift icon',
      title: getUpliftText(standardFields?.back_up_generator?.name_override, 'text.bugUplift')
    },
    {
      key: 'idg',
      show: tripInfo.any_idg_uplift,
      src: iconSrc.idg,
      alt: 'IDG uplift icon',
      title: getUpliftText(standardFields?.integrated_drive_generator?.name_override, 'text.idgUplift')
    },
    {
      key: 'hydraulic',
      show: tripInfo.any_hydraulic_uplift,
      src: iconSrc.hydraulic,
      alt: 'hydraulic uplift icon',
      title: getUpliftText(standardFields?.hydraulic_uplifts?.name_override, 'text.hydraulicUplift')
    }
  ] as const;

  return (
    <div className={styles.iconTrayWrapper}>
      {upliftConfig.map((config) => 
        config.show && <img key={config.key} src={config.src} alt={config.alt} title={config.title} />
      )}
      {tripInfo.attachment_count > 0 && (
        <button
          type="button"
          data-testid="TripIconTray-AttachmentButton"
          onClick={(e) => {
            e.stopPropagation();
            onAttachmentClick();
          }}
          className={styles.attachmentsWrapper}
        >
          <img src={iconSrc.attachment} alt="attachment icon" />
          <span>{tripInfo.attachment_count}</span>
        </button>
      )}
    </div>
  );
};

export default TripIconTray;