import _ from 'lodash';
import { BaseModel } from '..';
import { Aircraft, AircraftForPublicPage } from '../aircraft';
import { Attachment } from '../intermittentFaults';

const initialState: WorkpacksState = {
  workpacksMap: new Map(),
  lastFetched: 0,
  data: null,
  publicWorkpack: {},
};

export enum WorkpacksActionTypes {
  SAVE = 'workpacks/save',
  SAVE_ALL = 'workpacks/saveAll',
  SAVE_PUBLIC = 'workpacks/savePublic',
  DELETE = 'workpacks/delete',
  UPDATE = 'workpacks/update',
}

interface SaveWorkpacksActionType {
  type: WorkpacksActionTypes.SAVE;
  payload: Workpack;
}

interface SaveAllWorkpacksActionType {
  type: WorkpacksActionTypes.SAVE_ALL;
  payload: Workpack;
}

interface SavePublicWorkpacksActionType {
  type: WorkpacksActionTypes.SAVE_PUBLIC;
  payload: Workpack;
}

interface UpdateWorkpackActionType {
  type: WorkpacksActionTypes.UPDATE;
  payload: Workpack;
}

interface DeleteWorkpacksActionType {
  type: WorkpacksActionTypes.DELETE;
  payload: { removeId };
}

type WorkpacksAction =
  | SaveWorkpacksActionType
  | SaveAllWorkpacksActionType
  | SavePublicWorkpacksActionType
  | UpdateWorkpackActionType
  | DeleteWorkpacksActionType;

export default function (state = initialState, action: WorkpacksAction): WorkpacksState {
  switch (action.type) {
    case WorkpacksActionTypes.SAVE_ALL: {
      const workpacksMap = new Map(state.workpacksMap.entries());
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((workpack) => {
          const combinedWorkpack = _.merge(workpacksMap.get(workpack.id), workpack);
          workpacksMap.set(workpack.id, combinedWorkpack);
        });
      }
      return {
        ...state,
        workpacksMap,
        lastFetched: Date.now(),
      };
    }
    case WorkpacksActionTypes.SAVE: {
      const workpacksMap = new Map(state.workpacksMap.entries());
      if (action.payload && action.payload.id) {
        workpacksMap.set(action.payload.id, {
          ...workpacksMap.get(action.payload.id),
          ...action.payload,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        workpacksMap,
      };
    }
    case WorkpacksActionTypes.SAVE_PUBLIC: {
      return {
        ...state,
        publicWorkpack: {
          ...action.payload,
          lastFetched: Date.now(),
        },
      };
    }
    case WorkpacksActionTypes.UPDATE: {
      const newWorkpacksMap = new Map(state.workpacksMap.entries());
      if (action.payload && action.payload.id) {
        newWorkpacksMap.set(action.payload.id, {
          ...action.payload,
          lastFetched: Date.now(),
        });
      }
      return {
        ...state,
        workpacksMap: newWorkpacksMap,
      };
    }
    case WorkpacksActionTypes.DELETE: {
      const workpacksMap = new Map(state.workpacksMap.entries());
      if (action.payload.removeId) {
        workpacksMap.delete(action.payload.removeId);
      }
      return {
        ...state,
        workpacksMap,
        lastFetched: Date.now(),
      };
    }
    default:
      return state;
  }
}

export interface WorkpacksState {
  workpacksMap: Map<string, Workpack>;
  lastFetched: number;
  data: Workpack;
  publicWorkpack: object;
}

export interface Workpack extends BaseModel {
  id: string;
  aircraft_id: string;
  aircraft?: Aircraft;
  po_number: string;
  reference: string;
  description: string;
  status: string;
  release_date: string;
  release: any;
  attachments: Array<any>;
  notes: string;
  date: string;
  mx_checks: MXChecks;
  versions: any[];
  workpack_versions: any[];
  feature_flags?: any[];
  workpack_items: {
    id: string;
    mx_item_id: string;
    mx_item: {
      type: string;
      id: string;
      draft: false;
      status: string;
      mx_type: string;
      last_updated: number;
      name: string;
      reference: unknown;
      ata_chapter: unknown;
      ata_number: unknown;
      assembly: unknown;
      repeat_remarks: unknown;
      date_due: unknown;
      flight_seconds_due: number;
      flight_seconds_tolerance: unknown;
      flight_seconds_repeat: unknown;
      flight_seconds_threshold: unknown;
      flight_seconds_remaining: number;
      apu_seconds_due: unknown;
      apu_seconds_tolerance: unknown;
      apu_seconds_repeat: unknown;
      apu_seconds_threshold: unknown;
      apu_seconds_remaining: unknown;
      cycles_due: unknown;
      cycles_tolerance: unknown;
      cycles_repeat: unknown;
      cycles_threshold: unknown;
      cycles_remaining: unknown;
      days_tolerance: unknown;
      days_threshold: unknown;
      days_remaining: unknown;
      mx_events?: Array<MXEvents>;
      months_repeat: unknown;
      months_tolerance: unknown;
      months_threshold: unknown;
      part_number: unknown;
      part_serial: unknown;
      estimated_date: string;
    };
  }[];
}

export interface WorkpackForPublicPage extends Workpack {
  public_url_key?: string;
  aircraft?: AircraftForPublicPage;
}

export interface MXEvents {
  date: string;
  description: string;
  id: string;
  limitations: string;
  part_changes: Array<any>;
  release: Release;
  work_type: any;
}

export interface Release {
  additional_items: Array<any>;
  approval_number: string;
  attachments: Array<Attachment>;
  company_name: string;
  company_email?: string;
  first_name: string;
  id: string;
  last_name: string;
  licence_number: string;
  reporter_first_name: string;
  reporter_last_name: string;
  signature: string;
  signature_statement: string;
  date?: string;
  work_type?: string;
  limitations?: string;
  description?: string;
  part_changes?: Array<PartChange>;
  mx_item_resolved?: boolean;
  public_signature_image_url?: string;
  release_id?: string;
  release_notes?: string;
  signature_data?: string;
  signature_image_url?: string;
  signature_url?: string;
  workpack_reference?: string;
}

export interface PartChange {
  batch: string;
  batch_off: string;
  description: string;
  part_number: string;
  part_number_on: string;
  position: string;
  serial_off: string;
  serial_on: string;
}

export interface MXChecks {
  date: string;
  types: string[];
}
