/* eslint-disable jsx-a11y/label-has-associated-control */
import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ToastCategories, ToastTypes, addToast } from '../../../models/toasts';
import calendarIcon from '../../../assets/icon-small-calendar.svg';
import { AircraftResource } from '../../../models/aircraft';
import AuthDropdownMenu from '../../AuthDropdownMenu/AuthDropdownMenu';
import StatusBadge from '../../Status/StatusBadge';
import { DashboardState } from '../../../models';
import TFModal from '../../TFModal/TFModal';
import styles from '../workpackDrawer.module.less';
import RadioInputButton from '../../RadioInputButton/RadioInputButton';
import { getPublicShareURL } from '../../../services/api';
import UnsavedConfirmationButton from './UnsavedConfirmationButton';

const EditTitleInfo = ({
  editStagedPayload,
  description = '',
  po = '',
  date,
  status,
  loading,
  numberOfDraftItems,
  hasAnyDraftItems,
  operatorSettings,
}): JSX.Element => {
  const [workpackDate, setWorkpackDate] = useState(null);
  const [localStatus, setLocalStatus] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const { formatMessage } = useIntl();

  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const handleDateChange = (dateMoment, dateString: string): void => {
    setWorkpackDate(dateMoment);
    editStagedPayload({ date: dateString });
  };

  const handleToggle = (value: boolean): void => {
    if (value && hasAnyDraftItems) {
      setLocalStatus(true);
      setModalVisible(true);
    } else {
      editStagedPayload({ status: value ? 'active' : 'draft' });
      setLocalStatus(value);
    }
  };

  const handleCancelClick = () => {
    setLocalStatus(false);
    setModalVisible(false);
  };

  const handleContinueClick = () => {
    setModalVisible(false);

    editStagedPayload({ status: 'active' });
  };

  useEffect(() => {
    if (status === 'active') setLocalStatus(true);
  }, [status]);

  useEffect(() => {
    if (date) setWorkpackDate(date);
  }, [date]);
  return (
    <>
      <div className={styles.rowGrid}>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>
            {`${operatorSettings?.po_short_name} ${operatorSettings?.po_suffix}`}
          </span>
          <input
            type="text"
            defaultValue={po}
            onChange={(e): void => editStagedPayload({ po_number: e.target.value })}
            className={styles.formInput}
            disabled={loading}
          />
        </div>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.dateCreated' })}</span>
          <DatePicker
            suffixIcon={<span />}
            value={workpackDate ? moment(workpackDate) : moment()}
            format={dateFormat}
            onChange={handleDateChange}
            disabled={loading}
          />
        </div>
        <div className={`${styles.rowItem} ${styles.statusRow}`}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.statusToggle' })}</span>
          <div className={styles.toggleWrapper}>
            {status === 'completed' ? (
              <span className={styles.statusInfo}>
                <StatusBadge status={status} /> {status}
              </span>
            ) : (
              <div className={styles.toggleWrapper}>
                <RadioInputButton
                  text="Draft"
                  active={localStatus === false}
                  marginRight="8px"
                  onClick={() => {
                    handleToggle(false);
                  }}
                />
                <RadioInputButton
                  text="Active"
                  active={localStatus === true}
                  marginRight="8px"
                  onClick={() => {
                    handleToggle(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.rowItemDescription}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.description' })}</span>
          <input
            type="text"
            defaultValue={description}
            className={styles.formInput}
            onChange={(e): void => editStagedPayload({ description: e.target.value })}
            disabled={loading}
          />
        </div>
      </div>

      {modalVisible && (
        <TFModal
          handleCancelClick={handleCancelClick}
          handleContinueClick={handleContinueClick}
          title="Accept all draft items?"
          // eslint-disable-next-line max-len
          text={`This workpack contains ${numberOfDraftItems} draft item${
            numberOfDraftItems === 1 ? '' : 's'
          }. Continuing will cause all of these items to be automatically accepted. Do you wish to proceed?`}
          cancelButtonText="No, cancel"
          confirmButtonText="Yes, proceed"
        />
      )}
    </>
  );
};

const AddTitleInfo = ({ editStagedPayload, operatorSettings }): JSX.Element => {
  const [status, setStatus] = useState(false);
  const [date, setDate] = useState(null);
  const [po, setPo] = useState('');
  const [description, setDescription] = useState('');

  const { formatMessage } = useIntl();

  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const handleInputChange = (target, value): void => {
    editStagedPayload({ [`${target}`]: value });
  };

  const handleDateChange = (dateMoment, dateString: string): void => {
    setDate(dateMoment);
    handleInputChange('date', dateString);
  };

  useEffect(() => {
    if (date === null) {
      setDate(moment().format('YYYY-MM-DD'));
      const setInitialDate = (): void => {
        editStagedPayload({ date: moment().format('YYYY-MM-DD'), status: 'draft' });
      };
      setInitialDate();
    }
  }, [date, editStagedPayload]);

  return (
    <>
      <div className={styles.rowGrid}>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>
            {`${operatorSettings?.po_short_name} ${operatorSettings?.po_suffix}`}
          </span>
          <input
            type="text"
            value={po}
            onChange={(e): void => {
              setPo(e.target.value);
              handleInputChange('po_number', e.target.value);
            }}
            className={styles.formInput}
          />
        </div>
        <div className={styles.rowItem}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.dateCreated' })}</span>
          <DatePicker
            suffixIcon={<img src={calendarIcon} alt="calendarIcon" />}
            value={date ? moment(date) : moment()}
            format={dateFormat}
            onChange={handleDateChange}
          />
        </div>
        <div className={`${styles.rowItem} ${styles.statusRow}`}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.status' })}</span>
          <div className={styles.toggleWrapper}>
            <RadioInputButton
              text="Draft"
              active={status === false}
              marginRight="8px"
              onClick={() => {
                handleInputChange('status', 'draft');
                setStatus(false);
              }}
            />
            <RadioInputButton
              text="Active"
              active={status === true}
              marginRight="8px"
              onClick={() => {
                handleInputChange('status', 'active');
                setStatus(true);
              }}
            />
          </div>
        </div>
        <div className={styles.rowItemDescription}>
          <span className={styles.formLabel}>{formatMessage({ id: 'text.description' })}</span>
          <input
            type="text"
            value={description}
            className={styles.formInput}
            onChange={(e): void => {
              setDescription(e.target.value);
              handleInputChange('description', e.target.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

const WorkpackTitle = ({
  mode,
  description,
  changeMode,
  editStagedPayload,
  po,
  date,
  status,
  handleDeleteWorkpack,
  wpId,
  unsavedChanges,
  handleUnsavedItems,
  aircraftId,
  loading,
  numberOfDraftItems,
  hasAnyDraftItems,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [CRSUrl, setCRSUrl] = useState('');

  const fetchUrl = async () => {
    try {
      const response = await getPublicShareURL({ type: 'workpacks', id: wpId });
      setCRSUrl(response);
      window.open(response, '_blank');
    } catch (err) {
      dispatch(
        addToast({
          payload: {
            title: formatMessage({ id: 'text.failedToCreatePublicUrl' }),
            type: ToastTypes.ERROR,
            message: err.message || formatMessage({ id: 'text.failedToCreatePublicUrl' }),
            category: ToastCategories.FLAG,
          },
        }),
      );
    }
  };

  const handleCloseClick = (): void => {
    handleUnsavedItems();
  };

  const {
    aircraft: { aircraftMap },
    userSettings: {
      details: { operators },
    },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const operatorSettings = operators.find((op) => op.id === aircraftMap.get(id)?.operator_id)?.operator_setting;

  const editButtonChild = <span className={styles.workpackMenuSpan}>{formatMessage({ id: 'form.button.edit' })}</span>;

  let customButtonChild = null;

  if (status === 'active' || status === 'complete')
    customButtonChild = (
      <a
        href={CRSUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.addCRSLink}
        onClick={async (e) => {
          if (!CRSUrl) {
            e.preventDefault();
            fetchUrl();
          }
        }}
      >
        {status === 'complete' ? `View ${operatorSettings?.crs_short_name}` : `Add ${operatorSettings?.crs_short_name}`}
      </a>
    );

  let allowUpdate = true;
  if (status === 'complete') allowUpdate = false;
  return (
    <>
      <div className={styles.workpackTitle}>
        {mode === 'edit' ? (
          <h3>
            Editing:
            {po}
          </h3>
        ) : (
          <>
            <h3>
              {mode === 'new' ? operatorSettings?.workpack_name : `${operatorSettings?.workpack_name}: `}
              <span>
                {po} {description && `(${description})`}
              </span>
            </h3>
            {mode !== 'new' && (
              <AuthDropdownMenu
                editText={editButtonChild}
                editCallback={(): void => changeMode('edit')}
                customText={customButtonChild}
                handleDelete={(): void => handleDeleteWorkpack(wpId)}
                resource={AircraftResource.WORKPACK}
                aircraftId={aircraftId}
                options={{ read: false, update: allowUpdate, delete: true, custom: true }}
              />
            )}
          </>
        )}
        <UnsavedConfirmationButton handleClick={handleCloseClick} unsavedChanges={unsavedChanges} />
      </div>
      <div>
        {mode === 'edit' && (
          <EditTitleInfo
            editStagedPayload={editStagedPayload}
            description={description}
            po={po}
            date={date}
            status={status}
            loading={loading}
            numberOfDraftItems={numberOfDraftItems}
            hasAnyDraftItems={hasAnyDraftItems}
            operatorSettings={operatorSettings}
          />
        )}
      </div>
      <div>
        {mode === 'new' && <AddTitleInfo editStagedPayload={editStagedPayload} operatorSettings={operatorSettings} />}
      </div>
    </>
  );
};

export default WorkpackTitle;
