import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button } from '@arcflight/tf-component-library';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Flight } from '../../models/flights';
import EmptyStateFaults from '../../assets/emptyState/empty-state-sector-faults.png';
import { DashboardState } from '../../models';
import plusIcon from '../../assets/plus-blue.svg';
import { changeDrawerContent, changeDrawerMode } from '../../models/drawer';
import { BaseAircraft } from '../../models/aircraft';
import useGetOperatorSetting from '../../utils/useGetOperatorSetting';
import IntermittentFaultsDrawer from '../../pages/IntermittentFaults/IntermittentFaultsDrawer';
import { SectionHeader } from './FlightDrawer';
import FlightIntermittentFaultsDrawer from './FlightIntermittentFaultsTable';

interface IntermittentFaultsSectionProps {
  aircraft: BaseAircraft;
  flight: Flight;
}

const SectionWrapper = styled.div`
  row-gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.img`
  width: 9px;
  height: 9px;
  margin-right: 4px;
`;

const NoDefectsText = styled.span`
  margin-left: 8px;
`;

export const EmptyStateDiv = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: solid 2px #fff;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 0 32px;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
    letter-spacing: 1.33;
    color: rgba(36, 45, 65, 0.7);
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
  margin-top: 10px;
`;

const IntermittentFaultsSection: React.FC<IntermittentFaultsSectionProps> = ({ aircraft, flight }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const {
    drawer: { mode },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const sectorNameOverride = useGetOperatorSetting('sector_name', id) || formatMessage({ id: 'text.sector' });

  const getIntermittentFaultName = (singular) => {
    let name = 'Intermittent Faults';
    const currentOrgId = aircraft?.operator_id;
    if (userSettings.details) {
      const currentOrg = userSettings.details.operators.find((o) => o.id === currentOrgId);
      if (currentOrg) {
        name = singular
          ? currentOrg.operator_setting?.intermittent_fault_name_singular
          : currentOrg.operator_setting?.intermittent_fault_name_plural;
      }
    }
    return name;
  };

  const handleAddFault = (): void => {
    const currentOrgId = aircraft.operator_id;
    const loggedInUser = userSettings?.details?.people.find((p) => p.organisation.id === currentOrgId);
    const emptyFault = {
      reported_at: moment().utc().format(),
      reported_by_id: loggedInUser.id,
    };
    dispatch(
      changeDrawerContent({
        payload: {
          content: (
            <IntermittentFaultsDrawer
              fault={emptyFault as any}
              intermittentFaultName={getIntermittentFaultName(true)}
              sectorFault
              flightId={flight?.id}
              convertable={false}
            />
          ),
          backButton: true,
          previousContent: flight,
        },
      }),
    );
    dispatch(changeDrawerMode({ payload: 'add' }));
  };

  const faultName = getIntermittentFaultName(false);

  return (
    <SectionWrapper data-testid="FlightDrawer-FaultsSection">
      <HeaderWrapper>
        <SectionHeader marginLeft="20px" reducedBottomMargin data-testid="FaultsSection-Header">
          {faultName}
        </SectionHeader>
      </HeaderWrapper>
      <div>
        {flight?.intermittent_faults?.length ? (
          <FlightIntermittentFaultsDrawer
            intermittentFaults={flight?.intermittent_faults}
            aircraft={aircraft}
            flight={flight}
          />
        ) : (
          <EmptyStateDiv data-testid="FaultsSection-EmptyState">
            <div>
              <img src={EmptyStateFaults} alt="no faults" />
              <NoDefectsText>
                {`No ${faultName?.toLowerCase()} currently recorded on this ${sectorNameOverride.toLowerCase()}`}
              </NoDefectsText>
            </div>
            {mode !== 'view' && flight?.intermittent_faults?.length === 0 ? (
              <Button height="24px" onClick={handleAddFault} primary={false}>
                <AddTextWrapper>
                  <PlusIcon src={plusIcon} alt="plus icon" /> Add item
                </AddTextWrapper>
              </Button>
            ) : null}
          </EmptyStateDiv>
        )}
        {mode !== 'view' && flight?.intermittent_faults?.length > 0 ? (
          <ButtonWrapper>
            <Button height="24px" onClick={handleAddFault} primary={false}>
              <AddTextWrapper>
                <PlusIcon src={plusIcon} alt="plus icon" /> Add item
              </AddTextWrapper>
            </Button>
          </ButtonWrapper>
        ) : null}
      </div>
    </SectionWrapper>
  );
};

export default IntermittentFaultsSection;
