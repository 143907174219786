/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ata from '../../../utils/ata';
import { ItemWrapper, Label } from '../../CommonStyledComponents/CommonStyledComponents';
import Dropdown from '../../TFDropdown/TFDropdown';
import TFInput from '../../TFInput/TFInput';
import BaseModalFields from './BaseModalFields';

const StyledLabel = styled.span`
  color: ${({ theme }) => theme.colours.black80Alpha};
`;

const MXModalLLPContents = ({ item, updateItemData, configOptions }) => {
  const [ataOptions, setATAOptions] = useState([]);
  const [foundAta, setFoundAta] = useState(null);

  const { formatMessage } = useIntl();

  const getAllAtaOptions = () => {
    const options = ata.map((ataItem) => {
      return { title: ataItem.nameFull, value: ataItem.key };
    });
    return options;
  };

  useEffect(() => {
    if (ata) {
      const options = getAllAtaOptions();
      setATAOptions(options);
    }
  }, []);

  useEffect(() => {
    if (item?.ata_number) {
      const found = ataOptions.find((ataItem) => ataItem.value === item.ata_number);
      setFoundAta(found);
    }
  }, [item, ataOptions]);

  return (
    <>
      <ItemWrapper marginBottom="16px" data-testid="MXModalLLPContents-ATA">
        <Dropdown
          options={ataOptions}
          initialValue={foundAta || null}
          onSelect={(option) => updateItemData([{ value: option.value, key: 'ata_chapter' }])}
          label={formatMessage({ id: 'form.labels.ataChapter' })}
          searchable
          placeholder={formatMessage({ id: 'form.placeholder.searchATA' })}
        />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalLLPContents-Assembly">
        <Label edit>
          {formatMessage({ id: 'form.labels.assembly' })}
          <StyledLabel>
            {formatMessage({
              id: 'form.labels.optional',
            })}
          </StyledLabel>
        </Label>
        <TFInput
          id=""
          value={item?.assembly}
          onChange={(e) => updateItemData([{ value: e.target.value, key: 'assembly' }])}
        />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalLLPContents-TaskDescription">
        <Label edit>{formatMessage({ id: 'form.labels.taskDescription' })}</Label>
        <TFInput id="" value={item?.name} onChange={(e) => updateItemData([{ value: e.target.value, key: 'name' }])} />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalLLPContents-TaskReference">
        <Label edit>
          {formatMessage({ id: 'form.labels.taskRef' })}
          <StyledLabel>
            {formatMessage({
              id: 'form.labels.optional',
            })}
          </StyledLabel>
        </Label>
        <TFInput
          id=""
          value={item?.reference}
          onChange={(e) => updateItemData([{ value: e.target.value, key: 'reference' }])}
        />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalLLPContents-PartNo">
        <Label edit>{formatMessage({ id: 'title.partNum' })}</Label>
        <TFInput
          id=""
          value={item?.part_number}
          onChange={(e) => updateItemData([{ value: e.target.value, key: 'part_number' }])}
        />
      </ItemWrapper>
      <ItemWrapper marginBottom="16px" data-testid="MXModalLLPContents-SerialNo">
        <Label edit>{formatMessage({ id: 'title.serialNum' })}</Label>
        <TFInput
          id=""
          value={item?.part_serial}
          onChange={(e) => updateItemData([{ value: e.target.value, key: 'part_serial' }])}
        />
      </ItemWrapper>
      <BaseModalFields item={item} updateItemData={updateItemData} configOptions={configOptions} />
    </>
  );
};

MXModalLLPContents.propTypes = {
  updateItemData: PropTypes.func.isRequired,
  item: PropTypes.object,
  configOptions: PropTypes.object.isRequired,
};

MXModalLLPContents.defaultProps = {
  item: null,
};

export default MXModalLLPContents;
