import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Divider, Label, SectionHeader, Text } from '../../CommonStyledComponents/CommonStyledComponents';

interface ViewRectificationExtensionProps {
  rectificationIntervalExtension: {
    extension_date: string;
    extension_date_due: string;
  };
  dateFormat: string;
}

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px 0;
`;

const ViewRectificationExtension: React.FC<ViewRectificationExtensionProps> = ({
  rectificationIntervalExtension,
  dateFormat,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Divider />
      <SectionHeader data-testid="ViewRectificationExtension-Header">
        {formatMessage({ id: 'form.labels.rectificationIntervalExtension' })}
      </SectionHeader>
      <GridWrapper>
        <div data-testid="ViewRectificationExtension-ExtensionAdded">
          <Label>{formatMessage({ id: 'text.extensionAdded' })}</Label>
          <Text>{rectificationIntervalExtension ? 'YES' : 'NO'}</Text>
        </div>
        <div data-testid="ViewRectificationExtension-ExtensionDate">
          <Label>{formatMessage({ id: 'text.extendedOn' })}</Label>
          <Text>
            {rectificationIntervalExtension?.extension_date
              ? moment.utc(rectificationIntervalExtension?.extension_date).format(`${dateFormat}`)
              : '-'}
          </Text>
        </div>
        <div data-testid="ViewRectificationExtension-ExtensionDateDue">
          <Label>{formatMessage({ id: 'text.extensionDue' })}</Label>
          <Text>
            {rectificationIntervalExtension?.extension_date_due
              ? moment.utc(rectificationIntervalExtension?.extension_date_due).format(`${dateFormat}`)
              : '-'}
          </Text>
        </div>
      </GridWrapper>
    </div>
  );
};

export default ViewRectificationExtension;
