import React, { Component } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { AircraftAuthenticationWrapper } from '../_utils/AuthenticationWrapper';
import { AircraftResource, AircraftPermission } from '../../models/aircraft';
import { changeDrawerContent, changeDrawerVisibility, setDrawerId } from '../../models/drawer';
import { getReleaseDetails } from '../../services/api';
import { Divider } from '../CommonStyledComponents/CommonStyledComponents';
import MXItemDrawer from '../../pages/AircraftMaintenance/MXItemDrawer';
import ResolutionDetails from '../WorkPackDrawer/Components/ResolutionDetails';
import ata from '../../utils/ata';
import TFCard from '../TFCard/TFCard';
import styles from './ExpandableCard.module.less';
import ExpandableCardTitle from './ExpandableCardTitle';
import MXDetailsWorkpacks from './MXDetailsWorkpacks';
import MXDetailsManage from './MXDetailsManage';
import MXDetailsPackage from './MXDetailsPackage';
import MXTimeDetails from './MXTimeDetails';
import ProgressBars from './ProgressBars';

const ResolutionDetailsWrapper = styled.div`
  margin: 0 12px 20px;
`;

class ExpandableCard extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    aircraftThreshold: PropTypes.object,
    aircraftID: PropTypes.string,
    hasAPU: PropTypes.bool,
    isOverdue: PropTypes.bool.isRequired,
    isCritical: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    onApplyTolerance: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    expandOverride: PropTypes.bool,
    expandItem: PropTypes.string,
    intl: PropTypes.shape({ formatMessage: PropTypes.func }).isRequired,
    setPassedMxItemId: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    expandOverride: false,
    hasAPU: false,
    aircraftID: '',
    aircraftThreshold: undefined,
    expandItem: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      resolutionData: null,
    };
  }

  componentDidMount() {
    const { item, expandItem, loading } = this.props;
    if (expandItem === item.id && !loading) {
      this.checkItemExpanded();
    }
  }

  componentDidUpdate() {
    const { item, expandItem, loading, expandOverride } = this.props;
    const { expanded, resolutionData } = this.state;
    if (expandItem === item.id && !loading) {
      if (!expanded) {
        this.checkItemExpanded();
      }
    }

    if ((expanded || expandOverride) && !resolutionData && item.status === 'resolved') {
      this.getReleaseDetails();
    }
  }

  checkItemExpanded = () => {
    const { expandItem, item } = this.props;
    if (expandItem === item.id) {
      this.setState({ expanded: true });
    }
    document.getElementById(expandItem).scrollIntoView();
  };

  onToggle = () => {
    const { expanded } = this.state;
    const { setPassedMxItemId } = this.props;
    this.setState({
      expanded: !expanded,
    });
    setPassedMxItemId('');
  };

  handleViewHistory = () => {
    const { dispatch, item } = this.props;
    dispatch(changeDrawerVisibility({ payload: true }));
    dispatch(
      changeDrawerContent({
        payload: {
          content: <MXItemDrawer />,
        },
      }),
    );
    dispatch(setDrawerId({ payload: item.id }));
  };

  getReleaseDetails = async () => {
    const { item } = this.props;
    try {
      const response = await getReleaseDetails(item.id);
      response.json().then((data) => {
        this.setState({ resolutionData: data });
      });
    } catch (error) {
      console.error('Error fetching release details:', error);
    }
  };

  render() {
    const {
      aircraftID,
      aircraftThreshold,
      hasAPU,
      intl: { formatMessage },
      item,
      isOverdue,
      isCritical,
      onEdit,
      onApplyTolerance,
      onSuccess,
      expandOverride,
    } = this.props;
    const { expanded, resolutionData } = this.state;

    const hrsTimeDetails = {
      repeat: item.flight_seconds_repeat,
      due: item.flight_seconds_due,
      remaining: item.flight_seconds_remaining,
      threshold: item.flight_seconds_threshold,
      tolerance: item.flight_seconds_tolerance,
      visible_tolerance: item.flight_seconds_visible_tolerance,
      format: 'seconds',
    };

    const cyclesTimeDetails = {
      repeat: item.cycles_repeat,
      due: item.cycles_due,
      remaining: item.cycles_remaining,
      threshold: item.cycles_threshold,
      tolerance: item.cycles_tolerance,
      visible_tolerance: item.cycles_visible_tolerance,
      format: 'number',
    };

    const monthsTimeDetails = {
      repeat: item.months_repeat,
      due: item.date_due || item.datetime_due,
      remaining: item.days_remaining,
      threshold: item.months_threshold,
      tolerance: item.months_tolerance,
      visible_tolerance: item.months_visible_tolerance,
      format: 'months',
      resolved: item.date_closed,
    };

    const daysTimeDetails = {
      repeat: item.days_repeat,
      due: item.date_due || item.datetime_due,
      remaining: item.days_remaining,
      threshold: item.days_threshold,
      tolerance: item.days_tolerance,
      visible_tolerance: item.days_visible_tolerance,
      format: 'days',
      resolved: item.date_closed,
    };

    const apuHrsTimeDetails = {
      repeat: item.apu_seconds_repeat,
      due: item.apu_seconds_due,
      remaining: item.apu_seconds_remaining,
      threshold: item.apu_seconds_threshold,
      tolerance: item.apu_seconds_tolerance,
      visible_tolerance: item.apu_seconds_visible_tolerance,
      format: 'seconds',
    };

    const isItemDisplaying =
      item.ata_chapter ||
      item.assembly ||
      item.part_number ||
      item.part_serial ||
      item.repeat_remarks ||
      item.reference;

    const showTolerance =
      (item.flight_seconds_tolerance && item.flight_seconds_due) ||
      (item.cycles_tolerance && item.cycles_due) ||
      (item.months_tolerance && item.date_due) ||
      (item.days_tolerance && item.date_due) ||
      (item.apu_seconds_tolerance && item.apu_seconds_due);

    return (
      <TFCard id={item.id} padding="0px" marginBottom={12} width="100%">
        <ExpandableCardTitle
          item={item}
          expanded={expanded}
          onToggle={() => this.onToggle()}
          isOverdue={isOverdue}
          isCritical={isCritical}
          data-test="cardTitle"
        />
        {(expanded || expandOverride) && (
          <>
            <Row gutter={16} className={styles.mxDetails} data-test="expandedDetails">
              {isItemDisplaying && (
                <Col md={24}>
                  <Row gutter={16} className={styles.itemInfo}>
                    {item.ata_chapter ? (
                      <Col xl={8} lg={8} md={12}>
                        <div className={styles.itemTitle}>{formatMessage({ id: 'form.labels.ataChapter' })}</div>
                        <div className={styles.itemValue}>
                          {ata
                            .filter((itemATA) => itemATA.key === Number(item.ata_chapter))
                            .map((itemATA) => itemATA.nameFull)}
                        </div>
                      </Col>
                    ) : null}
                    {item.assembly && (
                      <Col xl={4} lg={4} md={12}>
                        <div className={styles.itemTitle}>{formatMessage({ id: 'form.labels.assembly' })}</div>
                        <div className={styles.itemValue}>{item.assembly}</div>
                      </Col>
                    )}
                    {item.part_number && (
                      <Col xl={4} lg={4} md={12}>
                        <div className={styles.itemTitle}>{formatMessage({ id: 'form.labels.partNum' })}</div>
                        <div className={styles.itemValue}>{item.part_number}</div>
                      </Col>
                    )}
                    {item.part_serial && (
                      <Col xl={4} lg={4} md={12}>
                        <div className={styles.itemTitle}>{formatMessage({ id: 'form.labels.serialNum' })}</div>
                        <div className={styles.itemValue}>{item.part_serial}</div>
                      </Col>
                    )}
                    {item.repeat_remarks && (
                      <Col xl={8} lg={8} md={12}>
                        <div className={styles.itemTitle}>{formatMessage({ id: 'title.notes' })}</div>
                        <div className={styles.itemValue}>{item.repeat_remarks}</div>
                      </Col>
                    )}
                    {item.reference && (
                      <Col xl={4} lg={4} md={12}>
                        <div className={styles.itemTitle}>{formatMessage({ id: 'form.labels.taskRef' })}</div>
                        <div className={styles.itemValue}>{item.reference}</div>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
              <Col xl={8} lg={24}>
                <ProgressBars
                  title={formatMessage({ id: 'title.status' })}
                  mxItem={item}
                  hasAPU={hasAPU}
                  aircraftThreshold={aircraftThreshold}
                  data-test="progressBars"
                />
              </Col>
              <Col xl={16} lg={24} className={styles.mxTimings}>
                <Row gutter={16}>
                  <Col md={hasAPU ? 6 : 8} data-test="hrsCol">
                    <MXTimeDetails
                      title={formatMessage({ id: 'title.flightHrs' })}
                      unitSingular={formatMessage({ id: 'units.hourShort' })}
                      unitPlural={formatMessage({ id: 'units.hoursShort' })}
                      item={hrsTimeDetails}
                      data-test="mxTimeHrs"
                    />
                  </Col>
                  <Col md={hasAPU ? 6 : 8} data-test="cyclesCol">
                    <MXTimeDetails
                      title={formatMessage({ id: 'title.cycles' })}
                      unitSingular={formatMessage({ id: 'units.cycle' })}
                      unitPlural={formatMessage({ id: 'units.cycles' })}
                      item={cyclesTimeDetails}
                      data-test="mxTimeCycles"
                    />
                  </Col>
                  <Col md={hasAPU ? 6 : 8} data-test="monthsCol">
                    <MXTimeDetails
                      title={
                        item?.unit_of_time === 'months'
                          ? formatMessage({ id: 'title.months' })
                          : formatMessage({ id: 'title.days' })
                      }
                      unitSingular={
                        item?.unit_of_time === 'months'
                          ? formatMessage({ id: 'units.monthShort' })
                          : formatMessage({ id: 'units.day' })
                      }
                      unitPlural={
                        item?.unit_of_time === 'months'
                          ? formatMessage({ id: 'units.monthShort' })
                          : formatMessage({ id: 'units.days' })
                      }
                      item={item?.unit_of_time === 'months' ? monthsTimeDetails : daysTimeDetails}
                      data-test="mxTimeMonths"
                    />
                  </Col>
                  {hasAPU && (
                    <Col md={6} data-test="apuCol">
                      <MXTimeDetails
                        title={formatMessage({ id: 'title.apuHours' })}
                        unitSingular={formatMessage({ id: 'units.hourShort' })}
                        unitPlural={formatMessage({ id: 'units.hoursShort' })}
                        item={apuHrsTimeDetails}
                        data-test="mxTimeAPU"
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {item.status === 'resolved' ? (
              <div className={styles.historyButtonWrapper}>
                <button type="button" onClick={this.handleViewHistory}>
                  View History
                </button>
              </div>
            ) : (
              <AircraftAuthenticationWrapper
                aircraftId={aircraftID}
                requiredResource={AircraftResource.SCHEDULED_MX_ITEM}
                requiredPermissionLevel={AircraftPermission.UPDATE}
              >
                <MXDetailsManage
                  item={item}
                  data-test="mxDetailsManage"
                  hideEdit={item.source === 'camp'}
                  hideTolerance={!showTolerance}
                  aircraftId={aircraftID}
                  onEdit={() => onEdit()}
                  onApplyTolerance={() => onApplyTolerance()}
                  onSuccess={() => onSuccess()}
                />
              </AircraftAuthenticationWrapper>
            )}
            {item.status === 'resolved' && (
              <>
                <ResolutionDetailsWrapper>
                  <Divider />
                  <ResolutionDetails release={resolutionData} poNum={item.workpack_reference} noCard fromWorkpack />
                </ResolutionDetailsWrapper>
              </>
            )}
            <MXDetailsWorkpacks item={item} aircraftID={aircraftID} data-test="mxDetailsWorkpacks" />
            <MXDetailsPackage item={item} data-test="mxDetailsPackage" />
          </>
        )}
      </TFCard>
    );
  }
}

export default injectIntl(connect()(ExpandableCard));
