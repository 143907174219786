import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Divider, SectionHeader } from '../../CommonStyledComponents/CommonStyledComponents';
import TFRadioInput from '../../TFRadioInput/TFRadioInput';
import TFInput from '../../TFInput/TFInput';

interface EditLimitationsProps {
  partialDefectData: { limitations: string; erops_prevented: boolean };
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  requiredSettings: { eropsRename: string; sectorsNameOverride: string; ignoreEROPS: boolean };
}

const LabelWrapper = styled.div`
  margin: 20px 0 16px;
`;

const LimitationsWrapper = styled.div`
  margin: 20px 0;
  gap: 20px;
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
`;

const WarningText = styled.p`
  color: #f79833;
  grid-column: span 2;
`;

const radioOptions = [
  {
    title: 'Yes',
    value: true,
  },
  {
    title: 'No',
    value: false,
  },
];

const EditLimitations: React.FC<EditLimitationsProps> = ({ partialDefectData, updateDefectData, requiredSettings }) => {
  const [isLimitations, setIsLimitations] = useState<boolean>(false);
  const [isErops, setIsErops] = useState<boolean>(false);

  const { formatMessage } = useIntl();

  const handleLimitationsChange = (value: boolean): void => {
    setIsLimitations(value);
    const changes = [{ value: value as boolean, key: 'isLimitations' }];
    if (!value) {
      changes.push({ value: null, key: 'limitations' }, { value: null, key: 'erops_prevented' });
    }
    updateDefectData(changes);
  };

  const handleLimitationsInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    updateDefectData([{ value: e.target.value, key: 'limitations' }]);
  };

  const handleEropsChange = (value: boolean): void => {
    setIsErops(value);
    updateDefectData([{ value: value as boolean, key: 'erops_prevented' }]);
  };

  useEffect(() => {
    if (partialDefectData?.limitations) {
      setIsLimitations(true);
    }
    if (partialDefectData?.erops_prevented) {
      setIsErops(true);
    }
  }, [partialDefectData]);

  return (
    <div data-testid="EditLimitations">
      <LabelWrapper data-testid="EditLimitations-LabelWrapper">
        <SectionHeader>{formatMessage({ id: 'form.labels.aircraftLimitations' })}</SectionHeader>
      </LabelWrapper>
      <TFRadioInput
        options={radioOptions}
        onChange={handleLimitationsChange}
        value={isLimitations}
        id="Limitations"
        label={formatMessage({ id: 'text.areThereAnyLimitations' })}
      />
      {isLimitations && (
        <LimitationsWrapper>
          <InputWrapper>
            <TFInput
              id="limitations"
              value={partialDefectData?.limitations}
              label={formatMessage({ id: 'title.limitations' })}
              onChange={handleLimitationsInputChange}
            />
          </InputWrapper>
          {!requiredSettings.ignoreEROPS && (
            <InputWrapper>
              <TFRadioInput
                options={radioOptions}
                onChange={handleEropsChange}
                value={isErops}
                id="Erops"
                label={formatMessage(
                  {
                    id: 'text.exceptEROPS',
                  },
                  { field: requiredSettings.eropsRename, sectors: requiredSettings.sectorsNameOverride },
                )}
              />
            </InputWrapper>
          )}
          {isErops && !requiredSettings?.ignoreEROPS ? (
            <WarningText data-testid="EditLimitations-EropsWarning">
              {formatMessage({ id: 'text.eropsWarning' }, { field: requiredSettings.eropsRename })}
            </WarningText>
          ) : null}
        </LimitationsWrapper>
      )}
      <Divider />
    </div>
  );
};

export default EditLimitations;
