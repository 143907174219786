import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../../models';
import { Defect } from '../../../models/defects';
import TFTooltip from '../../TFTooltip/TFTooltip';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import StyledInput from './StyledInput';

interface RectificationLimitsProps {
  defect: Defect | null;
  updateDefectData?: (changes: { value: any; key: string }[]) => void;
}

const BorderLine = styled.div`
  width: 640px;
  height: 1px;
  background-color: rgba(36, 45, 65, 0.05);
  margin-top: ${({ marginTop }): string => (marginTop ? `${marginTop}` : '')};
`;

const RectificationLimits: React.FC<RectificationLimitsProps> = ({ defect, updateDefectData }) => {
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const apuInstalled = aircraftMap.get(id)?.apu_installed;

  return (
    <div id="rectificationLimits">
      <BorderLine marginTop="20px" />
      <FlexWrapper column marginTop={20}>
        <FlexWrapper>
          <Label fontWeight={500} marginBottom={15} marginRight={5}>
            {formatMessage({ id: 'title.enterRectificationIntervals' })}
          </Label>
          <TFTooltip>
            <span>{formatMessage({ id: 'text.allowedRectificationIntervals' })}</span>
          </TFTooltip>
        </FlexWrapper>
        <Label color="rgba(36, 45, 65, 0.7)" marginBottom={20}>
          {formatMessage({ id: 'text.pleaseEnterRectificationInterval' })}
        </Label>
        <FlexWrapper flexWrap="wrap">
          <FlexWrapper column marginRight={20} marginBottom={20}>
            <Label marginBottom={5}>{formatMessage({ id: 'form.labels.flightHourLimit' })}</Label>
            <StyledInput
              defaultValue={defect?.flight_seconds_limit / 3600 || ''}
              type="text"
              width={140}
              id="flightHoursLimit"
              onChange={(e): void =>
                updateDefectData
                  ? updateDefectData([{ value: parseInt(e.target.value, 10) * 3600, key: 'flight_seconds_limit' }])
                  : null
              }
            />
          </FlexWrapper>
          {apuInstalled ? (
            <FlexWrapper column marginRight={20} marginBottom={20}>
              <Label marginBottom={5}>{formatMessage({ id: 'form.labels.apuHoursLimit' })}</Label>
              <StyledInput
                defaultValue={defect?.apu_seconds_limit / 3600 || null}
                type="text"
                width={140}
                id="APUHoursLimit"
                onChange={(e): void =>
                  updateDefectData
                    ? updateDefectData([{ value: parseInt(e.target.value, 10) * 3600, key: 'apu_seconds_limit' }])
                    : null
                }
              />
            </FlexWrapper>
          ) : null}
          <FlexWrapper column marginRight={20} marginBottom={20}>
            <Label marginBottom={5}>{formatMessage({ id: 'form.labels.flightDaysLimit' })}</Label>
            <StyledInput
              defaultValue={defect?.flight_days_limit || ''}
              type="text"
              width={140}
              id="flightDaysLimit"
              onChange={(e): void =>
                updateDefectData ? updateDefectData([{ value: e.target.value, key: 'flight_days_limit' }]) : null
              }
            />
          </FlexWrapper>
          <FlexWrapper column marginRight={20} marginBottom={20}>
            <Label marginBottom={5}>{formatMessage({ id: 'form.labels.calendarHoursLimit' })}</Label>
            <StyledInput
              defaultValue={defect?.seconds_limit / 3600 || ''}
              type="text"
              width={140}
              id="hoursLimit"
              onChange={(e): void =>
                updateDefectData
                  ? updateDefectData([{ value: parseInt(e.target.value, 10) * 3600, key: 'seconds_limit' }])
                  : null
              }
            />
          </FlexWrapper>
          <FlexWrapper column marginRight={20} marginBottom={20}>
            <Label marginBottom={5}>{formatMessage({ id: 'form.labels.calendarDaysLimit' })}</Label>
            <StyledInput
              defaultValue={defect?.calendar_days_limit || ''}
              type="text"
              width={140}
              onChange={(e): void =>
                updateDefectData ? updateDefectData([{ value: e.target.value, key: 'calendar_days_limit' }]) : null
              }
              id="calendarDaysLimit"
            />
          </FlexWrapper>
          <FlexWrapper column marginRight={20} marginBottom={20}>
            <Label marginBottom={5}>{formatMessage({ id: 'form.labels.cyclesLimit' })}</Label>
            <StyledInput
              defaultValue={defect?.cycles_limit || ''}
              type="text"
              width={140}
              id="cyclesLimit"
              onChange={(e): void =>
                updateDefectData ? updateDefectData([{ value: e.target.value, key: 'cycles_limit' }]) : null
              }
            />
          </FlexWrapper>
          <FlexWrapper column marginRight={20} marginBottom={20}>
            <Label marginBottom={5}>{formatMessage({ id: 'form.labels.flightLimit' })}</Label>
            <StyledInput
              defaultValue={defect?.flights_limit || ''}
              type="text"
              width={140}
              id="flightLimit"
              onChange={(e): void =>
                updateDefectData ? updateDefectData([{ value: e.target.value, key: 'flights_limit' }]) : null
              }
            />
          </FlexWrapper>
          <FlexWrapper column marginRight={20} marginBottom={20}>
            <Label marginBottom={5}>{formatMessage({ id: 'form.labels.otherLimit' })}</Label>
            <StyledInput
              defaultValue={defect?.other_limit || ''}
              type="text"
              width={140}
              id="otherLimit"
              onChange={(e): void =>
                updateDefectData ? updateDefectData([{ value: e.target.value, key: 'other_limit' }]) : null
              }
            />
          </FlexWrapper>
        </FlexWrapper>
      </FlexWrapper>
    </div>
  );
};

export default RectificationLimits;
