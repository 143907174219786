import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import { DisplayText, Header, SectionHeader } from '../../components/FlightDrawer/FlightDrawer';
import { IntermittentFault } from '../../models/intermittentFaults';
import FileViewer from '../../components/TFFileViewer/fileViewer';
import SelectPilot from '../../components/FlightDrawer/SelectPilot';
import { getPeople } from '../../models/people/actions';

interface DetailsSectionProps {
  fault: IntermittentFault;
  editable: boolean;
  updateFaultData?: (key: string, value: string, secondKey?: string, secondValue?: string) => void;
  aircraftId: string;
  attachments?: any[];
  setAttachments?: (input: any) => void;
}

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile, editable }): string => {
    if (isMobile) return '1fr';
    if (editable) return '1fr 1fr';
    return '1fr 1fr';
  }};
  grid-template-rows: auto;
  gap: ${({ isMobile }): string => {
    if (isMobile) return '1rem';
    return '1.5rem';
  }};
`;

const StyledTextarea = styled.textarea`
  border-radius: 4px;
  border-color: rgba(36, 45, 65, 0.1);
  padding: 8px;
  width: 100%;
`;

const DetailsSection: React.FC<DetailsSectionProps> = ({
  fault,
  editable,
  updateFaultData,
  aircraftId,
  attachments,
  setAttachments,
}) => {
  const {
    userSettings,
    people: { peopleMap },
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);
  const dispatch = useDispatch();

  const [details, setDetails] = useState('');
  // const [resAttachments, setResAttachments] = useState(null);
  const [reportedBy, setReportedBy] = useState(null);
  const [people, setPeople] = useState([]);
  const operatorId = aircraftMap.get(aircraftId)?.operator_id;

  useEffect(() => {
    if (peopleMap.size === 0) {
      dispatch(getPeople());
    }
  }, [peopleMap, dispatch]);

  useEffect(() => {
    const newArray = Array.from(peopleMap.values());
    const peopleArray = newArray
      .filter((person) => person.organisation.id === operatorId)
      .map((person) => {
        return {
          value: person.id,
          title: `${person.first_name} ${person.last_name}`,
        };
      });
    const loggedInUser = userSettings.details.people.find((p) => p.organisation.id === operatorId);
    if (loggedInUser && !fault?.reported_by_id) {
      setReportedBy({
        value: loggedInUser.id,
        title: `${loggedInUser.first_name} ${loggedInUser.last_name}`,
      });
    }

    setPeople(peopleArray);
  }, [peopleMap, userSettings, operatorId, fault]);

  useEffect(() => {
    if (fault) {
      if (fault.details) setDetails(fault.details);
      if (fault.reported_by_id && people) {
        setReportedBy(people.find((person) => person.value === fault.reported_by_id));
      }
    }
  }, [fault, people]);

  const convertToImgFile = (file: File) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = (e): void => {
        res({ attachment: e?.target?.result, attachment_file_name: file.name });
      };

      reader.onerror = (): void => {
        reader.abort();
        rej(console.error('Failed to convert image'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAttachmentUpload = async (files: FileList): Promise<void> => {
    if (files) {
      const attachPromises = [];
      attachPromises.push(convertToImgFile(files[0]));
      const base64Attachments = await Promise.all(attachPromises);
      setAttachments(attachments?.concat(...base64Attachments));
    }
  };

  const handleDelete = ({ fileId, fileName }: Record<'fileId' | 'fileName', string>): void => {
    const newAttachmentsList = attachments.filter(
      (item) => item?.id !== fileId || (item?.attachment_file_name && item?.attachment_file_name !== fileName),
    );
    if (fileId) {
      newAttachmentsList.push({ id: fileId, _destroy: true });
    }
    setAttachments(newAttachmentsList);
  };

  const handlePersonChange = (id: string): void => {
    setReportedBy(people.find((person) => person.id === id));
    updateFaultData('reported_by_id', id);
  };

  const isMobile = window.innerWidth < 451;
  return (
    <>
      <SectionHeader>Intermittent Fault Details</SectionHeader>
      <ContentWrapper isMobile={isMobile}>
        <div>
          <Header editable={editable}>Details</Header>
          {!editable ? (
            <span>{details}</span>
          ) : (
            <StyledTextarea
              id="notes"
              name="details"
              data-testid="IntermittentFault--TextArea"
              cols={30}
              rows={5}
              defaultValue={details}
              onChange={(e) => {
                setDetails(e.target.value);
                updateFaultData('details', e.target.value);
              }}
            />
          )}
        </div>
        <div>
          <Header editable={editable}>Attachments</Header>
          <FileViewer
            attachments={attachments}
            handleDeleteClick={handleDelete}
            addAttachment={handleAttachmentUpload}
            uploaderVisible={editable}
            allowDelete={editable}
          />
        </div>
        <div>
          <Header editable={editable}>Reported By</Header>
          {!editable ? (
            <DisplayText>{`${fault?.reported_by_name || ''}`}</DisplayText>
          ) : (
            <SelectPilot
              people={people}
              handlePersonChange={(id): void => handlePersonChange(id)}
              pilotFlying={reportedBy}
            />
          )}
        </div>
      </ContentWrapper>
    </>
  );
};

DetailsSection.defaultProps = {
  updateFaultData: (): void => {},
  attachments: [],
  setAttachments: (): void => {},
};

export default DetailsSection;
