import React from 'react';
import { Defect } from '../../../models/defects';
import { Flight } from '../../../models/flights';
import { BookItem } from '../../../models/deferrableDefectBook';
import { Aircraft, BaseAircraft } from '../../../models/aircraft';
import { Divider } from '../../CommonStyledComponents/CommonStyledComponents';
import ResolutionDetails from '../../WorkPackDrawer/Components/ResolutionDetails';
import EditDefectDrawerHeader from './EditDefectDrawerHeader';
import Card from './Card';
import EditDefectDrawerDetails from './EditDefectDrawerDetails';
import EditDeferralOptions from './EditDeferralOptions';
import EditDefectAdditionalInformation from './EditDefectAdditionalInformation';
import EditLimitations from './EditLimitations';
import ConfirmSection from './ConfirmSection';
import DeferralSignoffDetails from './DeferralSignoffDetails';
import EditRectificationExtension from './EditRectificationExtension';

interface EditDefectDrawerProps {
  defectData: Defect;
  originalDefectData: Defect;
  aircraftId: string;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  flightsData: Flight[];
  isLoadingFlights: boolean;
  setAircraftId: (id: string) => void;
  formChanged: boolean;
  chosenAircraft: Aircraft | BaseAircraft;
  bookItemsLoading: boolean;
  melItems: BookItem[];
  cdlItems: BookItem[];
  nefItems: BookItem[];
  signOffAsEngineer: boolean;
  setSignOffAsEngineer: (value: boolean) => void;
  handleFormChange: () => void;
  mode: string;
  requiredSettings: {
    troubleShootingStepsRename: string;
    troubleShootingStepsRequired: boolean;
    reasonToDeferRequired: boolean;
    reasonToDeferAsDropdown: boolean;
    reasonToDeferDropdownOptions: string[];
    eropsRename: string;
    sectorsNameOverride: string;
    ignoreEROPS: boolean;
    defectRename: string;
  };
}

const EditDefectDrawer: React.FC<EditDefectDrawerProps> = ({
  defectData,
  originalDefectData,
  aircraftId,
  updateDefectData,
  flightsData,
  isLoadingFlights,
  setAircraftId,
  formChanged,
  chosenAircraft,
  bookItemsLoading,
  melItems,
  cdlItems,
  nefItems,
  signOffAsEngineer,
  setSignOffAsEngineer,
  requiredSettings,
  handleFormChange,
  mode,
}) => {
  return (
    <div>
      <EditDefectDrawerHeader
        defectData={defectData}
        aircraftId={aircraftId}
        updateDefectData={updateDefectData}
        flightsData={flightsData}
        isLoadingFlights={isLoadingFlights}
        setAircraftId={setAircraftId}
      />
      <Card marginBottom={20} identifier="DefectDetailsWrapper">
        <EditDefectDrawerDetails
          defectData={defectData}
          updateDefectData={updateDefectData}
          formChanged={formChanged}
        />
      </Card>
      <Card>
        <EditDeferralOptions
          defectData={defectData}
          originalDefectData={originalDefectData}
          updateDefectData={updateDefectData}
          aircraftId={aircraftId}
          selectedAircraftId={chosenAircraft?.id}
          formChanged={formChanged}
          bookItemsLoading={bookItemsLoading}
          melItems={melItems}
          cdlItems={cdlItems}
          nefItems={nefItems}
        />
      </Card>
      {defectData?.deferred ? (
        <>
          <EditLimitations
            partialDefectData={{
              limitations: defectData?.limitations,
              erops_prevented: defectData?.erops_prevented,
            }}
            updateDefectData={updateDefectData}
            requiredSettings={{
              eropsRename: requiredSettings.eropsRename,
              sectorsNameOverride: requiredSettings.sectorsNameOverride,
              ignoreEROPS: requiredSettings.ignoreEROPS,
            }}
          />
          <EditDefectAdditionalInformation
            partialDefectData={{
              troubleshootingSteps: defectData?.troubleshooting_steps,
              reasonToDefer: defectData?.reason_to_defer,
            }}
            updateDefectData={updateDefectData}
            requiredSettings={requiredSettings}
          />
          {formChanged && <ConfirmSection defect={defectData} updateDefectData={updateDefectData} />}
          <Divider />
          <DeferralSignoffDetails
            defect={defectData}
            operatorID={chosenAircraft?.operator_id}
            updateDefectData={updateDefectData}
            signOffAsEngineer={signOffAsEngineer}
            setSignOffAsEngineer={setSignOffAsEngineer}
          />

          {defectData?.mx_events?.length > 0 ? (
            <ResolutionDetails
              release={defectData?.mx_events[defectData?.mx_events.length - 1]}
              poNum={defectData?.mx_events[defectData?.mx_events.length - 1]?.workpack_reference}
            />
          ) : null}
        </>
      ) : null}
      {mode === 'edit' && (
        <EditRectificationExtension
          partialDefectData={{
            rectificationIntervalExtension: defectData?.rectification_interval_extension,
            raisedAt: defectData?.raised_at,
            displayData: defectData?.display_data,
            deferred: defectData?.deferred,
          }}
          updateDefectData={updateDefectData}
          onChange={handleFormChange}
          requiredSettings={{
            defectRename: requiredSettings.defectRename,
          }}
        />
      )}
    </div>
  );
};

export default EditDefectDrawer;
