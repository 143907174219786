/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import servers from '../../utils/servers';
import styles from './fileViewer.module.less';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MapAttachmentThumbnails = ({ attachments, toggleShowFile }): JSX.Element => {
  const showFile = (index): void => {
    toggleShowFile({ visible: true, index: index === -1 ? 0 : index });
  };
  return (
    attachments
      // eslint-disable-next-line no-underscore-dangle
      ?.filter((item) => !item?._destroy)
      ?.map((item, index) => {
        // core returns a PNG thumbnail for PDFs - so we only have to handle local PDF attachments here
        if (item?.attachment_file_name?.includes('pdf')) {
          return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              type="button"
              className={styles.thumbNailButton}
              onClick={(): void => showFile(index)}
              id={item?.id}
              key={item?.id || item?.attachment_file_name}
            >
              <Document file={item.attachment}>
                <Page pageNumber={1} height={50} />
              </Document>
            </button>
          );
        }

        return (
          <button
            type="button"
            className={styles.thumbNailButton}
            onClick={(): void => showFile(index)}
            id={item?.id}
            key={item?.id || item?.attachment_file_name}
            data-testid={`TFFileViewer-MapThumbnails-${item?.id || item?.attachment_file_name}`}
          >
            <img
              className={styles.attachmentThumbnail}
              src={item?.attachment || `${servers.api}/${item?.thumbnail_url}`}
              alt="attachments"
              id={item?.id}
            />
          </button>
        );
      }) || null
  );
};

export default MapAttachmentThumbnails;
