import { Person } from '../models/people';

const getDisplayDetailsOfPerson = (person: Person): string => {
  if (!person) return '';
  let displayText = `${person.first_name || ''} ${person.last_name || ''}${
    person.email ? ` - ${person.email}` : ''
  }`.trim();
  if (person.is_deleted) {
    displayText = `*${displayText}`;
  }
  return displayText;
};

export default getDisplayDetailsOfPerson;
